<template> 
  <div>
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Expense Center List') }}</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">{{ $t('List Types') }}</router-link></li>
                <li class="breadcrumb-item text-gray-600">{{ $t('Expense Center List') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/ListTypes">
                <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> {{ $t('Return back to List Types') }}</span>
                </button>
            </router-link> 
        </div>
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <router-link to="/ExpenseCenterCreate">
                        <button type="button" class="btn btn-light-primary border border-primary me-3">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                            </span>
                            {{ $t('Add Expense Center') }} 
                        </button>
                    </router-link>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                            <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('List Expense Centers') }} </span> 
                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                        {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                            </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <DxDataGrid id="gridContainer" :show-borders="true" key-expr="id" :data-source="lists" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Expense-Center-List')"
                                :v-model:page-size="pageSize"
                                :v-model:page-index="pageIndex"> 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="10"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn caption="#" alignment="center" :width="30"/>
                                <DxColumn data-field="code" :caption="$t('Code')" alignment="left"/> 
                                <DxColumn data-field="name" :caption="$t('Name')" alignment="left" />   
                                <DxColumn data-field="is_default" :caption="$t('Default')" alignment="left"/>
                                <DxColumn data-field="hide" :caption="$t('Is Hide?')" alignment="center" :allow-filtering="false"/>  
                                <DxColumn data-field="created_by" :caption="$t('Created By')" alignment="left" />   

 
                                <template #dataRowTemplate="{ data: data }">  
                                    <td class="text-center">
                                         {{ ((this.pageSize - 1) * this.pageIndex) + data.rowIndex + 1 }} 
                                    </td> 
                                    <td class="text-gray-600 text-start align-middle"> 
                                        <a href="#" class="text-gray-600 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            <div>
                                                <span class="badge badge-circle badge-light me-3">
                                                    <i class="bi bi-pencil text-gray-900"></i> 
                                                </span> 
                                                <span class="text-gray-800"> 
                                                    {{ data.data.code }} 
                                                </span> 
                                            </div>
                                        </a>
                                    </td> 
                                    <td class="text-gray-800 fw-bolder text-start align-middle">
                                        <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.name }}
                                        </a>
                                    </td>  
                                    <td class="text-gray-800 text-start align-middle">
                                        <span class="svg-icon svg-icon-1 svg-icon-success" v-if="data.data.is_default">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="updateItem(data.data.id)">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </td> 
                                    <td class="text-center text-gray-800">
                                        <input class="form-check-input" type="checkbox" value="" id="hide" v-model="data.data.hide" @change="setListItemHide(data.data)">
                                    </td>
                                    <td class="text-gray-800  text-start align-middle">
                                        <a href="#" class="text-gray-800  text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.created_by }}
                                        </a>
                                    </td>  
                                </template>

                        </DxDataGrid>
                  </div>
                </div> 
            </div>
        </div> 
    </div>  
  </div>  
</template>

<script>
// Component Alias: `EXC001`  
import axios from 'axios' 

import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: "ExpenseCenterList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
    data() {
        return {
        lists:[],
        searchbar: '',
        isListProgressing: false,
        page_size: 10,
        page: 1,
        pageSize: 20,
        pageIndex: 0,
        menuSelection: '/ExpenseCenterHelppage',
        iconView : false
        };
    }, 
    computed: {
        searchableList() {
        return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        }).slice((this.page - 1) * this.page_size, this.page * this.page_size);
        },
        showPageSizeSelector() {
            return this.lists.length > this.pageSize;
        },
        pageSizes() {
            let arr = [];
            let x = 1;
            let loop = (this.lists.length / this.pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(100);
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    methods: {
    getData() {  
        this.isListProgressing = true;
        this.$Progress.start();
        this.lists = [];
        this.$removeSessionStorage('data' + '_EXC001');
        const that = this;
        const params = {
            session_id: this.$getUser().session_id
        };
        axios.post('/api/ExpenseCenter/ExpenseCenterList', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.lists = response.data;
            this.$setSessionStorage('data'+ '_EXC001', response.data);  
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isListProgressing = false;
                that.$Progress.finish();
            }, 500);
        }); 
    },
    updateItem(id){ 
        if(id == -1){
            return;
        }
        this.$setSessionStorage('record_id', id);
        this.$router.push({ name: 'ExpenseCenterUpdate' });
    },
    setListItemHide(data) {
        this.$swal.fire({
            title: data.hide == true ? 'Are you sure to hide this selected data from the lists?' : 'Are you sure to show this selected data from the lists?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
        }).then((result) => { 
            if (result.isConfirmed) {
                const params = {
                  session_id: this.$getUser().session_id,
                  list_type: 15,
                  hide: data.hide,
                  id: data.id
                };      
                params.hide = params.hide == true ? 1: 0;
                axios.post('/api/Helper/SetListItemHide', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.getData();
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                    this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                });
            }
            else{
                data.hide = !data.hide;
            }
        });  
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/ExpenseCenterHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    }, 
    created() {
        this.helpPageViewIcon();
            if(this.$getSessionStorage('refreshList')){
                this.getData();
                this.$removeSessionStorage('refreshList'); 
            } 
        else{
            this.lists = [];
        }
    }
}; 
</script>