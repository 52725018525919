<template>
    <div>
        <div class="row justify-content-center" v-if="activeStep == 0">
            <div class="col-md-6 col-lg-6 col-sm-12" v-if="licenceList.length > 0" :class="{'col-lg-12': userData.package_type == 0}">
                <a href="#" class="card hover-elevate-up shadow-sm bg-light-primary border border-primary border-dotted parent-hover" @click="onNextStep('upgradePlan')">
                    <div class="card-body d-flex flex-column flex-center">                        
                        <h1 class="text-gray-800 text-center lh-lg">           
                            Upgrade Plan
                        </h1>
                        <div class="py-10 text-center">
                            <img src="assets/theme/media/svg/illustrations/easy/3.svg" class="theme-light-show w-150px h-100px" alt="">
                            <img src="assets/theme/media/svg/illustrations/easy/3-dark.svg" class="theme-dark-show w-150px h-100px" alt="">
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12" v-if="userData.package_type !== 0" :class="{'col-lg-12': licenceList.length == 0}">
                <a href="#" class="card hover-elevate-up shadow-sm bg-light-info border border-info border-dotted parent-hover" @click="onNextStep('addUser')">
                    <div class="card-body d-flex flex-column flex-center">                        
                        <h1 class="text-gray-800 text-center lh-lg">           
                            Add User To Plan
                        </h1>
                        <div class="py-10 text-center">
                            <img src="assets/theme/media/svg/illustrations/easy/2.svg" class="theme-light-show w-150px h-100px" alt="">
                            <img src="assets/theme/media/svg/illustrations/easy/2-dark.svg" class="theme-dark-show w-150px h-100px" alt="">
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div v-if="activeStep > 0" class="stepper stepper-links" data-kt-stepper="true">
            <div class="card p-1 shadow-sm">
                <div class="card-header border-0 min-h-30px">
                    <div class="card-title">
                        <div class="card-label fs-5 text-gray-800">
                            Your current plan is 
                            <b class="fw-boldest">{{ userData.licence_name }}</b>.
                            You have active <b class="fw-boldest">{{userData.user_count}}</b> users.
                        </div>
                    </div>
                    <div class="card-toolbar">                                    
                        <span class="fs-5 fw-bolder" v-if="userData.period_end > 0"> 
                           Expires in <b :class="{'text-danger': userData.period_end <= 15}">{{parseInt(userData.period_end)}}</b> days
                        </span>
                        <span class="text-danger fs-5 fw-bolder" v-if="userData.period_end < 0"> Expired {{Math.abs(userData.period_end)}} days ago</span>
                        <span class="text-danger fs-5 fw-bolder" v-if="userData.period_end == 0"> Expired today</span>  
                    </div>
                </div>
            </div>
            <div class="card mt-5 p-1 shadow-sm">                
                <div class="card-body bgi-no-repeat bgi-position-y-top bgi-position-x-end ribbon ribbon-top" style="background-image: url(assets/theme/media/svg/shapes/abstract-1.svg);">
                    <div class="ribbon-label bg-primary" v-if="userData.package_type > 0 && activeView == 'upgradePlan' && (this.activeStep == 1 || this.activeStep == 2) && userData.period_end <= 30" > 
                        The remaining <b class="px-2 fs-6"> {{userData.period_end}} </b> will be added to your new plan. 
                    </div>
                    <div class="d-flex flex-column" v-if="this.activeStep == 1">
                        <div class="text-center">
                            <h1 class="fs-2hx fw-bold mb-3" v-if="activeView == 'upgradePlan'">{{$t('Choose Your Plan')}}</h1>
                            <h1 class="fs-2hx fw-bold mb-3" v-if="activeView == 'addUser'">{{$t('Add User To Your Plan')}}</h1>
                            <div class="text-gray-600 fw-semibold fs-5 mb-3" v-if="this.isViewLicenceGuid == true" :menuSelection="this.menuSelection">
                                If you need more info about our pricing, please check 
                                <a href="#" class="link-primary fw-bold" @click="openHelpPage()" >Licence Plans Guidelines</a>.
                            </div>
                        </div>
                        <div class="nav-group nav-group-outline mx-auto mb-5" v-if="activeView == 'upgradePlan'">
                            <button class="btn btn-color-gray-600 btn-active btn-active-secondary px-6 py-3" 
                                @click="activePriceView = 'annual'" :class="{'active': activePriceView == 'annual'}">
                                Annual <span class="badge badge-light-success ms-2">Best Value</span>
                            </button>
                            <button class="btn btn-color-gray-600 btn-active btn-active-secondary px-6 py-3 me-2" 
                                @click="activePriceView = 'monthly'" :class="{'active': activePriceView == 'monthly'}">
                                Monthly
                            </button>
                        </div>
                        <div class="d-flex flex-row row g-10 justify-content-center" v-if="activeView == 'upgradePlan'">
                            <div class="d-flex flex-column flex-row-auto col-xl-4 col-md-4" v-for="item in licenceList" :key="item">
                                <div class="d-flex card h-100 align-items-start d-flex flex-column-fluid ribbon ribbon-top">
                                    <div class="ribbon-label bg-info" v-if="item.package_type == userData.package_type" > {{ $t('Current Plan') }} </div>
                                    <div class="w-100 card-body d-flex flex-column d-flex flex-column-fluid border rounded-top-3 bg-light py-15 px-10">
                                        <div class="mb-7 text-center">
                                            <h3 class="text-gray-900 fw-bolder">
                                                {{item.package_name}} 
                                            </h3>
                                            <div class="text-gray-600 fw-semibold mh-100px scroll-y">
                                                {{item.description}}
                                            </div>
                                            <div class="text-center">
                                                <span class="fs-3x fw-bold text-primary" v-if="activePriceView == 'monthly'">
                                                    {{item.total_price_monthly}}
                                                </span>
                                                <span class="fs-3x fw-bold text-primary" v-if="activePriceView == 'annual'">
                                                    {{item.total_price}}
                                                </span>
                                                <span class="mb-2 text-primary"> {{userData.currency_code}} </span>
                                                <span class="fs-7 fw-semibold opacity-50">/
                                                    <span v-if="activePriceView == 'monthly'">month</span>
                                                    <span v-if="activePriceView == 'annual'">year</span>
                                                </span>
                                            </div>
                                            <div class="text-center" v-if="activePriceView == 'annual'">
                                                <div v-if="onCalculateSaving(item) > 0" class="fs-7 text-success">
                                                    <i class="bi bi-check-circle text-success"></i> Save {{onCalculateSaving(item)}} {{userData.currency_code}} with billed annually
                                                </div>
                                                <div v-else class="fs-7 text-gray-600">
                                                    Billed annually
                                                </div>
                                            </div>
                                            <div class="text-center" v-if="activePriceView == 'monthly'">                                                
                                                <div class="fs-7 text-gray-600">
                                                    Billed monthly
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="w-100 mb-10">
                                            <div class="d-flex align-items-center mb-1">
                                                <span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                    Up to {{item.user_count}} Active Users 
                                                </span>
                                                <i class="bi bi-person-check-fill fs-3"></i>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3" v-if="activePriceView == 'monthly'">
                                                    Only Credit Card                                                    
                                                </span>
                                                <span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3" v-if="activePriceView == 'annual'"> 
                                                    Credit Card & Bank Transfer 
                                                </span>
                                                <i class="bi bi-credit-card-fill fs-3"></i>
                                            </div>
                                            <div class="separator my-5 border-gray-300"></div>
                                            <div class="d-flex flex-column content-justify-center flex-grow-1" v-if="item.package_type > 1">
                                                <div class="d-flex fs-6 fw-semibold align-items-center">
                                                    <h5>Everything in {{item.prev_package_name}}, and </h5>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column content-justify-center flex-grow-1" v-for="moduleItem in getModuleTitles(item.package_type)" :key="moduleItem">
                                                <div class="d-flex fs-5 fw-semibold align-items-center border-bottom border-1 border-gray-300 my-3">
                                                    {{moduleItem.name}}
                                                </div>
                                                <div class="d-flex fs-6 align-items-center my-1" v-for="subItem in moduleItem.items" :key="subItem">
                                                    <span class="fs-6 text-gray-800 flex-grow-1 pe-3" v-if="subItem">
                                                        {{subItem.name}}
                                                    </span>
                                                    <i class="bi bi-check fs-3 text-success"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer border-top-0 col-lg-12 p-3 bg-light">
                                        <div class="d-grid gap-2"> 
                                            <button type="button" class="btn btn-sm btn-primary" :class="{'btn-info': item.package_type == userData.package_type}" @click="setDetail(item)">
                                                <span v-if="item.package_type == userData.package_type">{{ $t('Extend Your Plan')}}</span>
                                                <span v-else>{{ $t('Select and Continue')}}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="activeView == 'addUser'">
                            <div class="card card-dashed shadow-sm">
                                <div class="card-body">
                                    <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                        <i class="bi bi-info-circle fs-2tx text-primary me-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                        <div class="d-flex flex-stack flex-grow-1 ">
                                            <div class=" fw-semibold">
                                                <div class="fs-6 text-gray-700 ">
                                                    You have active <b> {{userData.user_count}} </b> users. If you want to add new users to your existing plan, specify the number of users in the field below.
                                                    <br>
                                                    The price for users will be calculated based on your current plan.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body ps-15">
                                        <div class="table-responsive">
                                            <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                <thead>
                                                    <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                        <th class="min-w-175px">Product</th>
                                                        <th class="min-w-100px text-end">Unit Price</th>
                                                        <th class="min-w-70px text-center">Qty</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="fw-semibold text-gray-600">
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="symbol symbol-50px">
                                                                    <span class="symbol-label theme-light-show" style="background-image:url('/assets/theme/media/svg/avatars/blank.svg');"></span>
                                                                    <span class="symbol-label theme-dark-show" style="background-image:url('/assets/theme/media/svg/avatars/blank-dark.svg');"></span>
                                                                </a>
                                                                <div class="ms-5">
                                                                    <a href="#" class="fw-bold text-gray-600 text-hover-primary">User</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-end">
                                                            {{upgradeForm.user_price}}
                                                        </td>
                                                        <td class="text-center">
                                                            <div class="d-flex align-items-center justify-content-center mt-3" >
                                                                <button type="button" class="btn btn-icon btn-sm btn-light btn-icon-gray-400" :disabled="upgradeForm.user_count == 0" @click="onChangePrice('minus')">
                                                                    <i class="bi bi-dash fs-3x"></i>
                                                                </button>
                                                                <CurrencyInput type="text" class="border-0 text-center px-0 fs-3 fw-bold text-gray-800 w-50px" :precision="0" v-model="upgradeForm.user_count" @focus="$event.target.select()" :min="0" @blur="calculateSubTotal()"/>
                                                                <button type="button" class="btn btn-icon btn-sm btn-light btn-icon-gray-400" @click="onChangePrice('plus')">
                                                                    <i class="bi bi-plus fs-3x"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="fs-3 text-gray-900 text-end">
                                                            Grand Total
                                                        </td>
                                                        <td class="text-gray-900 fs-3 fw-bolder text-center">
                                                            {{upgradeForm.user_total}} {{userData.currency_code}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="{'current': this.activeStep == 2}" v-if="this.activeStep == 2">
                        <div class="row mb-10 mb-xl-0">
                            <h1 class="fw-bold text-gray-800 mb-5">Plan Details</h1>
                            <div class="col-lg-12 pb-5">
                                <div class="table-responsive">
                                    <table class="table mb-3">
                                        <thead>
                                            <tr class="border-bottom fs-6 fw-bold text-muted">
                                                <th class="min-w-175px pb-2">Description</th>
                                                <th class="min-w-70px  text-end pb-2">Plan Type</th>
                                                <th class="min-w-80px  text-end pb-2">Total Price</th>
                                                <th class="min-w-100px text-end pb-2">VAT
                                                    <span v-if="this.licenceDetails.is_add_vat == 1">({{parseInt(this.licenceDetails.vat_rate)}}%)</span>
                                                    <span v-if="this.licenceDetails.is_add_vat == 0">(None)</span>
                                                </th>
                                                <th class="min-w-100px text-end pb-2">Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr class="fw-bold text-gray-700 fs-5 " v-for="item in licencePlanDetails" v-bind:key="item">
                                                <td class="d-flex align-items-center pt-6">                                            
                                                    <i class="bi bi-diamond-fill fs-10 me-2 text-success"></i>
                                                    {{item.plan_name}}
                                                    <span v-if="activePriceView == 'monthly'"> 
                                                        ({{item.monthly_price}} {{userData.currency_code}} x {{upgradeForm.payment_period}} installments)
                                                    </span>
                                                </td>

                                                <td class="pt-6 text-end">{{item.plan_type}}</td>
                                                <td class="pt-6 text-end">{{item.price}}</td>
                                                <td class="pt-6 text-end">{{item.vat_amount}}</td>
                                                <td class="pt-6 text-end text-gray-800 fw-bolder">{{item.total}}</td>
                                            </tr>
                                            <tr class="pt-6 border-top border-gray-300">
                                                <td class="pt-6 text-end fw-bold text-gray-600 fs-6" colspan="4">Subtotal</td>
                                                <td class="pt-6 text-end fw-bold fs-6 text-gray-800">{{this.totals.sub_total}} {{userData.currency_code}}</td>
                                            </tr>
                                            <tr v-if="activeView == 'upgradePlan'">
                                                <td class="pt-3 text-end fw-bold text-gray-600 fs-6" colspan="4">VAT                                                    
                                                    <span v-if="this.licenceDetails.is_add_vat == 1">({{parseInt(this.licenceDetails.vat_rate)}}%)</span>
                                                    <span v-if="this.licenceDetails.is_add_vat == 0">(None)</span>
                                                </td>
                                                <td class="pt-3 text-end fw-bold fs-6 text-gray-800" v-if="this.licenceDetails.is_add_vat == 1">{{this.totals.vat_amount}} {{userData.currency_code}}</td>
                                                <td class="pt-3 text-end fw-bold fs-6 text-gray-800" v-if="this.licenceDetails.is_add_vat == 0">None</td>
                                            </tr>
                                            <tr v-if="activeView == 'addUser'">
                                                <td class="pt-3 text-end fw-bold text-gray-600 fs-6" colspan="4">VAT                                                    
                                                    <span v-if="this.userData.is_add_vat == 1">({{parseInt(this.userData.vat_rate)}}%)</span>
                                                    <span v-if="this.userData.is_add_vat == 0">(None)</span>
                                                </td>
                                                <td class="pt-3 text-end fw-bold fs-6 text-gray-800" v-if="this.userData.is_add_vat == 1">{{this.totals.vat_amount}} {{userData.currency_code}}</td>
                                                <td class="pt-3 text-end fw-bold fs-6 text-gray-800" v-if="this.userData.is_add_vat == 0">None</td>
                                            </tr>
                                            <tr>
                                                <td class="pt-3 text-end fw-bold fs-3 text-gray-800" colspan="4">Grand Total</td>
                                                <td class="pt-3 text-end fw-bolder fs-3 text-gray-800">{{this.totals.grand_total}} {{userData.currency_code}}</td>
                                            </tr>
                                            <tr v-if="activePriceView == 'monthly'">
                                                <td class="pt-3 text-end fw-bold fs-3 text-gray-800" colspan="4">Monthly Price</td>
                                                <td class="pt-3 text-end fs-3">
                                                    <div class="fw-bolder text-gray-800">{{this.totals.monthly_price_total}} {{userData.currency_code}} </div>
                                                    <div class="fw-bolder fs-7"> (Paid in {{upgradeForm.payment_period}} installments) </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <div class="flex-grow-1 mt-10">
                                <h1 class="fw-bold text-gray-800 mb-5">Payment Method</h1>
                                <div class="d-flex flex-column">
                                    <div class="row">
                                        <div class="col-lg-6" :class="{'col-lg-12': activePriceView == 'monthly'}">
                                            <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-3"
                                                :class="{'active btn-active-light-primary': upgradeForm.payment_method == 1}">
                                                <div class="d-flex align-items-center me-2">
                                                    <div class="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                        <input class="form-check-input" v-model="upgradeForm.payment_method" type="radio" name="payment_plan" value="1">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <h2 class="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                            CREDIT CARD
                                                        </h2>
                                                        <div class="fw-semibold opacity-75">
                                                            In the next step, you can complete the payment process with a credit card.
                                                            <div class="mt-3 fs-7 text-gray-700" v-if="activePriceView == 'monthly'">
                                                                By choosing the <b>{{licenceDetails.period_day}} Days </b> payment plan, you can make your payment with the <b>Bank Transfer</b> option.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ms-5">
                                                    <i class="bi bi-credit-card fs-3x"></i>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-lg-6" v-if="activePriceView == 'annual'">
                                            <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-3"
                                                :class="{'active btn-active-light-info': upgradeForm.payment_method == 2}">
                                                <div class="d-flex align-items-center me-2">
                                                    <div class="form-check form-check-custom form-check-solid form-check-info me-6">
                                                        <input class="form-check-input" v-model="upgradeForm.payment_method" type="radio" name="payment_plan" value="2">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <h2 class="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                            BANK TRANSFER
                                                        </h2>
                                                        <div class="fw-semibold opacity-75">
                                                            In the next step, you can complete the payment process with the Bank Transfer method.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ms-5">
                                                    <i class="bi bi-cash-stack fs-3x"></i>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="{'current': this.activeStep == 3}" v-if="this.activeStep == 3">
                        <div class="row mb-10 mb-xl-0" v-if="!isEditBillingAddress">
                            <h1 class="fw-bold text-gray-800 mb-5">Billing Address</h1>
                            <div class="col-lg-12" data-kt-billing-element="address">
                                <div class="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                                    <div class="d-flex flex-column py-2">
                                        <div class="d-flex align-items-center fs-5 fw-bold mb-5">
                                            {{companyInfo.company_name}}
                                            <span class="badge badge-light-success fs-7 ms-2">Primary</span>
                                        </div>
                                        <div class="fs-6 fw-semibold text-gray-600">
                                            {{companyInfo.company_address}}
                                        </div>
                                        <div class="fs-6 fw-semibold text-gray-600">
                                            {{companyInfo.company_city}}
                                        </div>
                                        <div class="fs-6 fw-semibold text-gray-600">
                                            {{companyInfo.company_country_code}}
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center py-2">
                                        <button class="btn btn-sm btn-light btn-active-light-primary" @click="onEditBillingAddress()">Edit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fv-row row rounded shadow-sm p-5" v-if="isEditBillingAddress">
                            <h1 class="fw-bold text-gray-800 mb-5">Edit Billing Address</h1>
                            <GetAddress @address-selected="onSelectAddress" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2"/>
                            <div class="col-lg-4">
                                <div class="fv-row">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Post Code') }}
                                    </label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" name="post_code" v-model="form.company_postcode">
                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                id="basic-addon2" 
                                                @click="this.onFindAddressByPostCode()" 
                                                data-bs-toggle="modal" 
                                                data-bs-target="#kt_modal_search_address" 
                                                data-kt-menu-trigger="click" 
                                                data-kt-menu-placement="bottom-end"
                                                :disabled="this.form.company_postcode == null || this.form.company_postcode == ''">
                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="fv-row">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('City') }}
                                    </label>
                                    <input type="text" class="form-control" name="city" v-model="form.company_city">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="fv-row">
                                    <label class="fs-6 fw-bold form-label mt-3">{{ $t('District') }}                                                    
                                    </label>
                                    <input type="text" class="form-control" name="city" v-model="form.company_district">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="fv-row">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Address') }}
                                    </label>
                                    <textarea class="form-control" name="address" v-model="form.company_address"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Primary Person') }}
                                </label>
                                <input type="text" class="form-control" placeholder="" name="name" v-model="form.primary_person"> 
                            </div>
                            <div class="col-lg-4 mb-3">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Mobile Phone') }}
                                </label>
                                <div class="input-group">
                                    <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                        {{form.company_country_code}} {{form.phone_country_code}}
                                    </span>
                                    <input type="text" class="form-control" name="name" v-model="form.company_gsm"> 
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Email') }}
                                </label>
                                <input type="text" class="form-control" name="name" v-model="form.company_email" :class="{'border border-danger': isValidEmail == false}"> 
                                <small class="text-danger" v-if="!isValidEmail && form.company_email !== null && form.company_email !== '' ">{{ $t('Email is not valid') }}</small>
                            </div>  
                            <div class="d-flex align-items-center justify-content-end text-end py-2">
                                <button type="reset" class="btn btn-light me-3" @click="isEditBillingAddress = false">Cancel</button>
                                <button class="btn btn-sm btn-primary" @click="saveCompanyInfo()" :disabled="isValidEmail == false">Update</button>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="{'current': this.activeStep == 4}" v-if="this.activeStep == 4">
                        <div class="card card-flush" v-if="upgradeForm.payment_method == 1">
                            <div class="card-body text-center">
                                <div class="fw-bolder fs-2x text-gray-900 mb-4">                                    
                                    <div class="mb-2">
                                        <span class="text-gray-800 fw-bold me-1" v-if="activePriceView == 'monthly'">
                                            <span class="me-3">Grand Total:</span>
                                            <b>{{this.totals.monthly_price_total}} {{userData.currency_code}}</b>
                                        </span> 
                                        <span class="text-gray-800 fw-bold me-1" v-else>
                                            <span class="me-3">Grand Total:</span>
                                            <b>{{this.totals.grand_total}} {{userData.currency_code}}</b>
                                        </span> 
                                        <small class="text-gray-600 fw-semibold" v-if="activePriceView == 'monthly'">(Per Month)</small>
                                    </div>
                                    <div class="fs-3 text-gray-800 fw-semibold">
                                        <span v-if="activeView == 'upgradePlan'">{{licenceDetails.parameter_type_name}} ({{licenceDetails.period_day}} Days) </span>
                                        <span v-if="upgradeForm.user_count > 0"> + {{upgradeForm.user_count}} Users </span>
                                    </div>
                                </div>
                                <div class="fw-semibold fs-6 text-gray-500 mb-7">
                                    We have received your request. You can start using your new plan after you make the payment.
                                </div>
                                <div class="mb-3">
                                    <img src="assets/theme/media/illustrations/sigma-1/1.png" class="mw-100 mh-300px theme-light-show" alt="">
                                    <img src="assets/theme/media/illustrations/sigma-1/1-dark.png" class="mw-100 mh-300px theme-dark-show" alt="">
                                </div> 
                            </div>
                        </div>      
                        <div v-if="upgradeForm.payment_method == 2">
                            <div class="card card-dashed bg-light-primary border-primary flex-row flex-stack flex-wrap p-5">
                                <div class="d-flex align-items-sm-center">
                                    <div class="symbol symbol-50px me-5">
                                        <span class="symbol-label bg-light">
                                            <i class="bi bi-cash-stack fs-3x text-primary"></i>
                                        </span>
                                    </div>
                                    <div class="d-flex align-items-center flex-row-fluid flex-wrap">                    
                                        <div class="me-20">
                                            <span class="text-gray-800 fs-6 fw-bold">This is your Reference Number</span>
                                            <div class="input-group input-group-sm mt-1">
                                                <input type="text" class="form-control form-control-sm form-control-solid border border-gray-300" style="font-size: 15px !important; font-weight: bolder;" 
                                                    ref="referenceNumber" readonly v-model="licenceReferenceNumber">
                                                <button class="btn btn-sm btn-secondary" :class="{'btn-success': isCopied}" @click="copyToClipboard()">
                                                    <b v-if="!isCopied">Copy</b>
                                                    <b v-if="isCopied">Copied!</b>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-20">
                                            <div class="text-gray-800 fw-bolder d-block fs-3">
                                                <span class="fs-5 me-3">Grand Total: </span>
                                                <span class="fs-2" v-if="activePriceView == 'monthly'">{{this.totals.monthly_price_total}} {{userData.currency_code}}</span>
                                                <span class="fs-2" v-else>{{this.totals.grand_total}} {{userData.currency_code}}</span>
                                            </div>
                                            <div class="fs-6 text-gray-600 fw-semibold">
                                                {{licenceDetails.parameter_type_name}} ({{licenceDetails.period_day}} Days) 
                                                <span v-if="upgradeForm.user_count > 0"> + {{upgradeForm.user_count}} Users </span>
                                            </div>
                                            
                                            <div class="fw-semibold d-block fs-7">When making a payment by bank transfer, you must write this reference number in the Reference field.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-10" v-html="upgradeForm.licence_bank_description" 
                                v-if="upgradeForm.licence_bank_description !== null && upgradeForm.licence_bank_description !== '' && upgradeForm.licence_bank_description !== undefined">
                            </div>
                            <div class="card card-flush">
                                <div class="card-body text-center pb-0">
                                    <div class="fs-6 text-gray-800 mb-5">
                                        We have received your request. You can start using your new plan after you make the payment using this Reference Number.
                                    </div>
                                    <div class="mb-3">
                                        <img src="assets/theme/media/illustrations/sigma-1/1.png" class="mw-100 mh-200px theme-light-show" alt="">
                                        <img src="assets/theme/media/illustrations/sigma-1/1-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="{'current': this.activeStep == 5}" v-if="this.activeStep == 5">
                        <div class="card card-flush">
                            <div class="card-body text-center" v-if="!isViewConfirmation">
                                <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
                                    Thank you!
                                </h1>
                                <div class="fw-semibold fs-6 text-gray-500 mb-7">
                                    We have received your request. You will be redirected to Login page now.
                                </div>
                                <div class="mb-3">
                                    <img src="assets/theme/media/illustrations/sigma-1/2.png" class="mw-100 mh-300px theme-light-show" alt="">
                                    <img src="assets/theme/media/illustrations/sigma-1/2-dark.png" class="mw-100 mh-300px theme-dark-show" alt="">
                                </div> 
                            </div>
                            <div class="card-body text-center" v-if="isViewConfirmation">
                                <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
                                    Are you sure?
                                </h1>
                                <div class="fw-semibold fs-6 text-gray-500 mb-7">
                                    The <b>{{licenceDetails.parameter_type_name}}</b> you have selected will be added to your existing licence so that you can try it for <b>{{licenceDetails.period_day}}</b> days. Do you confirm?
                                </div>
                                <div class="mb-7 text-center">                                    
                                    <button type="button" class="btn btn-lg btn-success" @click="addLicenceTransaction()" data-kt-indicator="on">
                                        <span v-if="!this.isTransactionProgressing"> Confirm </span>
                                        <span class="indicator-progress" v-if="this.isTransactionProgressing">
                                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                                <div class="mb-3">
                                    <img src="assets/theme/media/illustrations/sigma-1/17.png" class="mw-100 mh-300px theme-light-show" alt="">
                                    <img src="assets/theme/media/illustrations/sigma-1/17-dark.png" class="mw-100 mh-300px theme-dark-show" alt="">
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer py-3" v-if="activeStep !== 5">
                    <button v-if="activeStep > 0 && activeStep !== 4" type="button" class="btn btn-lg btn-light me-3" @click="onBackStep()">
                        Back
                    </button>
                    <button v-if="activeStep == 2 || (activeStep == 1 && activeView == 'addUser')" type="button" class="btn btn-lg btn-primary float-end" @click="onNextStep()">
                        <span class="indicator-label">
                            Continue
                        </span>
                        <span class="indicator-progress">
                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <!-- <button v-if="activeStep == 1 && isTrialPackage" type="button" class="btn btn-lg btn-primary float-end" @click="onConfirmFreePackage()" data-kt-indicator="on">
                        Continue
                    </button> -->
                    <button v-if="activeStep == 3 && !isEditBillingAddress" type="button" class="btn btn-lg btn-success float-end" @click="addLicenceTransaction()" data-kt-indicator="on">
                        <span v-if="!this.isTransactionProgressing"> Confirm and Continue </span>
                        <span class="indicator-progress" v-if="this.isTransactionProgressing">
                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>

                    <div class="row" v-if="activeStep == 4 && upgradeForm.payment_method == 1">
                        <div class="col-lg-6">
                            <button type="button" class="btn btn-lg btn-danger me-3" @click="onCancelTransaction()">
                                Cancel Request
                            </button>
                        </div>
                        <div class="col-lg-6">
                            <!-- If Credit Card selected -->
                            <!-- One-time Payment -->
                            <stripe-checkout
                                ref="checkoutRef"
                                mode="payment"
                                :pk="this.stripeData.strpk"
                                :session-id="this.stripeData.strsid"
                                @loading="v => loading = v"
                                v-if="activePriceView !== 'monthly' && this.stripeData !== null">
                                <button @click="onSubmitPayment" class="btn btn-lg btn-success float-end" type="button">
                                    Confirm and Proceed to Payment
                                </button>
                            </stripe-checkout> 
                            <!-- Monthly Payment -->
                            <stripe-checkout
                                ref="checkoutRef"
                                mode="subscription"
                                :pk="this.stripeData.strpk"
                                :session-id="this.stripeData.strsid"
                                @loading="v => loading = v"
                                v-if="activePriceView === 'monthly' && this.stripeData !== null">
                                <button @click="onSubmitPayment" class="btn btn-lg btn-success float-end" type="button">
                                    Confirm and Proceed to Payment
                                </button>
                            </stripe-checkout>
                        </div>
                    </div>

                    
                    <button type="button" class="btn btn-lg btn-danger me-3" v-if="activeStep == 4 && upgradeForm.payment_method == 2" @click="onCancelTransaction()">
                        Cancel Request
                    </button>
                    <button type="button" class="btn btn-lg btn-success float-end" v-if="activeStep == 4 && upgradeForm.payment_method == 2" @click="completeUpgrade()">
                        <span class="indicator-label">
                            Complete and Close
                        </span>
                        <span class="indicator-progress">
                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div> 
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false});
import { StripeCheckout } from '@james090500/vue-stripe';
export default {
  name: "Upgrade",
  components: {
    StripeCheckout
  },
  data() {
    this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      userData: this.$getUser(),
      stripeData: {
        strpk : this.$getUser().stripe ? this.$getUser().stripe.strpk : null,
        strsid: null
      },
      activeStep: 0,
      licenceList: [],
      activeView: null,
      upgradeForm: {
        licence_id: null,
        user_count: 1,
        user_price: 0,
        payment_method: 1,
        subtotal: 0,
        user_total: 0,
        licence_bank_description: null,
        userCalculatedPrice: 0
      },
      licenceDetails: {},
      activePriceView: 'annual',
      menuSelection: '/LicenceGuideHelppage',
      isViewLicenceGuid : false,
      licencePlanDetails: [],
      totals: {
        sub_total: 0.00,
        vat_amount: 0.00,
        grand_total: 0.00,
        monthly_price_total: 0.00
      },
      licenceReferenceNumber: null,
      isCopied: false,
      isTransactionProgressing: false,
      licenceTransactionId: null,
      companyInfo: {},
      form: {
        primary_person: null,
        company_country_code: '-1',
        phone_country_code: null,
        company_gsm: null,
        company_email: null,
        company_postcode: null,
        company_address: null,
        company_city: null,
        company_district: null
      },
      isEditBillingAddress: false
    };
  },
  computed: { 
    onCalculateSaving() {
        return(value) => {
            return (parseFloat(value.total_price_monthly) - parseFloat(value.total_price)).toFixed(2);
        };
    },
    isValidEmail() {
        return (this.form.company_email == null || this.form.company_email == '' || this.form.company_email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.company_email);
    },
    getModuleTitles() {
        return(value) => {
            return this.modules.map((i) => {
                i.items = [];
                if (i.type == 1) { 
                    this.modules.forEach(element => {
                        if(element.parent_id == i.id && element.licence_requirement >= value) { 
                            i.items.push(element);
                        }
                    });
                }
                return i;
            }).filter(x => x.type == 1 && (value == 2 ? x.licence_requirement <= value && x.id !== 4 : x.licence_requirement == value));
        };
    }
  },
  methods: { 
    getData() {
        this.activeStep = this.$getUser().package_type == 0 ? 1 : 0;
        this.activeView = this.$getUser().package_type == 0 ? 'upgradePlan' : null;
        this.isEditBillingAddress = false;
        this.userMail = this.$getUser().user_mail;
        this.upgradeForm = {
            licence_id: null,
            user_count: 1,
            user_price: 0,
            payment_method: 1,
            subtotal: 0,
            user_total: 0
        };
        this.licenceList = [];
        this.modules = [];
        this.licenceDetails = {};
        this.activePriceView = this.userData.payment_type == 1 ? 'monthly' : 'annual';
        const parameters = {
          session_id: this.$getUser().session_id
        };  
        axios.post('/api/Lic/Get', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.licenceList = response.data.packages;
                this.modules = response.data.modules;
                this.helpPageViewIcon();
            }
        });
    },
    setDetail(item) {
        this.licenceDetails = item;       
        this.upgradeForm.licence_id = item.id;
        this.upgradeForm.user_count = this.activeView == 'addUser' ? 1 : 0;
        this.upgradeForm.payment_period = parseInt(this.licenceDetails.period_day / 30);
        this.calculateSubTotal();
        this.onNextStep();
    },
    onChangePrice(priceType) {
        if(priceType == 'plus') {
            this.upgradeForm.user_count++;
        }
        else if(priceType == 'minus'){
            this.upgradeForm.user_count--;
        }
        this.calculateSubTotal();
    },
    calculateSubTotal() {
        let licencePrice = 0;
        let userPrice = parseFloat(this.userData.user_price);
        if(this.activeView == 'upgradePlan') {
            licencePrice = this.activePriceView == 'monthly' ? parseFloat(this.licenceDetails.price_monthly) : parseFloat(this.licenceDetails.price);
        }
        this.upgradeForm.user_total = (userPrice * parseInt(this.upgradeForm.user_count)).toFixed(2);
        this.upgradeForm.subtotal = (licencePrice + (userPrice * parseInt(this.upgradeForm.user_count))).toFixed(2);

    },
    onChangePeriod(item) {
        this.activePriceView = item;
        this.upgradeForm.user_count = 0;
        this.upgradeForm.user_total = 0;
        this.upgradeForm.subtotal = this.activePriceView == 'monthly' ? parseFloat(this.licenceDetails.price_monthly) : parseFloat(this.licenceDetails.price);
        this.calculateSubTotal();
    },
    onConfirmFreePackage() {
        this.activeStep = 5;
        this.isViewConfirmation = true;
    },
    addLicenceTransaction() {
        this.isTransactionProgressing = true;
        // Quantity in LineItems should be updated prior to Total Amount
        var subTotalReedited = parseFloat(this.totals.grand_total).toFixed(2) / 0.1;
        var subTotalMonthlyReedited = parseFloat(this.totals.monthly_price_total).toFixed(2) / 0.1;
        // One-time Payment
        var oneTimeQuantity = parseInt(subTotalReedited);
        // Monthly Payment
        var monthlyQuantity = parseInt(subTotalMonthlyReedited); 

        const parameters = {
          session_id: this.$getUser().session_id,
          target_licence_id: this.licenceDetails.id,
          add_user_count: this.activeView == 'addUser' ? this.upgradeForm.user_count : 0,
          payment_type: this.activePriceView == 'monthly' ? 1 : 2,
          payment_method: this.upgradeForm.payment_method,
          quantity: this.activePriceView == 'monthly' ? monthlyQuantity : oneTimeQuantity,
          email: this.$getUser().user_mail
        };  
        const that = this;
        axios.post('/api/Lic/AddLicenceTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.licenceReferenceNumber = response.data.LicenceSID;
                this.licenceTransactionId = response.data.LicenceTransactionID;
                this.stripeData.strsid = response.data.StrSID;

                var userData = ls.get('user_' + sessionStorage.getItem('sid'));          
                if(userData !== null && userData !== '' && userData !== undefined) {
                    userData.is_pending_licence_exist = 1;
                    ls.set('user_' + sessionStorage.getItem('sid'), userData);
                }
                this.onNextStep();
            }
        })
        .catch(function (error) {
           console.log(error);
            that.isTransactionProgressing = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isTransactionProgressing = false;
            }, 500);
        });
    },
    onNextStep(activeView) {
        this.activeStep++;
        if(activeView) {
            this.activeView = activeView;            
            if(this.activeView == 'addUser') {
                this.upgradeForm.userCalculatedPrice = 0;
                this.upgradeForm.user_price = parseFloat(this.userData.user_price).toFixed(2);

                if(this.activePriceView == 'monthly') {
                    this.upgradeForm.payment_period = parseInt(parseInt(this.userData.period_end) / 30);
                }
                this.calculateSubTotal();
            }
        }
        

        if(this.activeStep == 2){
            this.licencePlanDetails = [];
            this.upgradeForm.payment_method = 1;
            let licenceMonthlyPrice = this.activePriceView == 'monthly' ? parseFloat(this.licenceDetails.price_monthly) : parseFloat(this.licenceDetails.price);
            let licencePrice = this.activePriceView == 'monthly' ? (parseFloat(this.licenceDetails.price_monthly) * this.upgradeForm.payment_period) : parseFloat(this.licenceDetails.price);
            let licenceUserPrice = parseFloat(this.userData.user_price);
            let userVatAmount = 0;
            
            if(this.activeView == 'upgradePlan') {
                licenceUserPrice = this.activePriceView == 'monthly' ? (parseFloat(this.licenceDetails.user_price_monthly) * this.upgradeForm.payment_period) : parseFloat(this.licenceDetails.user_price);
                licenceUserPrice *= this.licenceDetails.user_count;
                let licenceVatAmount = this.licenceDetails.is_add_vat == 1 ? parseFloat(((licencePrice + licenceUserPrice) / 100) * this.licenceDetails.vat_rate).toFixed(2) : 0;
                this.licencePlanDetails.push(
                    {
                        plan_name: this.licenceDetails.package_name,
                        plan_type: this.activePriceView == 'monthly' ? 'Monthly' : (this.licenceDetails.period_day + ' Days'),
                        price: licencePrice.toFixed(2),
                        user_price: licenceUserPrice.toFixed(2),
                        vat_amount: this.licenceDetails.is_add_vat == 1 ? licenceVatAmount : 'None',
                        total: (parseFloat(licencePrice) + parseFloat(licenceVatAmount)).toFixed(2),
                        is_add_vat: this.licenceDetails.is_add_vat,
                        user_count: this.licenceDetails.user_count,
                        monthly_price: licenceMonthlyPrice.toFixed(2)
                    }
                );

                if(this.userData.package_type > 0 && this.licenceDetails.user_count > 0) {
                    this.licencePlanDetails.push(
                        {
                            plan_name: 'User' + ' x ' + this.licenceDetails.user_count,
                            plan_type: this.activePriceView == 'monthly' ? 'Monthly' : (this.licenceDetails.period_day + ' Days'),
                            price: parseFloat(licenceUserPrice).toFixed(2),
                            user_price: licenceUserPrice.toFixed(2),
                            vat_amount: this.licenceDetails.is_add_vat == 1 ? parseFloat(userVatAmount) : 'None',
                            total: (parseFloat(licenceUserPrice) + parseFloat(userVatAmount)).toFixed(2),
                            is_add_vat: this.licenceDetails.is_add_vat,
                            user_count: this.licenceDetails.user_count,
                            monthly_price: parseFloat(licenceUserPrice).toFixed(2)
                        }
                    );
                }
            }

            if(this.activeView == 'addUser') {
                licenceUserPrice *= this.upgradeForm.user_count;
                userVatAmount = this.userData.is_add_vat == 1 ? parseFloat((licenceUserPrice / 100) * this.userData.vat_rate).toFixed(2) : 0;
                this.licencePlanDetails.push({
                    plan_name: 'User',
                    plan_type: this.activePriceView == 'monthly' ? 'Monthly' : (this.userData.period_end + ' Days'),
                    price: parseFloat(licenceUserPrice).toFixed(2),
                    user_price: licenceUserPrice.toFixed(2),
                    vat_amount: this.userData.is_add_vat == 1 ? parseFloat(userVatAmount) : 'None',
                    total: (parseFloat(licenceUserPrice) + parseFloat(userVatAmount)).toFixed(2),
                    is_add_vat: this.userData.is_add_vat,
                    user_count: this.upgradeForm.user_count,
                    monthly_price: parseFloat(licenceUserPrice * this.upgradeForm.user_count).toFixed(2)
                });
            }
            let subTotal = 0.00;
            let vatAmount = 0.00;
            let grandTotal = 0.00;
            this.licencePlanDetails.forEach(element => {
                subTotal += parseFloat(element.price);
                vatAmount += parseFloat(element.vat_amount);
                grandTotal += parseFloat(element.total);
            });

            this.totals.sub_total = subTotal.toFixed(2);
            this.totals.vat_amount = vatAmount.toFixed(2);
            this.totals.grand_total = grandTotal.toFixed(2);
            this.totals.monthly_price_total = (grandTotal / parseInt(this.upgradeForm.payment_period)).toFixed(2);
        }
        
        if(this.activeStep == 2){
            this.getCompanyInfo();
        }
        if(this.activeStep == 4 && this.upgradeForm.payment_method == 2) {
            this.getBankTransferDetails();
        }
    },
    onBackStep() {
        this.activeStep--;
    },
    onEditBillingAddress() {
        this.isEditBillingAddress = !this.isEditBillingAddress;
        this.form = this.companyInfo;
    },
    getCompanyInfo() {
        const parameters = {
          session_id: this.$getUser().session_id
        };  
        axios.post('/api/Lic/GetCompanyInfo', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.companyInfo = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    saveCompanyInfo() {
        const parameters = this.form;
        parameters.session_id = this.$getUser().session_id;
        axios.post('/api/Lic/SaveCompanyInfo', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.isEditBillingAddress = false;
                this.getCompanyInfo();
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onSelectAddress(item) {
        if(item.id == '-1') return;
        this.form.company_address = item.fullAddress;
        this.form.company_city = item.town_or_city;
        this.form.company_district = item.district;
        this.form.company_postcode = item.postcode;
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/LicenceGuideHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.isViewLicenceGuid = response.data;
            }
        })
    },
    openHelpPage(){
        const parameters = {
            session_id : this.$getUser().session_id,
            menu_selection : this.menuSelection
        };
        axios.post('api/HelpPage/GetPageContents', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.pageContents = response.data.contents;
            window.open(this.menuSelection);            
        })
        .catch(function (error) {
            console.log(error);               
        });    
    },
    getBankTransferDetails() {
        const parameters = {
          session_id: this.$getUser().session_id
        };
        const requestOne =  axios.post('/api/Lic/GetLicenceBankDescription', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.upgradeForm.licence_bank_description = responses[0].data;
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    copyToClipboard() {
        this.$refs.referenceNumber.select();
        document.execCommand('copy');
        this.isCopied = true;        
        setTimeout(() => {
            this.isCopied = false;
        }, 2000);
    }, 
    onCompletePayment(item) {
        this.userMail = this.$getUser().user_mail;
        this.stripeData.strsid = item.stripe_session_id;
        this.licenceList = [];
        this.licenceDetails = {
            parameter_type_name: item.description,
            period_day: item.period_day
        };
        this.upgradeForm.user_count = item.user_count;
        this.activeStep = 4;
        this.activePriceView = item.payment_type == 1 ? 'monthly' : 'annual';
        this.upgradeForm.payment_method = 1;
        this.totals.grand_total = item.grand_total;
        this.totals.monthly_price_total = item.grand_total;
    },
    onSubmitPayment () {  
      // Stripe redirection proceeds here
      this.$refs.checkoutRef.redirectToCheckout();
    },    
    startTimer() {
        const that = this;
        var timer = 20, minutes, seconds;
        const countdownInterval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            var display = document.querySelector('#payment_timer_text');
            if(display.textContent !== null && display.textContent !== undefined && display.textContent !== ''){			
            display.textContent = '('+ minutes + ":" + seconds + ')';
            }
            if (--timer < 0) {
                timer = 0;
                display.textContent = '(00:20)';
                clearInterval(countdownInterval);
                that.completeUpgrade();
            }
        }, 1000);
        this.countdownInterval = countdownInterval;
    },
    completeUpgrade() {
        if(this.countdownInterval) clearInterval(this.countdownInterval);
        
        if(this.$route.name == 'Home') {
            this.$router.go(0);
        }
        else if(this.$route.name == 'LicenceExpired') {
            this.$signOutWithTimer('We received your request!', 'We will activate your new plan once payment is received. </br> You will be automatically signed out in <b></b> seconds..!');
        }
        else {
            this.$router.push({ name: 'Home' });
        }
    },
    onCancelTransaction() {
        this.$swal.fire({
            title: "Are you sure you want to cancel this request?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CANCEL REQUEST",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                const params = {
                    session_id: this.$getUser().session_id, 
                    transaction_id: this.licenceTransactionId,
                    strsubid: ''
                };   
                const that = this;
                axios.post('api/Lic/CancelLicenceRequest', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') {
                        this.$Progress.finish();
                        this.$signOutWithTimer('Session terminated..!', 'Upgrade request has been cancelled! </br> You will be automatically signed out in <b></b> seconds. You must log in to the system again to continue.');
                    }
                })
                .catch(function () {
                    that.$Progress.finish();
                });
            }
        }); 
    }
  },
  created(){
    this.$removeSessionStorage('strsid');
  }
};
</script>