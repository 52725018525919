<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Company Infos') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Company Infos') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>   
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/Settings">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to Settings') }} </span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <div class="d-flex flex-column flex-lg-row">
                <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                    <div class="card card-flush pt-3 mb-5 mb-xl-10">
                        <div class="card-header">
                            <div class="card-title">
                                <h2 class="fw-bold">{{ $t('Product Details') }}</h2>
                            </div>
                        </div>

                        <div class="card-body pt-3">
                            <div class="mb-10">
                                <h5 class="mb-4">{{ $t('Billing Address') }}:</h5>
                                <div class="d-flex flex-wrap py-5">
                                    <div class="flex-equal me-5">
                                        <table class="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                                            <tbody>
                                                <tr>
                                                    <td class="text-gray-500 min-w-175px w-175px">{{ $t('Bill to') }}:</td>
                                                    <td class="text-gray-800 min-w-200px">
                                                        <span class="text-gray-800 text-hover-primary">{{licenceDetails.company_email}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-500">{{ $t('Customer Name') }}:</td>
                                                    <td class="text-gray-800">
                                                        {{userData.company_name}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-500">{{ $t('Address') }}:</td>
                                                    <td class="text-gray-800">
                                                        {{licenceDetails.company_address}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-500">{{ $t('Phone') }}:</td>
                                                    <td class="text-gray-800"> {{licenceDetails.phone_country_code}} {{licenceDetails.company_gsm}} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="flex-equal">
                                        <table class="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                                            <tbody>
                                                <tr>
                                                    <td class="text-gray-500 min-w-175px w-175px">{{ $t('Subscribed Product') }}:</td>
                                                    <td class="text-gray-800 min-w-200px">
                                                        <span class="text-gray-800 text-hover-primary">{{ licenceDetails.licence_name }}</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="text-gray-500">{{ $t('Subscription Fees') }}:</td>
                                                    <td class="text-gray-800">
                                                        {{licenceDetails.grand_total}} <b>{{userData.currency_code}}</b>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="text-gray-500">{{ $t('Billing method') }}:</td>
                                                    <td class="text-gray-800">
                                                        {{licenceDetails.payment_type_name}}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="text-gray-500">{{ $t('Currency') }}:</td>
                                                    <td class="text-gray-800">{{userData.currency_code}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-0">
                                <h5 class="mb-4">Subscribed Products:</h5>

                                <div class="table-responsive">
                                    <table class="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                                        <thead>
                                            <tr
                                                class="border-bottom border-gray-200 text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                <th class="min-w-150px">{{ $t('Product') }}</th>
                                                <th class="min-w-125px text-center">{{ $t('User') }}</th>
                                                <th class="min-w-125px text-end">{{ $t('End Date') }}</th>
                                                <th class="min-w-125px text-end">{{ $t('Price') }}</th>
                                                <th class="min-w-125px text-end">{{ $t('VAT') }}</th>
                                                <th class="min-w-125px text-end">{{ $t('Grand Total') }}</th>
                                            </tr>
                                        </thead>

                                        <tbody class="fw-semibold text-gray-800">
                                            <tr>
                                                <td>
                                                    <label class="w-150px">{{licenceDetails.licence_name}}</label>
                                                </td>
                                                <td class="text-center">{{licenceDetails.user_count}}</td>
                                                <td class="text-end"> 
                                                    {{previewDate(licenceDetails.period_end)}} 
                                                    <div class="fw-normal text-gray-600 fs-7">
                                                        <span v-if="licenceDetails.licence_remaining_day > 0"> 
                                                            {{parseInt(licenceDetails.licence_remaining_day)}} {{ $t('days left') }}
                                                        </span>
                                                        <span v-if="licenceDetails.licence_remaining_day < 0"> 
                                                            {{ $t('Expired') }} {{Math.abs(licenceDetails.licence_remaining_day)}} {{ $t('days ago') }}
                                                        </span>
                                                        <span v-if="licenceDetails.licence_remaining_day == 0"> {{ $t('Expired today') }}</span>
                                                    </div>
                                                </td>
                                                <td class="text-end">
                                                    {{licenceDetails.price}}
                                                </td>
                                                <td class="text-end">
                                                    {{licenceDetails.vat_amount}}
                                                </td>
                                                <td class="text-end">
                                                    {{licenceDetails.grand_total}} <b>{{userData.currency_code}}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-flush pt-3 mb-5 mb-xl-10">
                        <div class="card-header">
                            <div class="card-title">
                                <h2>{{ $t('Transactions') }}</h2>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div class="table-responsive min-h-300px">
                                <DxDataGrid :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="licenceTransactionsList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    @exporting="this.$onExporting($event , 'Licence-transactions')">

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="100"/>
                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                                    
                                    <DxColumn data-field="period_end" caption="Period End" data-type="date" format="dd.MM.yyyy" header-cell-template="period_end-header" />
                                    <DxColumn data-field="licence_name" caption="Licence" />
                                    <DxColumn data-field="price" caption="Price" />
                                    <DxColumn data-field="vat_amount" caption="Vat" />
                                    <DxColumn data-field="grand_total" caption="Total" />
                                    <DxColumn data-field="user_count" caption="User" />
                                    <DxColumn data-field="status_name" caption="Status" />
                                    <DxColumn data-field="document_limit" caption="Document Limit" header-cell-template="document_limit-header" />
                                    <DxColumn data-field="payment_type_name" caption="Payment Type" header-cell-template="payment_type_name-header" />
                                    <DxColumn data-field="is_company_inquiry_active" caption="Company Inquiry?" header-cell-template="is_company_inquiry_active-header" cell-template="is_company_inquiry_active-cell-template" />
                                    <DxColumn data-field="is_address_inquiry_active" caption="Address Inquiry?" header-cell-template="is_address_inquiry_active-header" cell-template="is_address_inquiry_active-cell-template" />

                                    <template #period_end-header>
                                        <div> Period </div> <div> End </div>
                                    </template>
                                    <template #document_limit-header>
                                        <div>Document</div> <div>Limit</div>
                                    </template>
                                    <template #payment_type_name-header>
                                        <div>Payment</div> <div>Type</div>
                                    </template>
                                    <template #is_company_inquiry_active-header>
                                        <div>Company</div> <div>Inquiry</div>
                                    </template>
                                    <template #is_address_inquiry_active-header>
                                        <div>Address</div> <div>Inquiry</div>
                                    </template>
                                    
                                    <template #is_company_inquiry_active-cell-template="{ data }">
                                        <span>
                                            <span class="text-gray-800 fw-bolder align-middle text-hover-primary mb-1">
                                                <i v-if="data.data.is_company_inquiry_active == 1" class="bi bi-check-circle fs-5 text-success"></i> 
                                                <div v-if="data.data.is_company_inquiry_active == 1" class="fs-8 text-gray-600">
                                                    <small class="text-muted">
                                                        {{data.data.company_inquiry_rate_limit}} requests per {{data.data.company_inquiry_exceed_period}} {{getPeriodTypeName(data.data.company_inquiry_period_type)}}.
                                                    </small>
                                                </div>
                                                <i v-if="data.data.is_company_inquiry_active == 0" class="bi bi-x-circle fs-5 text-danger"></i>
                                            </span>
                                        </span>
                                    </template>
                                    <template #is_address_inquiry_active-cell-template="{ data }">
                                        <span>
                                            <span class="text-gray-800 fw-bolder align-middle text-hover-primary mb-1">
                                                <i v-if="data.data.is_address_inquiry_active == 1" class="bi bi-check-circle fs-5 text-success"></i> 
                                                <div v-if="data.data.is_address_inquiry_active == 1" class="fs-8 text-gray-600">
                                                    <small class="text-muted">
                                                        {{data.data.address_inquiry_rate_limit}} requests per {{data.data.address_inquiry_exceed_period}} {{getPeriodTypeName(data.data.address_inquiry_period_type)}}.
                                                    </small>
                                                </div>
                                                <i v-if="data.data.is_address_inquiry_active == 0" class="bi bi-x-circle fs-5 text-danger"></i>
                                            </span>
                                        </span>
                                    </template>
                                </DxDataGrid>  
                            </div>
                        </div>
                    </div>
                    <div class="card card-flush pt-3 mb-5 mb-xl-10" v-if="licencePaymentPlanList.length > 0">
                        <div class="card-header">
                            <div class="card-title">
                                <h2>{{ $t('Payment Plan') }}</h2>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div class="table-responsive min-h-300px">
                                <DxDataGrid :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="licencePaymentPlanList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    @exporting="this.$onExporting($event , 'Licence-payment-plan')">

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="100"/>
                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                                    <DxColumn data-field="description" caption="Plan Name" header-cell-template="description-header" cell-template="description-cell-template"/>
                                    <DxColumn data-field="payment_type_name" caption="Payment Type" header-cell-template="payment_type-header" cell-template="payment_type-cell-template"/>
                                    <DxColumn data-field="payment_method" caption="Payment Method" header-cell-template="payment_method-header" cell-template="payment_method-cell-template"/>
                                    <DxColumn data-field="monthly_price" caption="Price" alignment="right" cell-template="monthly_price-cell-template"/>
                                    <DxColumn data-field="reference_number" caption="Reference Number" header-cell-template="reference_number-header" />
                                    <DxColumn data-field="value_date" caption="Value Date" data-type="date" format="dd.MM.yyyy" header-cell-template="value_date-header" cell-template="value_date-template"/> 
                                    <DxColumn data-field="status" caption="Status" alignment="center" cell-template="status-cell-template"/>

                                    <!-- Header Cell Templates -->
                                    <template #description-header>
                                        <div> Plan </div> <div> Name </div>
                                    </template> 
                                    <template #value_date-header>
                                        <div> Value </div> <div> Date </div>
                                    </template>
                                    <template #payment_method-header>
                                        <div> Payment </div> <div> Method </div>
                                    </template>
                                    <template #payment_type-header>
                                        <div> Payment </div> <div> Type </div>
                                    </template>
                                    <template #reference_number-header>
                                        <div> Reference </div> <div> Number </div>
                                    </template>

                                    <!-- Cell Templates -->
                                    <template #description-cell-template="{ data }">
                                        <span class="p-1 align-middle" >
                                        {{ data.data.description }} <span v-if="data.data.user_count > 0">(+{{ data.data.user_count }} {{ $t('User') }})</span>
                                        </span>
                                    </template>
                                    <template #payment_type-cell-template="{ data }">
                                        <span class="p-1 align-middle" >
                                        {{ data.data.payment_type_name }}
                                        </span>
                                    </template> 
                                    <template #monthly_price-cell-template="{ data }">
                                        <span class="p-1 align-middle" >
                                        {{ data.data.monthly_price }}
                                        </span>
                                    </template> 
                                    <template #value_date-template="{ data }">
                                        <span class="p-1 align-middle" >
                                        {{ previewDate(data.data.value_date) }}
                                        </span>
                                    </template>
                                    <template #status-cell-template="{ data }">
                                        <span class="badge p-1 align-middle" :class="{'badge-warning': data.data.status == 0, 'badge-success': data.data.status == 1, 'badge-danger': data.data.status == 2}">                                      
                                        {{ data.data.status_name }}
                                        </span>
                                    </template> 
                                    <template #payment_method-cell-template="{ data }">
                                        <span class="p-1 align-middle" >
                                        {{ data.data.payment_method_name }}
                                        </span>
                                    </template>
                                    <template #reference_number-cell-template="{ data }">
                                        <span class="p-1 align-middle" >
                                        {{ data.data.reference_number }}
                                        </span>
                                    </template>
                                </DxDataGrid>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
                    <div class="card card-flush mb-0 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow rounded bg-abstract-2">
                        <div class="card-header">
                            <div class="card-title">
                                <h2>{{ $t('Summary') }}</h2>
                            </div>
                        </div>

                        <div class="card-body pt-0 fs-6">
                            <div class="mb-7">
                                <div class="d-flex align-items-center">
                                    <div class="d-flex flex-column">
                                        <span class="fs-4 fw-bold text-gray-900 text-hover-primary me-2">{{userData.company_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="separator separator-dashed my-5"></div>
                            <div class="mb-7">
                                <h5 class="mb-4">{{ $t('Product Details') }}</h5>
                                <div class="mb-0">
                                    <span class="badge badge-light-primary me-2">{{ licenceDetails.licence_name }}</span>
                                    <span class="fw-semibold text-gray-600"> 
                                        {{licenceDetails.grand_total}} 
                                        <small class="fw-bold">{{userData.currency_code}}</small>
                                        <small v-if="licenceDetails.payment_type == 1"> / {{ $t('Month') }} </small>
                                        <small v-if="licenceDetails.payment_type == 2"> / {{ $t('Year') }} </small>
                                    </span>
                                </div>
                            </div>
                            <div class="separator separator-dashed my-5"></div>
                            <div class="mb-10">
                                <h5 class="mb-4">{{ $t('Subscription Details') }}</h5>
                                <table class="table fs-6 fw-semibold gs-0 gy-2 gx-2">
                                    <tbody>
                                        <tr class="">
                                            <td class="text-gray-500">{{ $t('Licence Number') }}:</td>
                                            <td class="text-gray-800">{{licenceDetails.licence_number}}</td>
                                        </tr>

                                        <tr class="">
                                            <td class="text-gray-500">{{ $t('Started') }}:</td>
                                            <td class="text-gray-800"> {{previewDate(licenceDetails.created_at)}} </td>
                                        </tr>

                                        <tr class="">
                                            <td class="text-gray-500">{{ $t('Status') }}:</td>
                                            <td>
                                                <span class="badge" :class="{'badge-warning': licenceDetails.status == 0, 'badge-info': licenceDetails.status == 1, 'badge-success': licenceDetails.status == 2}"> 
                                                    {{licenceDetails.status_name}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr class="">
                                            <td class="text-gray-500">{{ $t('Next Invoice') }}:</td>
                                            <td class="text-gray-800">{{previewDate(licenceDetails.period_end)}}</td>
                                        </tr>
                                        <tr class="">
                                            <td class="text-gray-500">{{ $t('Remain') }}:</td>
                                            <td class="text-gray-800">{{licenceDetails.licence_remaining_day}} {{ $t('Day') }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card card-flush mt-10 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow rounded bg-abstract-2">
                        <div class="w-100 card-body d-flex flex-column d-flex flex-column-fluid py-10 px-10">
                            <div class="mb-7">
                                <h3 class="text-gray-900 fw-bolder">
                                    {{licenceDetails.licence_name}} 
                                </h3>
                            </div>                    
                            <div class="w-100">
                                <div class="d-flex align-items-center">
                                    <span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                        {{licenceDetails.user_count}} Active Users 
                                    </span>
                                    <i class="bi bi-check-circle fs-3 text-success"></i>
                                </div>
                                <div class="d-flex flex-column content-justify-center flex-grow-1" v-for="moduleItem in getModuleTitles" :key="moduleItem">
                                    <div class="d-flex fs-6 fw-semibold align-items-center border-bottom border-2 border-primary my-5">
                                        <h5 class="my-3"> {{moduleItem.name}} </h5>
                                    </div>
                                    <div class="d-flex fs-6 fw-semibold align-items-center my-1" v-for="subItem in moduleItem.items" :key="subItem">
                                        <span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3" v-if="subItem">
                                            {{subItem.name}}
                                        </span>
                                        <i class="bi bi-check-circle fs-3 text-success"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
export default {
  name: "CompanyInfos",
  data() {
        return {
            userData: this.$getUser(),
            lists: [],
            licenceDetails: {},
            isProgressing: false,
            menuSelection: '/CompanyInfosHelppage',
            iconView : false,
            licencePaymentPlanList: [],
            licenceTransactionsList: [],
            periodTypeList: [
                { id: 1, text: 'Minute' },
                { id: 2, text: 'Hour' },
                { id: 3, text: 'Day' },
                { id: 4, text: 'Week' },
                { id: 5, text: 'Month' },
                { id: 6, text: 'Year' },
            ],
            modules: []
        };
    },
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
    computed: {        
        previewDate(){
            return (value)=>{
                if (value) {
                    return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        getPeriodTypeName(){
            return (value)=>{
                if (value) {
                    return this.periodTypeList.find(p => { 
                        return p.id == value;
                    }).text; 
                }
            } 
        },
        getModuleTitles() {
            return this.modules.map((i) => {
                i.items = [];
                if (i.type == 1) { 
                    this.modules.forEach(element => {
                        if(element.parent_id == i.id) { 
                            i.items.push(element);
                        }
                    });
                }
                return i;
            }).filter(x => x.type == 1);
        }
    },
    methods: {
        getData() {
            const parameters = {
              session_id: this.$getUser().session_id
            };  
            axios.post('/api/Auth/GetCompanyInfos', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.licenceDetails= response.data.licence;
                    this.licencePaymentPlanList= response.data.payment_plan;
                    this.licenceTransactionsList= response.data.transactions;
                    this.modules= response.data.modules;
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        },
        async helpPageViewIcon(){
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/CompanyInfosHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        },
    }, 
    created() {
        this.helpPageViewIcon();
        this.getData();
    }
}; 
</script>