<template>
    <div class="modal-dialog modal-dialog-centered modal-xxl" :class="{'modal-xxl-90': accountType == 7}">
        <div class="modal-content"  >
            <div class="modal-header bg-primary py-3">
                <h2 class="modal-title fw-bolder col-md-10 text-white">
                    <span v-if="accountType == 7 && processType == 1"> {{ $t('Search Stocks') }}</span>
                    <span v-if="processType == 2"> {{ $t('Search Fixed Assets') }}</span>
                    <span v-if="accountType !== 7 && processType !== 2">{{ $t('Search Accounts') }}</span>
                </h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeSearchModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body p-5 scroll-y" :class="{'overlay overlay-block': this.isLoading == true}">
                <div class="row mt-5">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="fv-row row d-flex justify-content-end">
                                        <div class="col-lg-3">
                                            <label class="fs-6 fw-bold form-label">
                                                {{ $t('Search By') }}
                                            </label>
                                            <Select2 v-model="searchParameter" :options="filteredSearchParameterList" :settings="{ width: '100%', dropdownParent: accountType == 7 ? '#kt_search_stock_account_modal .modal-content' : '#kt_search_account_modal .modal-content'}"/>
                                        </div>
                                        <div class="col-lg-3">
                                            <label class="fs-6 fw-bold form-label">                                                
                                                <span v-if="searchParameter == 1 && processType == 2">{{ $t('Asset Name') }}</span>

                                                <span v-if="searchParameter == 1 && accountType == 7 && processType == 1">{{ $t('Stock Name') }}</span>
                                                <span v-if="searchParameter == 2 && accountType == 7">{{ $t('Product Code') }}</span>

                                                <span v-if="searchParameter == 1 && accountType !== 7 && processType !== 2">{{ $t('Account Name') }}</span>
                                                <span v-if="searchParameter == 2 && accountType !== 7 && processType !== 2">{{ $t('Registration Number') }}</span>
                                            </label>
                                            <input type="search" class="form-control form-control-lg" name="accountName" v-model="searchText" @keyup.enter="getAccounts(searchText)" :placeholder="$t('Type at least 3 characters to search account')">
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">
                                                <button type="button" class="btn btn-primary border border-primary me-3" @click="getAccounts(searchText)" :disabled="searchText.length < 3">
                                                    <i class="fa fa-search"></i>
                                                    {{ $t('Search') }}
                                                </button>
                                                <button v-if="this.$getUser().is_allow_list_all_data" type="button" class="btn btn-light border border-gray-400 me-3" @click="getAccounts('')" data-kt-indicator="on">
                                                    <span class="svg-icon svg-icon-muted">
                                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    <span v-if="processType == 1 && accountType == 7"> {{ $t('All Stocks') }}</span>
                                                    <span v-else> {{ $t('All Accounts') }}</span>
                                                </button>

                                                <button v-if="isCreateAccount" ref="createAccountButton" class="btn btn-light-success border border-success btn-sm justify-content-end" @click="openCreateNewModal()">
                                                    <span class="svg-icon svg-icon-success svg-icon-2">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Create New') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div class="col-lg-8 col-sm-12 mt-5" :class="{'col-lg-12': accountType !== 7 || !isViewStockTrackingList}">
                        <div class="card h-xl-100">
                            <div class="card-body py-3">
                                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="table-responsive min-h-300px">
                                        <DxDataGrid id="gridContainer" 
                                                :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="accountList" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true" 
                                                :ref="'dataGrid'"
                                                @exporting="this.$onExporting($event , 'Search-Account')" > 
                                                
                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="30"/>
                                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                                <DxSearchPanel :visible="true"/> 
                                                
                                                <DxColumn data-field="detail" caption="..." cell-template="detail-cell-template" alignment="left" :allow-exporting="false" width="100"/>
                                                <DxColumn v-if="accountType !== 7" data-field="account_number" :caption="$t('Registration Number')" header-cell-template="account_number-header" alignment="left" cell-template="account_number-cell-template"/> 
                                                <DxColumn v-if="accountType == 7" data-field="product_code" :caption="$t('Product Code')" header-cell-template="product_code-header" alignment="left" cell-template="product_code-cell-template"/> 
                                                <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/> 
                                                <DxColumn v-if="accountType !== 7" data-field="balance" :sorting-method="this.$numericSort" :caption="$t('Balance')" alignment="right" cell-template="balance-cell-template"/>
                                                <DxColumn v-if="accountType == 7" data-field="item_remain" :caption="$t('Remain')" alignment="right" cell-template="item_remain-cell-template"/>
                                                <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template"/> 
                                                <DxColumn v-if="accountType !== 7" data-field="company_account_type_name" :caption="$t('Company Type')" header-cell-template="company_account_type_name-header" alignment="left" cell-template="company_account_type_name-cell-template"/>
                                                <DxColumn data-field="account_type_name" :caption="$t('Account Type')" header-cell-template="account-type-header" alignment="left" cell-template="account_type_name-cell-template"/>
                                                <DxColumn v-show="accountType !== 7"  data-field="account_sub_type" :caption="$t('Account Sub Type')" header-cell-template="account-sub-type-header" alignment="left" cell-template="account-sub-type-cell-template"/>

                                                <DxTooltip :hide-on-outside-click="false" target="#viewed" show-event="mouseenter" hide-event="mouseleave"> view stock tracking groups</DxTooltip>
                                                <template #account-type-header>
                                                    <div>{{ $t('Account') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #company_account_type_name-header>
                                                    <div>{{ $t('Company') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #account_number-header>
                                                    <div>{{ $t('Registration') }}</div><div>{{ $t('Number') }}</div>
                                                </template>
                                                <template #product_code-header>
                                                    <div>{{ $t('Product') }}</div><div>{{ $t('Code') }}</div>
                                                </template>
                                                <template #account-sub-type-header>
                                                    <div>{{ $t('Account') }}</div><div>{{ $t('Sub') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #detail-cell-template="{ data }">
                                                        <button v-if="data.data.account_type == 7" type="button" id="viewed" class="btn btn-xs btn-primary"  @click="getStockTrackingGroups(data.data)"
                                                            data-bs-toggle="tooltip" data-bs-trigger="hover" title="View Stock Tracking Groups">
                                                            <i class="bi bi-eye-fill fs-5 pe-0" id="viewed" ></i>
                                                        </button> 
                                                        <button type="button" class="btn btn-xs btn-primary " style="margin-left:2px;" @click="onSelectAccount(data.data)">Select</button>
                                                </template>
                                                <template #account_number-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-gray-800 fw-bold text-hover-primary cursor-pointer mb-1">
                                                        {{ data.data.account_number }}
                                                    </span>
                                                </template>
                                                <template #product_code-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-gray-800 fw-bold text-hover-primary cursor-pointer mb-1">
                                                        {{ data.data.product_code }}  
                                                        <div v-if="data.data.is_bundle_product == 1 && data.data.account_type == 7" class="ms-2 badge badge-sm badge-success">Bundle</div>
                                                    </span>
                                                </template>
                                                <template #account_type_name-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-gray-800 text-hover-primary cursor-pointer mb-1">
                                                        {{ data.data.account_type_name }} 
                                                    </span>
                                                </template>
                                                <template #company_account_type_name-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-gray-800 text-hover-primary cursor-pointer mb-1">
                                                        {{ data.data.company_account_type_name }}
                                                    </span>
                                                </template>
                                                <template #account_id-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-hover-primary cursor-pointer mb-1">
                                                        <div class="text-gray-900">
                                                            {{ data.data.account_id }} 
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #name-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-gray-800 fw-bold text-hover-primary cursor-pointer mb-1">
                                                        {{ data.data.name }} 
                                                    </span>
                                                </template>
                                                <template #item_remain-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-hover-primary cursor-pointer mb-1" :class="{ 'text-danger': (parseFloat(data.data.item_remain) == 0) || (parseFloat(data.data.item_remain) < 0), 'text-gray-900': parseFloat(data.data.item_remain) > 0}">
                                                        <span v-if="data.data.account_type == 7">{{ data.data.item_remain }}</span>
                                                        <span class="text-gray-900" v-else> <i class="bi bi-dash-lg"></i> </span>
                                                    </span>
                                                </template>
                                                <template #balance-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-gray-800 text-hover-primary cursor-pointer mb-1">
                                                        {{ data.data.balance }}
                                                    </span>
                                                </template>
                                                <template #account-sub-type-cell-template="{ data }">
                                                    <span @click="onSelectAccount(data.data)" class="text-hover-primary cursor-pointer mb-1">
                                                        <span v-if="data.data.account_type == 7">{{ data.data.account_sub_type == 0 ? 'Stock Account' : data.data.account_sub_type == 1 ? 'Service Account' : 'Property Account' }}</span>
                                                        <span class="text-gray-900" v-else> <i class="bi bi-dash-lg"></i> </span>
                                                    </span>
                                                </template> 

                                                <DxSummary>
                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="detail"/>
                                                </DxSummary>
                                        </DxDataGrid>   
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12 mt-5" v-if="accountType == 7 && isViewStockTrackingList">
                        <div class="card h-xl-100">
                            <div class="card-header border-0 pt-5">
                                <div class="card-title">
                                    {{ $t('Stock Tracking Groups') }}
                                </div>
                                <div class="card-toolbar">
                                    <i class="bi bi-x-lg fs-3 cursor-pointer" @click="isViewStockTrackingList = false"></i>
                                </div>
                            </div>
                            <div class="card-body py-3">         
                                <div class="table-responsive" v-if="stockTrackingListItems.length > 0">
                                    <table class="table table-rounded table-striped border gy-2 gs-2">
                                        <thead>
                                            <tr class="fw-bold fs-7 text-gray-800 border-bottom-2 border-gray-200">
                                                <th>{{ $t('Group') }}</th>
                                                <th>{{ $t('Value') }}</th>
                                                <th>{{ $t('Description') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="stockTrackingListItems">
                                            <tr class="fs-7 fw-bold" v-for="data in stockTrackingListItems" v-bind:key="data">
                                                <td>{{ data.tracking_group_name }}</td>
                                                <td>{{ data.item_value }}</td>
                                                <td>{{ data.description }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> 
                                <h5 v-if="stockTrackingListItems.length == 0"> {{ $t('No results found') }}.</h5>                      
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isLoading == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ $t('Searching Accounts') }}...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid';
export default {
    name: 'SearchAccountWithSelect',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem
    },
    data() {
        return {
            accountList: [],
            stockTrackingListItems: [],
            isLoading: false,
            searchText: '',
            accountType: null,
            processType: null,
            invoiceType: null,
            isViewStockTrackingList: false,
            searchParameter: 1,
            searchParameterList: [
                { id: 1, text: 'Asset Name', account_type: 8},
                { id: 1, text: 'Stock Name', account_type: 7},
                { id: 1, text: 'Account Name', account_type: 12},
                { id: 2, text: 'Product Code', account_type: 7},
                { id: 2, text: 'Registration Number', account_type: 12},
            ]
        }
    },
    computed: {
        filteredSearchParameterList(){
            if(this.accountType == 7 && this.processType == 1){ 
                return this.searchParameterList.filter(p => { 
                    return p.account_type == 7;
                });
            }
            else{
                if(this.processType == 2){ 
                    return this.searchParameterList.filter(p => { 
                        return p.account_type == 8;
                    });
                } else {
                    return this.searchParameterList.filter(p => { 
                        return p.account_type == 12;
                    });
                }
            }  
        },
        showPageSizeSelector() {
            return this.accountList.length > this.pageSize;
        },
        pageSizes() {
            let arr = [];
            let x = 1;
            let loop = (this.accountList.length / this.pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(100);
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    methods: {     
        getData(account_type, process_type, invoice_type, search_text, is_create_account) {
            this.accountList = [];
            this.searchText = search_text ? search_text : '';
            this.isLoading = false;
            this.searchParameter = 1;
            this.isViewStockTrackingList = false;
            this.accountType = account_type;
            this.processType = process_type;
            this.invoiceType = invoice_type;
            this.isCreateAccount = is_create_account;
            
            if(account_type == 7) {
                setTimeout(() => {
                    this.$parent.isViewSearchStockAccountModal = true;
                }, 100);
            }

            if(this.searchText) {
                this.getAccounts(search_text);
            }
        },
        getAccounts(search_text) {
            if((search_text === null || search_text === undefined || search_text.length < 3) && !this.$getUser().is_allow_list_all_data) return;
            this.searchText = search_text;
            if(this.$parent.$refs.stockItemModal) {this.$parent.$refs.stockItemModal.style.display = 'inline !important';}
            this.isLoading = true;
            this.isViewStockTrackingList = false;
            this.accountList = [];
            const params = {
                session_id: this.$getUser().session_id,
                account_type: this.accountType,
                search_text: search_text,
                search_parameter: this.searchParameter,
                process_type: this.processType,
                invoice_type: this.invoiceType
            };  
            let url = 'api/Invoice/GetAccountsWithSearch';
            if(this.accountType == 7) { url = 'api/Invoice/GetAccountsForInvoice'; }

            const that = this;
            axios.post(url, params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.accountList = response.data;
            })
            .catch(function() {
                that.isLoading = false;
            })
            .finally(function () {
                that.isLoading = false;
            });
        },
        getStockTrackingGroups(item){
            if(item.account_type !== 7) return;

            this.isViewStockTrackingList = true;
            this.stockTrackingListItems = [];
            const parameters = { 
                session_id: this.$getUser().session_id,
                account_id: item.id
            }; 
            axios.post('/api/StockTrackingGroups/GetStockTrackingGroupsItemsSelected', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                    this.stockTrackingListItems = response.data;
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });          
        },
        onSelectAccount(item) {            
            this.$refs.closeSearchModal.click();
            this.$parent.getAccountDetail(item.id);
            this.$parent.isViewSearchStockAccountModal = false;
        },
        onCloseModal() {
            this.$parent.isViewSearchStockAccountModal = false;
        },
        openCreateNewModal() {
            if(this.accountType == 7) {
                this.$refs.closeSearchModal.click();                
                if(this.processType == 2) this.$parent.openCreateAssetModal();
                else this.$parent.openCreateStockModal();
            }
            else {
                this.$parent.openCreateContactModal();
            }
        }
    },
    created() {
        this.getData();
    }
}
</script>