<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">
            {{ $t('Unit 2 Type List') }}
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">{{ $t('List Types') }}</router-link></li>    
              <li class="breadcrumb-item text-gray-600">{{ $t('Unit 2 Type List') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
              </li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/ListTypes">
            <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
               <span class="ms-2 fs-7"> {{ $t('Return back to List Types') }} </span>
             </button>
            </router-link>
        </div> 
      </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content">
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                        <router-link to="/UnitType2Create">
                                <button type="button" class="btn btn-light-primary border border-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                  </span>{{ $t('Add Unit 2 Type') }}</button>
                            </router-link>
                      </div>
                  </div>
                  <div class="card-toolbar">
                      <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                          <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> {{ $t('List Unit 2 Types') }}</strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                      </div>
                      <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                          <div class="fw-bolder me-5">
                          <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                          <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                      </div>
                  </div>
              </div>
              <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">                      
                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Unit-Type2')">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="10"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="auth_type" :caption="$t('Authorized User')" header-cell-template="authorized-user-header" cell-template="authorized_user-cell-template"/> 
                                <DxColumn data-field="code" :caption="$t('Code')" cell-template="code-cell-template"/> 
                                <DxColumn data-field="name" :caption="$t('List Name')" cell-template="list_name-cell-template"/>
                                <DxColumn data-field="hide" :caption="$t('Is Hide?')" alignment="center" cell-template="hide-cell-template" :allow-filtering="false"/> 
                                <DxColumn data-field="created_by" :caption="$t('Created By')" cell-template="created-by-cell-template"/> 

                                 
                                <!-- Header Cell Templates -->
                                <template #authorized-user-header>
                                    <div> {{ $t('Authorized') }} </div> <div> {{ $t('User') }} </div>
                                </template> 

                                <!-- Cell Templates -->
                                <template #authorized_user-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                      class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        {{data.data.auth_type}}
                                    </a>
                                </template>
                                <template #code-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                      class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        <span class="badge badge-circle badge-light me-3" v-if="data.data.full_access == 1">
                                            <i class="bi bi-pencil text-gray-900"></i> 
                                        </span> 
                                        <span> {{ data.data.code }} </span>
                                    </a>
                                </template>
                                <template #list_name-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                      class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                        {{ data.data.name }}
                                    </a>
                                </template>
                                <template #hide-cell-template="{ data }">
                                    <input class="form-check-input" type="checkbox" value="" id="hide" v-model="data.data.hide" @change="setListItemHide(data.data)">
                                </template>
                                <template #created-by-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                      class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        {{ data.data.created_by }}
                                    </a>
                                </template>
                        </DxDataGrid> 
                    </div>
                  </div> 
              </div>
          </div> 
      </div> 
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `UN2L001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling  } from 'devextreme-vue/data-grid';
 
export default {
  name: "UnitType2List",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists: [],
      searchbar: '',
      isListProgressing: false,
      menuSelection: '/Unit2TypeHelppage',
        iconView : false
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.lists = [];
      this.isListProgressing = true;
      const params = {
        session_id: this.$getUser().session_id,
        list_type: 10, // Unit 2 Type: 9
      };
      axios.post('/api/Helper/GetHideLists', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.lists = response.data; 
          this.$setSessionStorage('data' + '_UN2L001', response.data)
          this.$Progress.finish();
          setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
          console.log(error); 
          this.$removeSessionStorage('data' + '_UN2L001');
          this.$Progress.finish();
          setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
    },
    updateType(item){ 
        if (item.full_access == 0) return;
        this.$setSessionStorage('record_id', item.id);
        this.$router.push({ name: 'UnitType2Update' });
    },
    setListItemHide(data) {
        this.$swal.fire({
            title: data.hide == true ? 'Are you sure to hide this selected data from the lists?' : 'Are you sure to show this selected data from the lists?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
        }).then((result) => { 
            if (result.isConfirmed) {
                const params = {
                  session_id: this.$getUser().session_id,
                  list_type: 1,
                  hide: data.hide,
                  id: data.id
                };      
                params.hide = params.hide == true ? 1: 0;
                axios.post('/api/Helper/SetListItemHide', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.getData();
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                    this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                });
            }
            else{
                data.hide = !data.hide;
            }
        });  
    },
    async helpPageViewIcon(){
    const queryparameter = {
        session_id : this.$getUser().session_id,
        menu_selection : '/Unit2TypeHelppage'
    };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() { 
    this.helpPageViewIcon();
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList'); 
    } 
    else{
        this.lists = [];
    }
  }
}; 
</script>