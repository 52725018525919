<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800"> {{ $t('Update Offer') }} </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-primary">{{ $t('Transactions') }}</li>
                    <li class="breadcrumb-item text-gray-600">
                        {{ $t('Update Offer') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1" v-if="getSubCredentials('R')">                
                <button type="button" class="btn btn-light border border-gray-400" data-bs-toggle="modal" data-bs-target="#kt_modal_transaction_list" @click="onOpenTransactionListModal()" :disabled="!this.$getUser().is_allow_list_all_data && (this.form.account_id == null || this.form.account_id == undefined || this.form.account_id == '-1')" >
                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                    </svg>
                    </span>
                    {{ $t('Transaction List') }}
                </button>
            </div>
        </div>
        <div class="content flex-row-fluid ">
            <div class="tab-content" id="myTabContent">
                <RequiredField></RequiredField>
                <div class="alert alert-dismissible border d-flex flex-column flex-sm-row w-100 p-5 mb-10"
                    :class="{'bg-light-info border-info': form.position == 1, 'bg-light-success border-success': form.position == 2 || form.position == 6, 'bg-light-primary border-primary': form.position == 3, 'bg-light-danger border-danger': form.position == 4 || form.position == 7}">
                    <div class="d-flex flex-column pe-0 pe-sm-10">
                        <div>
                            <i class="bi bi-calendar-event-fill"></i> <b> {{previewDate(form.position_date)}} </b> 
                        </div>
                        <h5 class="my-1">{{ $t('Last position of this operation is') }} "{{this.form.position_name}}" </h5>                        
                        <span> {{form.description}} </span> 
                        <span v-if="form.document_no !== null && form.document_no !== ''"> {{ $t('Document No') }}: {{form.document_no}} </span> 
                        <span v-if="form.is_invoice_arranged == 1"> {{ $t('Invoice Issued') }}. {{ $t('Invoice No') }}: {{form.invoice_no}} </span>
                    </div>
                </div>
                <div class="card card-xxl-stretch ribbon ribbon-top">
                    <div class="ribbon-label" :class="{'bg-success': form.is_active == 1, 'bg-danger': form.is_active !== 1}">
                        <span v-if="form.is_active == 1"> {{ $t('Active') }} </span>
                        <span v-else> {{ $t('Passive') }} </span>
                    </div>
                    <div class="card-body p-5">
                        <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-4">
                            <li class="nav-item">
                                <a class="nav-link" :class="{'active': this.activeTab == 'Account'}" data-bs-toggle="tab" href="#kt_accounting_1" target="_self"
                                    @click="this.activeTab = 'Account'">
                                    <h4> {{ $t('Offer Transaction') }} </h4>
                                </a>
                            </li> 
                            <li class="nav-item" v-if="this.$isModuleActive(3)">
                                <a class="nav-link" :class="{'active': this.activeTab == 'Documents'}" data-bs-toggle="tab" href="#kt_accounting_4" target="_self" @click="activateDocumentsSection()">
                                    <h4> {{ $t('Documents') }} </h4>
                                </a>
                            </li>
                        </ul>
                    </div> 

                    <!-- <StatsBar></StatsBar> -->
                    <div id="kt_accounting_1" class="tab-pane fade show active" v-if="this.activeTab == 'Account'" role="tabpanel">
                        <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                            <div class="row">
                                <div class="card-toolbar">
                                    <div class="card card-xxl-stretch ">
                                        <div class="card-header border-0 min-h-45px ">
                                           <div class="card-title">
                                                 <!-- <Popper hover placement="top">
                                                    <i class="fa fa-regular fa-lock-open fs-4 fw-bolder pe-3 text-primary cursor-pointer"> </i>
                                                    <template #content>
                                                        <span class="fs-7 text-gray-800"> {{ $t('Company') }} </span>
                                                    </template>
                                                </Popper>
                                                <Popper hover placement="top">
                                                    <i class="fa fa-regular fa-lock fs-4 fw-bolder pe-3 text-danger cursor-pointer"></i>
                                                    <template #content>
                                                        <span class="fs-7 text-gray-800"> {{ $t('Controller') }} </span>
                                                    </template>
                                                </Popper>
                                                <Popper hover placement="top">
                                                    <i class="fa fa-regular fa-lock-open fs-4 fw-bolder text-warning opacity-100 cursor-pointer"></i>
                                                    <template #content>
                                                        <span class="fs-7 text-gray-800"> {{ $t('System') }} </span>
                                                    </template>
                                                </Popper>-->
                                            </div> 
                                            <div class="d-flex justify-content-end py-4">
                                                &nbsp;
                                                <router-link to="/OfferCreate" v-if="this.$isCredentialActive(81,'I')">
                                                    <button type="button" class="btn btn-light-primary border border-primary me-3">
                                                        <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                        </span>
                                                        {{ $t('Add a New Offer Operation') }}
                                                    </button>
                                                </router-link> 
                                                <div class="dropdown" v-if="getSubCredentials('P')">
                                                    <button class="btn btn-light-success border border-success dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="invoicePrintActions" type="button">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                        {{ $t('Operations') }}
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="invoicePrintActions"> 
                                                        <li>
                                                            <a class="dropdown-item fs-4 py-4" href="#" @click="onOpenUpdatePositionModal()"
                                                                data-bs-toggle="modal" data-bs-target="#kt_modal_update_position">
                                                                <i class="fa fa-print me-2"></i>
                                                                <span>{{ $t('Update Position') }}</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item fs-4 py-4" href="#" @click="onViewOperations()" 
                                                                data-bs-toggle="modal" data-bs-target="#kt_modal_operation_list">
                                                                <i class="fa fa-eye me-2"></i>
                                                                <span>{{ $t('View Operations') }}</span>
                                                            </a>
                                                        </li>
                                                        <li v-if="!form.is_invoice_issued">
                                                            <a class="dropdown-item fs-4" href="#" @click="saveAsInvoice()">
                                                                <i class="fas fa-file-invoice me-2"></i> 
                                                                {{ $t('Create Invoice Draft') }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>  
                                                <div class="dropdown ms-3">
                                                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="invoiceActions" type="button">
                                                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                                    </svg></span>
                                                        {{ $t('Actions') }}
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="invoiceActions"> 
                                                        <li v-if="this.$isAdmin() && selectedAccount.email !== null && selectedAccount.email !== '' && selectedAccount.email !== undefined">
                                                            <a class="dropdown-item fs-4" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_send_offer_view">
                                                                <i class="fas fa-envelope me-2"></i> 
                                                                <span>{{ $t('Send document by email') }}</span>
                                                            </a>
                                                            <hr class="dropdown-divider border-gray-300">
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item fs-4" :class="{'text-danger': this.form.is_active, 'text-success': !this.form.is_active}" href="#" @click="onChangeOfferStatus()">
                                                                <span v-if="!this.form.is_active">{{ $t('Activate Offer') }}</span>
                                                                <span v-if="this.form.is_active">{{ $t('Deactivate Offer') }}</span>
                                                            </a>
                                                            <hr class="dropdown-divider border-gray-300">
                                                        </li>
                                                        <li v-if="getSubCredentials('D')">
                                                            <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteOffer()">
                                                                {{ $t('Delete') }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="dropdown ms-3" v-if="getSubCredentials('P')">
                                                    <button class="btn btn-light-warning border border-warning dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="invoicePrintActions" type="button">
                                                        <i class="fa fa-print"></i>
                                                        {{ $t('Print Actions') }}
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="invoicePrintActions"> 
                                                        <li>
                                                            <a class="dropdown-item fs-4 text-primary py-4" href="#" @click="printOffer()" 
                                                                data-bs-toggle="modal" data-bs-target="#kt_modal_print">
                                                                <i class="fa fa-print text-primary fs-5 me-2"></i>
                                                                <span>{{ $t('Print') }}</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item fs-4 text-primary py-4" href="#" @click="downloadOffer()">
                                                                <i class="fa fa-file text-primary fs-5 me-2"></i> {{ $t('Download PDF') }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card card-xxl-stretch">
                                <div class="row p-4">
                                    <div class="d-flex flex-row row" v-if="form.activeView == 2"> 
                                        <div class="d-flex flex-column flex-row-auto col-lg-4"> 
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                                <div class="card-header rounded-2 rounded-bottom-0 bg-gray-gradient min-h-40px">
                                                    <div class="card-title">
                                                        <h3>{{ $t('Account') }}</h3>
                                                    </div>
                                                </div> 
                                                <div class="card-body pb-0">
                                                    <div class="row">
                                                        <div class="col-lg-9">
                                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row">
                                                                <span class="fs-2 fw-bolder text-gray-800">{{selectedAccount.name}}<br>
                                                                <span class="text-gray-700">{{selectedAccount.account_number}}</span></span> 
                                                            </div>
                                                            <div class="separator border-gray-200 mt-4" v-if="selectedAccount.address !== '' && selectedAccount.address !== null"></div>

                                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                                <span class="fs-6 fw-bolder text-gray-700 mt-2">
                                                                    {{selectedAccount.address}}
                                                                </span>
                                                            </div>                                            
                                                            <div class="separator border-gray-200 mt-4" v-if="selectedAccount.email !== '' && selectedAccount.email !== null"></div>  

                                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                                <a href="{{selectedAccount.email}}" class="fw-bold fs-6"> {{selectedAccount.email}} </a>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                                <span class="fs-6 text-gray-700 fw-bold"> {{selectedAccount.mobile_phone}} </span>
                                                            </div>

                                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2" v-if="selectedAccount.vat_number">
                                                                <span class="fs-6 text-gray-700 text-nowrap">
                                                                    {{ $t('VAT') }}: <b> {{selectedAccount.vat_number}} </b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 d-flex justify-content-end">
                                                            <div class="symbol symbol-100px cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()">
                                                                <div class="symbol-label fw-bold bg-light border text-hover-primary">
                                                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ribbon ribbon-triangle rounded-bottom-end-2 ribbon-bottom-end border-gray-200" data-bs-toggle="tooltip" data-bs-trigger="hover" title="Update Account">
                                                    <i class="cursor-pointer bi bi-pencil position-relative me-n6 d-block fs-3 text-gray-600" data-bs-toggle="modal" data-bs-target="#kt_modal_update_account" @click="onOpenUpdateAccount(selectedAccountInfo)" @refresh="getAccountDetail(selectedAccountInfo.id)"></i>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="d-flex flex-column flex-row-auto col-lg-5">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                                <div class="card-header rounded-2 rounded-bottom-0 bg-gray-gradient min-h-40px">
                                                    <div class="card-title">
                                                        <h3 v-if="form.offer_type == 1"> {{ $t('Sales Offer') }} </h3>
                                                        <h3 v-if="form.offer_type == 2"> {{ $t('Purchase Offer') }} </h3>
                                                    </div>
                                                </div>
                                                <div class="card-body pt-2">
                                                    <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                                                        <div class="col">
                                                            <div class="fv-row mb-1 fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'record_date'">
                                                                    {{ $t('Record Date') }} 
                                                                </label>
                                                                <Datepicker name="record_date" :required="this.$checkIfDateFieldRequired ('record_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.offer_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                                    :enableTimePicker="false" @update:modelValue="onChangeOfferDate()" :disabled="this.itemList.length > 0"/>
                                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'offer_number'">
                                                                    {{ $t('Offer Number') }}
                                                                </label>
                                                                <input type="text" class="form-control fw-boldest text-black-600" name="offerNumber" v-model="form.offer_no" disabled>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="fv-row mb-1 fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'due_date'">
                                                                    {{ $t('Due Date ') }}  
                                                                </label>
                                                                <Datepicker name="due_date" :required="this.$checkIfDateFieldRequired ('due_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.option_date" 
                                                                    format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false"/>
                                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </div> 
                                                        <div class="col">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'discount'">
                                                                    {{ $t('Discount') }} %
                                                                </label>
                                                                <CurrencyInput type="text" step="any" class="form-control text-end" name="discount" v-custom v-model="this.form.discount" @focus="$event.target.select()" :disabled="this.itemList.length > 0"/>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                                {{ $t('Branch') }}
                                                            </label>
                                                            <Select2 v-model="form.branch_id" :options="branchList" :disabled="this.itemList.length > 0" name="branch" v-custom :settings="{ width: '100%'}"/>
                                                        </div>
                                                        <!-- <div class="col">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'process'">
                                                                    {{ $t('Process') }}
                                                                </label>
                                                                <Select2 v-model="form.process_type" :options="processList" :disabled="this.itemList.length > 0" name="process" v-custom :settings="{ width: '100%'}"/> 
                                                            </div>
                                                        </div> -->
                                                        <div class="col" v-if="this.form.currency !== this.localCurrency">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'rate'">
                                                                    {{ $t('Rate') }}
                                                                    &nbsp; <small v-if="this.form.offer_date !== null">({{ $t('Rate Date') }}: {{ previewDate(this.form.offer_date) }})</small>
                                                                </label>
                                                                <CurrencyInput type="text" step="any" class="form-control text-end" name="rate" v-custom v-model="this.form.rate" :precision="5" @focus="$event.target.select()" :disabled="this.itemList.length > 0"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                        <div class="d-flex flex-column flex-row-auto col-lg-3">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                                <div class="card-header rounded-2 rounded-bottom-0 bg-gray-gradient min-h-40px">
                                                    <div class="card-title">
                                                        <h3>{{ $t('Options') }}</h3>
                                                    </div>
                                                </div>
                                                <div class="card-body pt-2">
                                                        <div class="fv-row mb-7 row">
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_type'">
                                                                    <span>{{ $t('Payment Method') }}</span>
                                                                </label>
                                                                <Select2 v-model="form.payment_id" :options="paymentList" name="payment_type" v-custom :settings="{ width: '100%'}"/> 
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'shipping_type'" >
                                                                    <span>{{ $t('Shipping Type') }}</span>
                                                                </label>
                                                                <Select2 v-model="form.shipping_id" :options="shippingList" name="shipping_type" v-custom :settings="{ width: '100%'}"/> 
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-if="form.activeView == 2">
                                        <ul class="nav nav-tabs flex-nowrap text-nowrap float-end px-3 border-0" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                                    :class="{'active border border-bottom-0 border-gray-300': this.activeSubTab == 'OfferItems'}" @click="this.activeSubTab = 'OfferItems'"
                                                    data-bs-toggle="tab" href="#kt_invoice_sub_tab_1" aria-selected="true" role="tab">
                                                    {{ $t('Offer Items') }}
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                                    :class="{'active border border-bottom-0 border-gray-300': this.activeSubTab == 'DefaultVendors'}" @click="this.activeSubTab = 'DefaultVendors'"
                                                    data-bs-toggle="tab" href="#kt_invoice_sub_tab_2" aria-selected="true" role="tab">{{ $t('Sales Vendors') }}</a>
                                            </li>
                                            <li class="nav-item" role="presentation" v-if="form.position !== 1 && form.position !== 4">
                                                <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                                    :class="{'active border border-bottom-0 border-gray-300': this.activeSubTab == 'RepeatedInvoice'}" @click="this.activeSubTab = 'RepeatedInvoice', getScheduledTransactions()"
                                                    data-bs-toggle="tab" href="#kt_invoice_sub_tab_2" aria-selected="true" role="tab">{{ $t('Repeated Invoice') }}</a>
                                            </li>
                                        </ul>

                                        <!-- Offer Items -->
                                        <div id="kt_invoice_sub_tab_1" class="tab-pane fade show active" v-if="this.activeSubTab == 'OfferItems'" role="tabpanel">
                                            <div class="col-lg-12">
                                                <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                                    <div class="p-3">
                                                        <div class="d-flex flex-stack flex-wrap mb-2 ">
                                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                <button ref="openStockItemModal" type="button" class="btn btn-sm btn-success fw-bold required me-3" data-bs-toggle="modal"
                                                                    data-bs-target="#kt_modal_add_item" @click="openStockItemModal('addItem')"
                                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                            viewBox="0 0 24 24" fill="none">
                                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                                fill="currentColor">
                                                                            </rect>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Add Item') }}
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-original-title="Coming Soon" data-bs-toggle="modal" ref="btnEditItem"
                                                                    data-bs-target="#kt_modal_add_item" @click="openStockItemModal('editItem')" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Edit') }}
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" data-bs-toggle="tooltip" title="" @click="deleteItem()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Remove') }}
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn btn-light btn-active-danger" data-bs-toggle="tooltip" title="" @click="deleteAllItems()">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Remove All (!)') }}
                                                                </button>
                                                            </div> 
                                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                <div class="row mb-2">
                                                                    <div class="input-group mb-5">
                                                                        <input type="text" class="form-control form-control-sm" name="stock_account_code" v-model="stock_qr_code" @keyup.enter="addStockItemByStockCode()"
                                                                            placeholder="Product or stock code">
                                                                        <button class="btn btn-secondary btn-sm" type="button" @click="addStockItemByStockCode()">
                                                                        <i class="bi bi-download fs-3 text-gray-900"></i> 
                                                                        </button> 
                                                                        <span class="h-20px border-gray-400 border-start mx-3 mt-3"></span>
                                                                        <b class="ms-5 mt-3 me-4"> {{this.form.currency}} </b>
                                                                        <span class="ms-5 mt-3 me-4" v-if="this.form.currency !== this.localCurrency"> ({{ $t('Rate') }}: {{this.form.rate}}) </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="dataTables_wrapper dt-bootstrap4 no-footer"> 
                                                            <div class="table-responsive-lg">
                                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                        key-expr="id" 
                                                                        :data-source="itemList" 
                                                                        :allow-column-reordering="true"  
                                                                        :allow-column-resizing="true" 
                                                                        :column-auto-width="true" 
                                                                        :row-alternatin-enabled="true"
                                                                        :hover-state-enabled="true" 
                                                                        :show-row-lines="true"
                                                                        @exporting="this.$onExporting($event , 'Invoice-items')"    
                                                                        :scrolling="{ mode: 'virtual' }"                                                                     
                                                                        @option-changed="handlePropertyChange"> 

                                                                    <DxFilterRow :visible="true"/>
                                                                    <DxHeaderFilter :visible="true"/>
                                                                    <DxGroupPanel :visible="true"/>      
                                                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                                    <DxPaging :page-size="pageSize"/>
                                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes(itemList)" :show-page-size-selector="showPageSizeSelector(itemList)" :show-info="true" :show-navigation-buttons="true"/> 
                                                                    <DxSearchPanel :visible="true"/>

                                                                    <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                                    <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/>                                                                 
                                                                    <DxColumn data-field="stock_account_code" :caption="$t('Code')" cell-template="stock_account_code-cell-template"/> 
                                                                    <DxColumn data-field="stock_account_name" :caption="$t('Item')" cell-template="stock_account_name-cell-template"/> 
                                                                    <DxColumn data-field="quantity1" :caption="$t('Quantity')" alignment="right" cell-template="quantity1-cell-template"/> 
                                                                    <DxColumn data-field="unit_name" :caption="$t('Unit')" cell-template="unit_name-cell-template"/> 
                                                                    <DxColumn data-field="stock_currency" :caption="$t('Currency')" alignment="left" cell-template="Currency-cell-template"/> 
                                                                    <DxColumn data-field="price" :sorting-method="this.$numericSort" :caption="$t('Price')" alignment="right" cell-template="price-cell-template"/> 
                                                                    <DxColumn data-field="total_price" :sorting-method="this.$numericSort" :caption="$t('Total')" alignment="right" cell-template="total_price-cell-template"/> 
                                                                    <DxColumn data-field="discount_amount" :sorting-method="this.$numericSort" :caption="$t('Discount')" alignment="right" cell-template="discount_amount-cell-template"/> 
                                                                    <DxColumn data-field="net_price" :sorting-method="this.$numericSort" :caption="$t('Net Total')" alignment="right" header-cell-template="net-total-header" cell-template="net_price-cell-template"/> 
                                                                    <DxColumn data-field="tax_rate" :caption="$t('VAT Rate')" alignment="right" header-cell-template="vat-rate-header" cell-template="tax_rate-cell-template"/> 
                                                                    <DxColumn data-field="tax_amount" :sorting-method="this.$numericSort" :caption="$t('VAT Amount')" alignment="right" header-cell-template="vat-amount-header" cell-template="tax_amount-cell-template"/> 
                                                                    <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Grand Total')" alignment="right" header-cell-template="grand-total-header" cell-template="gross_amount-cell-template"/> 
                                                                    <DxColumn data-field="isPopover" :caption="$t('Details')" :allow-filtering="false" :allow-exporting="false" cell-template="isPopover-cell-template"/> 


                                                                        <template #net-total-header>
                                                                            <div>{{ $t('Net') }}</div><div>{{ $t('Total') }}</div>
                                                                        </template> 
                                                                        <template #grand-total-header>
                                                                            <div>{{ $t('Grand') }}</div><div>{{ $t('Total') }}</div>
                                                                        </template>  
                                                                        <template #vat-rate-header>
                                                                            <div>{{ $t('VAT') }}</div><div>{{ $t('Rate') }}</div>
                                                                        </template> 
                                                                        <template #vat-amount-header>
                                                                            <div>{{ $t('VAT') }}</div><div>{{ $t('Amount') }}</div>
                                                                        </template> 

                                                                        <template #is-selected-cell-template="{ data }">
                                                                            <span>
                                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                                </div>
                                                                            </span>
                                                                        </template>
                                                                        <template #id-cell-template="{ data }">
                                                                            <a href="#" class="text-gray-800 text-hover-primary">
                                                                                {{ getRowNumber(data) }}
                                                                            </a>
                                                                        </template>                                                                
                                                                        <template #stock_account_code-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-900 text-start">{{data.data.stock_account_code}}</span>
                                                                        </template>
                                                                        <template #stock_account_name-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-900 text-start">{{data.data.stock_account_name}}</span>   
                                                                        </template>
                                                                        <template #quantity1-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-700 text-end">{{data.data.quantity1}}</span>   
                                                                        </template>
                                                                        <template #unit_name-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-600 text-start">{{data.data.unit_name}}</span>   
                                                                        </template>
                                                                        <template #Currency-cell-template>
                                                                            <span class="p-3 align-middle text-gray-700 text-start">{{this.form.currency}}</span>   
                                                                        </template>
                                                                        <template #price-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-700 text-end">{{data.data.price}}</span>   
                                                                        </template>
                                                                        <template #total_price-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.total_price }}</span>   
                                                                        </template>
                                                                        <template #discount_amount-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.discount_amount}}</span>   
                                                                        </template>
                                                                        <template #net_price-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.net_price}}</span>   
                                                                        </template>
                                                                        <template #tax_rate-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-600 text-end" v-if="data.data.vat_status_id == 1">None</span>
                                                                            <span class="p-3 align-middle text-gray-600 text-end" v-else>{{data.data.tax_rate }}</span>
                                                                        </template>
                                                                        <template #tax_amount-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.tax_amount }}</span>   
                                                                        </template>
                                                                        <template #gross_amount-cell-template="{ data }">
                                                                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.gross_amount}}</span>   
                                                                        </template>
                                                                        <template #isPopover-cell-template="{ data }">
                                                                            <span>
                                                                                <span v-if="data.data.id !== null && data.data.id !== ''">
                                                                                        <span class="svg-icon svg-icon-gray-600 svg-icon-2hx cursor-pointer" 
                                                                                                :id="'popover_'+ data.rowIndex" 
                                                                                                @mouseenter="this.onEnterPopover(data.data)" @mouseleave="this.onLeavePopover(data.data)">
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                                                                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/>
                                                                                                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                                                                            </svg>
                                                                                        </span>
                                                                                    <DxPopover
                                                                                        :width="300"
                                                                                        :show-title="true"
                                                                                        :visible="data.data.isPopover"
                                                                                        :target="'#popover_'+ data.rowIndex"
                                                                                        position="left"
                                                                                        title="Details">
                                                                                        <table class="table table-row-dashed mb-0">
                                                                                            <tbody>
                                                                                                <tr class="text-start">
                                                                                                    <td class="pt-2 pb-2 fw-bolder text-gray-800">{{ $t('Sales Channel') }}</td>
                                                                                                    <td class="pt-2 pb-2 text-gray-600" v-if="data.data.sales_channel_name">{{data.data.sales_channel_name}}</td>
                                                                                                    <td class="pt-2 pb-2 text-gray-600" v-if="!data.data.sales_channel_name"> <em> {{ $t('Not specified') }} </em> </td>
                                                                                                </tr>
                                                                                                <tr class="text-start">
                                                                                                    <td class="pt-2 pb-2 fw-bolder text-gray-800">{{ $t('Sales Role') }} / {{ $t('Campaign') }}</td>
                                                                                                    <td class="pt-2 pb-2 text-gray-600" v-if="data.data.campaign_name">{{data.data.campaign_name}}</td>
                                                                                                    <td class="pt-2 pb-2 text-gray-600" v-if="!data.data.campaign_name"> <em> {{ $t('Not specified') }} </em> </td>
                                                                                                </tr>
                                                                                                <tr class="text-start">
                                                                                                    <td class="pt-2 pb-2 fw-bolder text-gray-800" v-if="data.data.description">{{ $t('Description') }}</td>
                                                                                                    <td class="pt-2 pb-2 text-gray-600" v-if="data.data.description">{{data.data.description}}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>    
                                                                                    </DxPopover>
                                                                                </span>
                                                                            </span>   
                                                                        </template>
                                                                        <DxSummary>
                                                                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                                        </DxSummary>
                                                                </DxDataGrid>  
                                                            </div>
                                                            <!-- <div class="row" v-if="itemList && itemList.length > 0">
                                                                <div
                                                                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                                                    <div class="dataTables_info" role="status" aria-live="polite">
                                                                        {{ itemList.length }} {{$t('Records')}}
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 mt-0"> 
                                                <div class="d-flex flex-row row" v-if="form.activeView == 2">
                                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <h3>{{ $t('Description') }}</h3>
                                                                </div>
                                                            </div> 
                                                            <div class="card-body pb-0">
                                                                <div class="row">
                                                                    <div v-html="this.offerDescription"></div>
                                                                </div> 
                                                            </div>
                                                            <div class="card-footer" v-if="isShowDueDate || isFreeOfChargeItemsExist">
                                                                <span v-if="isShowDueDate"> {{ $t('The due date of this invoice is') }} <b> {{previewDate(form.option_date)}} </b> </span>
                                                                
                                                                <div class="float-end text-danger" v-if="isFreeOfChargeItemsExist">
                                                                    <i class="bi bi-info-circle text-danger"></i> {{ $t('Some items on the invoice are free of charge.') }}
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <div class="d-flex flex-column flex-row-auto col-lg-6" v-if="this.grandTotalsCardView == 'processTotal'">
                                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <h3>{{ $t('Totals') }} - 
                                                                        <span v-if="this.form.currency !== null && this.form.currency !== '' && this.form.currency !== '-1' && this.form.currency !== undefined">
                                                                            {{this.form.currency}}
                                                                        </span>                                                                    
                                                                    </h3>
                                                                </div>
                                                                <div class="card-toolbar" v-if="this.form.currency !== this.localCurrency">
                                                                    <button class="btn btn-light-primary btn-circle btn-icon btn-sm" @click="onChangeTotalsCurrency('grandTotal', 'localTotal')">
                                                                        {{this.localCurrency}}
                                                                    </button>
                                                                </div>
                                                            </div> 
                                                            <div class="card-body pb-0">
                                                                <div class="table-responsive border-bottom mb-9">
                                                                    <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                                        <tbody class="fw-bold text-gray-600">
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('Total') }}</td>
                                                                                <td class="text-end">{{this.totals.totalAmount}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('Discount') }}</td>
                                                                                <td class="text-end">{{this.totals.discountAmount}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('Net Total') }}</td>
                                                                                <td class="text-end">{{this.totals.netAmount}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('VAT') }}</td>
                                                                                <td class="text-end">{{this.totals.taxAmount}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="fs-3 text-gray-800 fw-bold text-start text-gray-800">{{ $t('Grand Total') }}</td>
                                                                                <td class="text-gray-800 fs-3 fw-bold text-end text-gray-800">{{this.totals.grossAmount}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex flex-column flex-row-auto col-lg-6" v-if="this.grandTotalsCardView == 'localTotal' && this.form.currency !== this.localCurrency">
                                                        <div class="d-flex flex-column-auto card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <h3>{{ $t('Totals') }} - {{this.localCurrency}} </h3>
                                                                </div>
                                                                <div class="card-toolbar">
                                                                    <button class="btn btn-light-info btn-circle btn-icon btn-sm" @click="onChangeTotalsCurrency('grandTotal', 'processTotal')">
                                                                        {{this.form.currency}}
                                                                    </button>
                                                                </div>
                                                            </div> 
                                                            <div class="card-body pb-0">
                                                                <div class="table-responsive border-bottom mb-9">
                                                                    <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                                        <tbody class="fw-bold text-gray-600">
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('Total') }}</td>
                                                                                <td class="text-end">{{this.totals.totalAmountLocal}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('Discount') }}</td>
                                                                                <td class="text-end">{{this.totals.discountAmountLocal}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('Net Total') }}</td>
                                                                                <td class="text-end">{{this.totals.netAmountLocal}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="text-start">{{ $t('VAT') }}</td>
                                                                                <td class="text-end">{{this.totals.taxAmountLocal}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="3" class="fs-3 text-gray-800 fw-bolder text-start">{{ $t('Grand Total') }}</td>
                                                                                <td class="text-gray-800 fs-3 fw-boldest text-end">{{this.totals.grossAmountLocal}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>

                                        <!-- Default Vendors -->
                                        <div id="kt_invoice_sub_tab_2" class="tab-pane fade show active" v-if="this.activeSubTab == 'DefaultVendors'" role="tabpanel">
                                            <div class="col-lg-12">
                                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                                    <div class="card-body"> 
                                                        <div class="card card-xxl-stretch card-bordered">
                                                            <div class="p-5">
                                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                                            data-bs-target="#kt_modal_add_vendor" @click="this.openAddVendorModal()"
                                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                            <span class="svg-icon svg-icon-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                                    viewBox="0 0 24 24" fill="none">
                                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                                        fill="currentColor">
                                                                                    </rect>
                                                                                </svg>
                                                                            </span>
                                                                            {{ $t('Add') }}
                                                                        </button>
                                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()" 
                                                                            :disabled="isVendorButtonDisabled == null || isVendorButtonDisabled == undefined">
                                                                            <span class="svg-icon svg-icon-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                                </svg>
                                                                            </span>
                                                                            {{ $t('Remove') }}
                                                                        </button>
                                                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllVendors()" 
                                                                            :disabled="vendorDefaultList.length == 0">
                                                                            <span class="svg-icon svg-icon-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                                </svg>
                                                                            </span>
                                                                            {{ $t('Remove All (!)') }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                                    <div class="table-responsive">
                                                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                                    key-expr="id" 
                                                                                    :data-source="vendorDefaultList" 
                                                                                    :allow-column-reordering="true"  
                                                                                    :allow-column-resizing="true" 
                                                                                    :column-auto-width="true" 
                                                                                    :row-alternatin-enabled="true"
                                                                                    :hover-state-enabled="true" 
                                                                                    :show-row-lines="true" > 

                                                                                <DxFilterRow :visible="true"/>
                                                                                <DxHeaderFilter :visible="true"/>
                                                                                <DxGroupPanel :visible="false"/>
                                                                                <DxPaging :page-size="1000"/>
                                                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                                <DxScrolling
                                                                                    mode="virtual"
                                                                                    row-rendering-mode="virtual"
                                                                                />
                                                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                                                <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template"/>
                                                                                <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template"/> 
                                                                                
                                                                                <template #is-selected-cell-template="{ data }"> 
                                                                                    <span>
                                                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(vendorDefaultList, data.data)" />
                                                                                        </div>
                                                                                    </span>
                                                                                </template>
                                                                                <template #account_id-cell-template="{ data }"> 
                                                                                    <span>
                                                                                        {{ data.data.account_id }}
                                                                                    </span>
                                                                                </template>
                                                                                <template #vendor_name-cell-template="{ data }"> 
                                                                                    <span>
                                                                                        {{ data.data.vendor_name }}
                                                                                    </span>
                                                                                </template>
                                                                        </DxDataGrid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>

                                        <!-- Repeated Invoice -->
                                        <div id="kt_invoice_sub_tab_2" class="tab-pane fade show active" v-if="this.activeSubTab == 'RepeatedInvoice'" role="tabpanel">
                                            <div class="col-lg-12">
                                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                                    <div class="card-body"> 
                                                        <div class="card card-xxl-stretch card-bordered">
                                                            <div class="p-5">
                                                                <div class="row d-flex flex-stack flex-wrap mb-5">
                                                                    <div class="d-flex justify-content-start" data-kt-docs-table-toolbar="base" v-if="scheduledTransactions.length == 0">
                                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                                            data-bs-target="#kt_modal_create_scheduled_operation" @click="onOpenScheduleModal()"
                                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                            <span class="svg-icon svg-icon-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                                    viewBox="0 0 24 24" fill="none">
                                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                                        fill="currentColor">
                                                                                    </rect>
                                                                                </svg>
                                                                            </span>
                                                                            {{ $t('Create Scheduled Transaction') }}
                                                                        </button>
                                                                    </div>
                                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base" v-if="scheduledTransactions.length > 0 && isPendingTrnExist">
                                                                        <button type="button" class="btn btn-sm btn-light-danger btn-active-success me-3" @click="updateScheduledTransaction(null, true)">
                                                                            {{ $t('Cancel Pending Transactions') }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                                    <div class="table-responsive">
                                                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                            key-expr="id" 
                                                                            :data-source="scheduledTransactions" 
                                                                            :allow-column-reordering="true"  
                                                                            :allow-column-resizing="true" 
                                                                            :column-auto-width="true" 
                                                                            :row-alternatin-enabled="true"
                                                                            :hover-state-enabled="true" 
                                                                            :show-row-lines="true" 
                                                                            @row-updating="updateScheduledTransaction"
                                                                            @editing-start="onEditingStart"> 

                                                                            <DxEditing :allow-updating="true" mode="row"/>
                                                                            <DxFilterRow :visible="true"/>
                                                                            <DxHeaderFilter :visible="true"/>
                                                                            <DxGroupPanel :visible="false"/>
                                                                            <DxPaging :page-size="1000"/>
                                                                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                            <DxScrolling
                                                                                mode="virtual"
                                                                                row-rendering-mode="virtual"
                                                                            />
                                                                            <DxColumn data-field="period_type_name" :caption="$t('Period Type')" :allow-editing="false" /> 
                                                                            <DxColumn data-field="transaction_date" :caption="$t('Transaction Date')"  data-type="datetime"  alignment="right" format="dd.MM.yyyy HH:mm" />
                                                                            <DxColumn data-field="status_name" :caption="$t('Status')" :set-cell-value="setScheduleStatus"  cell-template="status_name-cell-template">                                                                                
                                                                                <DxLookup :data-source="scheduleStatusList" value-expr="id" display-expr="text" v-model="scheduleStatus" />
                                                                            </DxColumn> 
                                                                            <DxColumn data-field="remain_day" :caption="$t('Remain Day')" :allow-editing="false" /> 
                                                                            <DxColumn data-field="invoice_date" :caption="$t('Invoice Date')"  data-type="date" alignment="right" format="dd.MM.yyyy" :allow-editing="false" />
                                                                            <DxColumn data-field="invoice_no" :caption="$t('Invoice No')" :allow-editing="false"/> 
                                                                            <DxColumn data-field="invoice_total" :caption="$t('Invoice Total')" :allow-editing="false"/>
                                                                            <DxColumn data-field="description" :caption="$t('Description')" :allow-editing="true" cell-template="description-cell-template"/>

                                                                            
                                                                            <template #status_name-cell-template="{ data }">
                                                                                <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                                                    {{ data.data.status_name }} 
                                                                                </a>
                                                                            </template>
                                                                            <template #description-cell-template="{ data }">
                                                                                <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                                                    {{ data.data.description }}
                                                                                </a>
                                                                            </template>
                                                                        </DxDataGrid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mt-0" v-if="getSubCredentials('U')">
                                            <div class="d-grid gap-2">
                                                <button class="btn btn-primary btn-lg mb-4" type="submit" @click="updateOffer();"  data-kt-indicator="on" :disabled="itemList.length == 0"> 
                                                    <span class="svg-icon svg-icon-2qx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"/>
                                                    <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"/>
                                                    <path d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z" fill="currentColor"/>
                                                    </svg>
                                                    </span>  
                                                    <strong v-if="!this.isOfferProgressing"> 
                                                        {{ $t('Update') }}
                                                        <span v-if="this.form.transaction == 1 && this.form.offer_type == 1"> {{ $t('Sales Offer') }} </span>
                                                        <span v-if="this.form.transaction == 1 && this.form.offer_type == 2"> {{ $t('Purchase Offer') }} </span>
                                                        <span v-if="this.form.transaction == 2 && this.form.offer_type == 1"> {{ $t('Sales Order') }} </span>
                                                        <span v-if="this.form.transaction == 2 && this.form.offer_type == 2"> {{ $t('Purchase Order') }} </span>
                                                    </strong> 
                                                    <span class="indicator-progress" v-if="this.isOfferProgressing">
                                                        {{ $t('Updating Invoice. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div id="kt_accounting_4" class="row p-10 tab-pane fade show" v-if="this.activeTab == 'Documents'" role="tabpanel">
                        <FileManager :location="2" :locationtype="1" :upload_source="1" ref="FileManagerComponent" :account_id="this.form.account_id" :account_type="this.selectedAccount.account_type"></FileManager>
                    </div>
                </div>
            </div>
        </div>

        <div ref="stockItemModal" class="modal fade" :class="{'show': isViewStockItemModal, 'd-none': isViewSearchStockAccountModal}" id="kt_modal_add_item" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
            <form id="offerItemForm" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                <div class="modal-dialog modal-xxl">
                    <div class="modal-content"  >
                        <div class="modal-header bg-primary">
                            <h2 class="modal-title fw-bolder col-md-11 text-white">
                                <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                                <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span>
                                {{ $t('Item') }}  &nbsp; ({{ $t('Currency') }} - {{this.form.currency}})                     
                            </h2>
                            <div class="btn btn-icon btn-sm btn-active-icon-primary"
                                data-bs-dismiss="modal" aria-label="Close" ref="closeModal" @click="isViewStockItemModal = false">
                                <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                            transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div class="modal-body scroll-y pt-1" :class="{'overlay overlay-block': this.isStockItemSaving == true}">
                            <RequiredFieldOnModal :routeName="this.modalType == 'addItem' ? 'InvoiceItemCreate' : 'InvoiceItemUpdate'" class="mt-5"></RequiredFieldOnModal> 
                            <div class="row">
                                <div class="col-lg-3" v-if="this.form.process_type == 1" :class="{'col-lg-5': stockTrackingListItems.length > 0}">
                                    <div class="card card-xxl-stretch mb-5 mb-xxl-10" style="height: 100%">
                                        <div class="card-header min-h-50px">
                                            <div class="card-title">
                                                <h3 class="text-gray-800 me-5">{{ $t('Sources') }}
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="card-body py-1">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <label class="fw-bold form-label mt-3" v-tag="'sales_channel'" >
                                                        <span> {{ $t('Sales Channel') }}</span>
                                                    </label>
                                                    <Select2 v-model="stockForm.sales_channel_id" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content', theme: ' select2-container--default select2-selection--sm' }" name="sales_channel" v-custom />
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fw-bold form-label mt-3" v-tag="'sales_role_campaign'" >
                                                        <span>{{ $t('Sales Role/Campaign') }}</span>
                                                    </label>
                                                    <Select2 v-model="stockForm.campaign_id" :options="campaignList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content', theme: ' select2-container--default select2-selection--sm' }" name="sales_role_campaign" v-custom />
                                                </div>
                                                <div class="col-lg-12 pt-3">
                                                    <div class="card card-xxl-stretch" v-if="stockTrackingListItems.length > 0">
                                                        <div class="card-body p-0 pt-5">
                                                            <div class="scroll-y me-n5 pe-5 h-300px h-xl-auto mh-300px">                                                            
                                                                <div class="table-responsive">
                                                                    <table class="table table-rounded table-striped border gy-2 gs-2">
                                                                        <thead>
                                                                            <tr class="fw-bold fs-7 text-gray-800 border-bottom-2 border-gray-200">
                                                                                <th>{{ $t('Group') }}</th>
                                                                                <th>{{ $t('Value') }}</th>
                                                                                <th>{{ $t('Description') }}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody v-if="stockTrackingListItems">
                                                                            <tr class="fs-8" v-for="data in stockTrackingListItems" v-bind:key="data">
                                                                                <td>{{ data.tracking_group_name }}</td>
                                                                                <td>{{ data.item_value }}</td>
                                                                                <td>{{ data.description }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7" :class="{'col-lg-9': stockTrackingListItems.length == 0, 'col-lg-12': this.form.process_type == 2}">
                                    <div class="card">
                                        <div class="card-header min-h-50px">
                                            <div class="card-title">
                                                <h3 v-if="this.form.process_type == 1" class="text-gray-800 me-5">{{ $t('Stock & Service') }}</h3>
                                                <h3 v-if="this.form.process_type == 2" class="text-gray-800 me-5">{{ $t('Fixed Assets') }}</h3>
                                            </div>
                                            <div class="card-toolbar m-0 p-0">
                                                <button ref="AddNewStockButton" type="button" class="d-none btn btn-light-success btn-xs justify-content-end me-2" data-bs-toggle="modal" 
                                                    @click="openCreateStockModal()" data-bs-target="#kt_modal_add_stock" v-if="this.form.process_type == 1">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                fill="currentColor">
                                                            </rect>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Create New') }}
                                                </button>
                                                <button ref="AddNewAssetButton" type="button" class="d-none btn btn-light-success btn-xs border border-success justify-content-end me-2" data-bs-toggle="modal" 
                                                    @click="openCreateAssetModal()" data-bs-target="#kt_modal_add_asset">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                fill="currentColor">
                                                            </rect>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Create New') }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body pt-0 pb-0">
                                           
                                                <div class="card-body p-1 pt-2">
                                                    <div class="row mb-2">
                                                        <label class="col-lg-3 col-form-label form-label fw-bold">
                                                            <span v-if="this.form.process_type == 1">{{ $t('Search Stock & Service') }} </span>
                                                            <span v-if="this.form.process_type == 2">{{ $t('Search Fixed Asset') }} </span>
                                                        </label>
                                                        <div class="col-lg-9 fv-row fv-plugins-icon-container" :class="{'col-lg-7': this.stockForm.account_type !== 7}">
                                                            <div class="input-group">
                                                                <input type="search" class="form-control form-control-lg" v-model="stockForm.stock_account_name" @input="onResetStockInfo()" :placeholder="$t('Please type a name and click on search button')" @keyup.enter="onClickSearchButton()" name="stock_service_fixed_asset" v-custom >
                                                                <button ref="searchStockButton" type="button" class="btn btn-sm btn-light-primary border border-gray-300" @click="openSearchModal(stockForm.stock_account_name)" data-kt-indicator="on">
                                                                    <i class="fa fa-search"></i>
                                                                    <span v-if="this.form.process_type == 1">{{ $t('Search Stock & Service') }}</span>
                                                                    <span v-if="this.form.process_type == 2">{{ $t('Search Fixed Asset') }}</span>
                                                                </button>                                                            
                                                                <span class="input-group-text border ms-0 rounded-end border border-start-0 border-gray-300 fw-boldest " v-if="this.stockForm.account_type == 7 && this.stockForm.item_remain != null"
                                                                    :class="{ 'text-success': parseFloat(this.stockForm.item_remain) > 0, 'text-danger': parseFloat(this.stockForm.item_remain) == 0 || parseFloat(this.stockForm.item_remain) < 0}"> 
                                                                    {{ this.stockForm.item_remain }}
                                                                </span>
                                                                <!-- <button type="button" class="btn rounded-start-0 btn-sm btn-secondary ms-1" v-if="stockForm.stock_account_name !== null && stockForm.stock_account_name !== ''">
                                                                    <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                                </button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <label class="col-lg-3 col-form-label form-label fw-bold p-2" >
                                                            <span  v-tag="'price_list'" >{{ $t('Price List') }}</span>
                                                            <span v-tag="'vat_status'"> / {{ $t('VAT Status') }}</span>
                                                        </label>
                                                        <div class="col-lg-5 fv-row fv-plugins-icon-container pe-1">
                                                            <Select2 v-model="stockForm.stock_currency_id" :options="stockPricesList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content', theme: ' select2-container--default select2-selection--sm' }" @select="onChangeStockCurrency()" name="price_list" v-custom />
                                                        </div>
                                                        <div class="col-lg-4 fv-row fv-plugins-icon-container ps-0">
                                                            <Select2 v-if="form.isVatActive || form.offer_type == 1" v-model="stockForm.vat_status_id" :options="vatStatusList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content', theme: ' select2-container--default select2-selection--sm' }" @select="onChangeVatStatus()" name="vat_status" v-custom />

                                                            <Popper hover placement="bottom" v-if="!form.isVatActive && form.offer_type == 2">
                                                                <Select2 v-model="stockForm.vat_status_id" :options="vatStatusList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content', theme: ' select2-container--default select2-selection--sm' }" disabled />
                                                                <template #content>
                                                                    <span class="text-danger fw-bold"> <i class="bi bi-info-circle text-danger"> </i> {{ $t('VAT Account Passive') }} </span>
                                                                </template>
                                                            </Popper>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <label class="col-lg-3 col-form-label form-label fw-bold p-2" v-tag="'quantity'" >{{ $t('Quantity2') }} 
                                                            <span v-if="this.stockForm.stock_unit_2_name !== '' && this.stockForm.stock_unit_2_name !== null && this.stockForm.stock_unit_2_name !== undefined" v-tag="'quantity2'" > /{{ $t('Qty 2') }}  </span>                                                        
                                                        </label>
                                                        <div class="col-lg-9">
                                                            <div class="row">
                                                                <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{'col-lg-12': this.stockForm.stock_unit_2_name == '' || this.stockForm.stock_unit_2_name == null || this.stockForm.stock_unit_2_name == undefined}">
                                                                    <div class="input-group">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.quantity1" v-on:blur="calculateTotals()" @focus="$event.target.select()" name="quantity" v-custom />
                                                                        <input v-if="stockForm.stock_unit_name" class="input-group-text mw-50 cursor-pointer p-2" id="basic-addon2"
                                                                            data-bs-toggle="tooltip" data-bs-trigger="hover" :title="this.stockForm.stock_unit_name" :value="stockForm.stock_unit_name" disabled />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.stockForm.stock_unit_2_name !== '' && this.stockForm.stock_unit_2_name !== null && this.stockForm.stock_unit_2_name !== undefined">
                                                                    <div class="input-group">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.quantity2" v-on:blur="calculateTotals()" @focus="$event.target.select()" name="quantity2" v-custom />
                                                                        <input v-if="stockForm.stock_unit_2_name" class="input-group-text mw-50 cursor-pointer p-2" id="basic-addon2"
                                                                            data-bs-toggle="tooltip" data-bs-trigger="hover" :title="this.stockForm.stock_unit_2_name" :value="stockForm.stock_unit_2_name" disabled />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label class="col-lg-3 col-form-label form-label fw-bold p-2"> 
                                                            <b v-if="this.stockForm.stock_currency_id !== '-1'" class="fw-boldest" v-tag="'stock_price'" > {{this.stockForm.stock_currency}} </b> 
                                                            {{ $t('Price') }}
                                                            <span v-if="this.form.currency !== this.stockForm.stock_currency && this.stockForm.stock_currency_id !== '-1'" v-tag="'stock_rate'" >/<b>{{this.form.currency}} </b> {{ $t('Rate') }} </span> 
                                                        </label>
                                                        <div class="col-lg-9">
                                                            <div class="row">
                                                                <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.stock_price" v-on:blur="calculateTotals()" @focus="$event.target.select()" name="stock_price" v-custom />
                                                                    <div class="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.form.currency !== this.stockForm.stock_currency && this.stockForm.stock_currency_id !== '-1'">
                                                                    <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.stock_rate" :precision="5" v-on:blur="calculateTotals()" @focus="$event.target.select()" name="stock_rate" v-custom  />
                                                                    <small v-if="this.stockForm.rate_date !== null"> {{ $t('Rate Date') }}: {{ previewDate(this.stockForm.rate_date) }}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        </div>
                                    </div>

                                    <div class="card mt-3" v-if="this.totalsCardView == 'processTotal'">
                                        <div class="card-header min-h-50px">
                                            <div class="card-title">
                                                <h3 class="text-gray-800 me-5"> <b class="fw-boldest"> {{this.form.currency}} </b> {{ $t('Totals') }}
                                                    <!-- <small> İşlem Para Birimi</small> -->
                                                </h3>
                                            </div>
                                            <div class="card-toolbar" v-if="this.form.currency !== this.localCurrency">
                                                <span class="me-6"> {{ $t('Rate') }}: <b> {{this.form.rate}} </b> </span>
                                                <button class="btn btn-light-primary btn-circle btn-icon btn-sm" @click="onChangeTotalsCurrency('stockModal', 'localTotal')">
                                                    {{this.localCurrency}}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body pt-0 pb-0">
                                            <div class="card-body p-1 pt-2">
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2">
                                                        <span >{{ $t('Price') }}</span>
                                                        <span > / {{ $t('Total') }}</span>    
                                                    </label>
                                                    <div class="col-lg-9">
                                                        <div class="row">
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.price" v-on:blur="calculateTotals()" @focus="$event.target.select()" :disabled="true" />
                                                            </div>
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.total_price" :disabled="true" @focus="$event.target.select()" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2">
                                                        <span v-tag="'discount'" >{{ $t('Discount') }}</span>
                                                        <span v-tag="'net_total'" > / {{ $t('Net Total') }}</span>
                                                    </label>
                                                    <div class="col-lg-9">
                                                        <div class="row">
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <div class="input-group input-group-sm">
                                                                    <span class="input-group-text">%</span>
                                                                    <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.discount" v-on:blur="calculateTotals()" @focus="$event.target.select()" name="discount" v-custom :min="0" :max="100" />
                                                                    <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.discount_amount" v-on:blur="calculateDiscountTotals()" @focus="$event.target.select()" name="discount_amount" v-custom />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.net_price" :disabled="true" @focus="$event.target.select()" name="net_total" v-custom />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2">
                                                        <span >{{ $t('VAT%') }}</span>
                                                        <span v-tag="'vat_amount'" > / {{ $t('Amount') }}</span>
                                                    </label>
                                                    <div class="col-lg-9">
                                                        <div class="row">
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container text-end">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.tax_rate"
                                                                v-if="stockForm.vat_status_id == 1" v-on:blur="calculateTotals()" @focus="$event.target.select()" />
                                                                <Select2 v-model="stockForm.tax_rate" :options="vatRateList" v-if="stockForm.vat_status_id != 1"
                                                                    @select="calculateTotals()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content', theme: ' select2-container--default select2-selection--sm' }"/>
                                                            </div>
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.tax_amount" :disabled="this.stockForm.account_type != 6 || this.stockForm.vat_status_id == 1" @focus="$event.target.select()" @blur="calculateTotals(null, true)" name="vat_amount" v-custom />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2" >{{ $t('Grand Total') }}</label>
                                                    <div class="col-lg-9 fv-row">
                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-3" v-model="stockForm.gross_amount" :disabled="true" @focus="$event.target.select()" />
                                                    </div>
                                                </div>
                                                <div class="separator my-2"></div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2" v-tag="'vendor_commission'" >
                                                        {{ $t('Vendors Commission') }}
                                                        <span v-if="this.vendorDefaultList.length == 1"> / {{ $t('Amount') }} </span>
                                                    </label>
                                                    <div class="col-lg-9 fv-row">
                                                        <div class="input-group input-group-sm" v-if="this.vendorDefaultList.length == 1">
                                                            <span class="input-group-text">%</span>
                                                            <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.vendor_commission_rate" v-on:blur="onChangeVendorCommission('rate')" @focus="$event.target.select()" name="vendor_commission_rate" v-custom :min="0" :max="100" />
                                                            <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.vendor_commission" v-on:blur="onChangeVendorCommission('amount')" @focus="$event.target.select()" name="vendor_commission" v-custom :min="0" />
                                                        </div>
                                                        <CurrencyInput v-else type="text" step="any" class="form-control text-end p-3" v-model="stockForm.vendor_commission" :disabled="true" @focus="$event.target.select()" name="vendor_commission" v-custom />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card mt-3" v-if="this.totalsCardView == 'localTotal' && this.form.currency !== this.localCurrency">
                                        <div class="card-header min-h-50px">
                                            <div class="card-title">
                                                <h3 class="text-gray-800 me-5"> {{ $t('Local') }} ({{this.localCurrency}}) {{ $t('Totals') }} 
                                                    <!-- <small> Yerel Para Birimi </small> -->
                                                </h3>
                                            </div>
                                            <div class="card-toolbar">
                                                <span class="me-6"> {{ $t('Rate') }}: <b> {{this.form.rate}} </b> </span>
                                                <button class="btn btn-light-info btn-circle btn-icon btn-sm" @click="onChangeTotalsCurrency('stockModal', 'processTotal')">
                                                    {{form.currency}}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body pt-0 pb-0" >
                                            <div class="card-body p-1 pt-2">
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2">
                                                        <span >{{ $t('Price') }}</span>
                                                        <span > / {{ $t('Total') }}</span>
                                                    </label>
                                                    <div class="col-lg-9">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.price_local" v-on:blur="calculateTotals()" :disabled="true" @focus="$event.target.select()" />
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.total_price_local" :disabled="true" @focus="$event.target.select()" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2">
                                                        <span v-tag="'discount'" >{{ $t('Discount') }}</span>
                                                        <span v-tag="'net_total'" > / {{ $t('Net Total') }}</span>
                                                    </label>
                                                    <div class="col-lg-9">
                                                        <div class="row">
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <div class="input-group input-group-sm">
                                                                    <span class="input-group-text">%</span>
                                                                    <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.discount" v-on:blur="calculateTotals()" :disabled="true" @focus="$event.target.select()" />
                                                                    <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.discount_amount_local" :disabled="true" @focus="$event.target.select()" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.net_price_local" :disabled="true" @focus="$event.target.select()"  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2">
                                                        <span >%{{ $t('VAT Rate') }}</span>
                                                        <span > / {{ $t('VAT Amount') }}</span>
                                                    </label>
                                                    <div class="col-lg-9">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.tax_rate" v-on:blur="calculateTotals()" :disabled="true" @focus="$event.target.select()" />
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.tax_amount_local" :disabled="true" @focus="$event.target.select()" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-3 col-form-label form-label fw-bold p-2" >{{ $t('Grand Total') }}</label>
                                                    <div class="col-lg-9 fv-row">
                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="stockForm.gross_amount_local" :disabled="true" @focus="$event.target.select()" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card mt-3">
                                        <div class="card-body pt-0 pb-0">
                                                <div class="card-body p-1 pt-2">
                                                    <div class="row mb-1">
                                                        <label class="col-lg-3 col-form-label form-label fw-bold" v-tag="'description'" >{{ $t('Description') }}</label>
                                                        <div class="col-lg-9 fv-row">                                                        
                                                            <textarea class="form-control" rows="1" v-model="stockForm.description"  name="description" v-custom ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-flush mt-4" id="kt_contacts_list">
                                        <div class="d-grid gap-2"> 
                                            <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" @click="this.saveData()"
                                                :disabled="this.stockForm.stock_account_id == null || this.isModalButtonDisabled">
                                                <span class="indicator-label">{{ $t('Add To List') }}</span> 
                                            </button>
                                            <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'" @click="this.updateData()"
                                                :disabled="this.stockForm.stock_account_id == null || this.isModalButtonDisabled">
                                                <span class="indicator-label">{{ $t('Update') }}</span> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isStockItemSaving == true">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">{{ $t('Loading...') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!-- Create New Account -->
        <div class="modal fade" id="kt_modal_add_stock" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<AddNewStock ref="AddNewStock"/>
		</div> 
        <div class="modal fade" id="kt_modal_print" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<PrintModal ref="PrintModal" />
		</div>  
        <div class="modal fade" id="kt_modal_add_asset" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<AddNewAsset ref="AddNewAsset"/>
		</div>    

        <!-- Search Stock Account Modal -->
        <div v-show="isViewSearchStockAccountModal" class="modal fade" :class="{'show': isViewSearchStockAccountModal}" id="kt_search_stock_account_modal" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
            <SearchAccountWithSelect ref="SearchStockAccountWithSelect"/>
        </div>
        
        <!-- Offer List -->
        <div class="modal fade" id="kt_modal_transaction_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <TransactionListModal ref="TransactionListModal" :transaction-type="12" :account-id="form.account_id" :account-name="form.account_name"/>
        </div>  

        <!-- Statement Modal  -->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="form.account_name" :account-id="form.account_id" :account-type="form.account_type" />
        </div> 
        
        <!-- Add Default Vendor -->
        <div class="modal fade" id="kt_modal_add_vendor" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AddDefaultVendor ref="AddDefaultVendor" :supplier-account-type="3" />
        </div>

              <!-- Update Customer -->
        <div class="modal fade" id="kt_modal_update_account" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<UpdateAccount ref="UpdateAccount"  @refresh="getAccountDetail(form.account_id)" />
		</div>
        
        <!-- Sending document as email -->
        <div class="modal fade" id="kt_modal_send_offer_view"  aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary py-3" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            {{ $t('Send Mail') }}
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeSendMailModal">

                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="mb-2">
                            <div class="fw-semibold text-gray-800 text-center lh-lg fs-3">
                                {{ $t('This offer will be sent to the e-mail address below.') }}
                                <h2 class="fw-bolder"> {{selectedAccount.email}} </h2>
                                {{ $t('Do you confirm?') }}
                            </div>
                            <div class="text-center">
                                <img src="/assets/theme/media/illustrations/unitedpalms-1/16.png" class="mw-100 mh-150px theme-light-show" alt="">
                                <img src="/assets/theme/media/illustrations/unitedpalms-1/16-dark.png" class="mw-100 mh-150px theme-dark-show" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer row m-0 justify-content-between">
                        <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-primary" @click="sendOfferByMail()">
                                {{ $t('SEND') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

        <!-- Operation List -->
        <div class="modal fade" id="kt_modal_operation_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <div class="modal-header bg-primary py-3" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            {{ $t('Operation List') }}
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeOperationListModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework" > 
                            <div class="row"> 
                                <div class="card p-0">  
                                    <div class="card-body px-3">
                                            <div>
                                                <div class="table-responsive min-h-300px">
                                                <DxDataGrid id="gridContainer" 
                                                    :show-borders="true" 
                                                    :data-source="operationList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    @exporting="this.$onExporting($event , 'Offer-operations-list')" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" >

                                                    <DxFilterRow :visible="true"/>
                                                    <DxHeaderFilter :visible="true"/>
                                                    <DxGroupPanel :visible="true"/>      
                                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                    <DxPaging :page-size="20"/>
                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes(operationList)" :show-page-size-selector="showPageSizeSelector(operationList)" :show-info="true" :show-navigation-buttons="true"/>    
                                                    <DxSearchPanel :visible="true"/>
                                                    
                                                    <DxColumn data-field="position_date" :caption="$t('Position Date')" data-type="date" alignment="right" format="dd.MM.yyyy" /> 
                                                    <DxColumn data-field="position_name" :caption="$t('Position Name')"/>
                                                    <DxColumn data-field="document_no" :caption="$t('Document No')"/>
                                                    <DxColumn data-field="invoice_no" :caption="$t('Invoice Issued?')" cell-template="invoice_no-cell-template"/>
                                                    <DxColumn data-field="description" :caption="$t('Description')"/>
                                                    
                                                    <template #invoice_no-cell-template="{ data }">
                                                        <div v-if="data.data.invoice_no !== '' && data.data.invoice_no !== null && data.data.invoice_no !== undefined">
                                                            <i class="bi bi-check-circle text-success"></i> {{ $t('Invoice Issued') }}
                                                        </div>
                                                        <div v-if="data.data.invoice_no !== '' && data.data.invoice_no !== null && data.data.invoice_no !== undefined">
                                                            {{ $t('No') }} : {{ data.data.invoice_no }}
                                                        </div>
                                                    </template>
                                                </DxDataGrid> 
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> 

        
        <!-- Update Position -->
        <div class="modal fade" id="kt_modal_update_position" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary py-3" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            {{ $t('Update Position') }}
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeUpdatePositionModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isPositionSaving == true}">
                        <div class="d-flex flex-column" data-kt-stepper="true">
                            <form id="kt_ecommerce_settings_general_form"
                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                <div class="fv-row mb-7 row">
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Position Date') }}
                                        </label> 
                                        <Datepicker name="position_date" :required="this.$checkIfDateFieldRequired ('position_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="positionForm.position_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false"  />
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Position') }}
                                        </label> 
                                        <Select2 v-model="positionForm.position" :options="positionList" name="position" :settings="{ width: '100%'}"/> 
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Document No') }}
                                        </label> 
                                        <input type="text" class="form-control" name="prefix" v-model="positionForm.document_no">
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Description') }}
                                        </label> 
                                        <textarea class="form-control" rows="1" v-model="positionForm.description"  name="description" v-custom ></textarea>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="fs-6 fw-bold form-label mt-3"  >{{$t('Invoice issued?')}}</label>
                                        <br>
                                        <input class="form-check-input mt-1" type="checkbox"  v-model="positionForm.is_invoice_arranged">
                                    </div>
                                    <div class="col-lg-8">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Invoice No') }}
                                        </label> 
                                        <input type="text" :disabled="!positionForm.is_invoice_arranged" class="form-control"  name="prefix" v-model="positionForm.invoice_no">
                                    </div>
                                </div>
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-success" @click="this.savePosition()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isPositionSaving"> {{ $t('Save') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isPositionSaving">
                                                {{ $t('Saving Data') }}... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isPositionSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading') }}...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

        
        <!-- Schedule Modal -->
        <div class="modal fade" id="kt_modal_create_scheduled_operation" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">            
            <div class="modal-dialog modal-lg p-9">
                <div class="modal-content modal-rounded">
                    <div class="modal-header py-7 d-flex justify-content-between bg-primary py-3">
                        <h2 class="text-white">{{ $t('Create Scheduled Operation') }}</h2>
                        <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeScheduleModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y m-3" :class="{'overlay overlay-block': this.isSaving == true}">
                        <div class="d-flex flex-column" data-kt-stepper="true">
                            <form id="kt_ecommerce_settings_general_form"
                                class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                <div class="fv-row mb-7 row">                                    
                                    <div class="col-lg-12">
                                        <label class="fw-bold form-label mt-3" >
                                            <span> {{ $t('Period Type') }}</span>
                                        </label>
                                        <Select2 v-model="scheduleForm.period_type" :options="periodTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_create_scheduled_operation .modal-content' }" name="period_type" />
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('Period Count') }}
                                        </label>
                                        <input type="number" class="form-control" name="period_count" v-model="scheduleForm.period_count">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('Start From') }}
                                        </label>
                                        <Datepicker name="transaction_date" textInput :textInputOptions="{ format: 'dd.MM.yyyy HH:mm' }" v-maska="'##.##.#### ##:##'" v-model="scheduleForm.transaction_date" format="dd.MM.yyyy HH:mm" previewFormat="dd.MM.yyyy HH:mm" autoApply :clearable="false" :enableTimePicker="true" />
                                    </div>
                                </div>
                                <div class="fv-row mb-5 row">
                                    <div class="col-md-12">
                                        <label class="fs-6 fw-bold form-label required">
                                            {{ $t('Description') }}
                                        </label>
                                        <textarea name="description" class="form-control" row="1" v-model="scheduleForm.description" id=""></textarea>
                                    </div> 
                                </div>
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-success" @click="this.saveScheduledTransaction()"  data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isSaving"> {{ $t('Create') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isSaving">
                                                {{ $t('Saving Data') }}... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>

                
                <!-- SEARCH COMPANY BY NAME RESULT -->
                <div v-if="isViewSearchCompanyModal" class="modal fade show" id="kt_modal_search_company" style="display: block;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                    <div class="modal-dialog modal-dialog-centered modal-xl">
                        <div class="modal-content"  >
                            <div class="modal-header bg-primary">
                                <h2 class="modal-title fw-bolder col-md-10 text-white">
                                    Search Result
                                </h2>
                                <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="isViewSearchCompanyModal = false"
                                    data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body scroll-y">
                                <span class="svg-icon svg-icon-1 position-absolute ms-6" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15 form-control-sm" placeholder="Search in Results" v-model="searchBar" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0"/>
                                <div class="progress" v-if="this.isCompanySearchInProgress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        Getting Company List. Please wait...
                                    </div>
                                </div>
                                <h5 v-if="this.companySearchResult.length == 0 && !this.isCompanySearchInProgress">
                                    No result found.
                                </h5>
                                <table class="table table-row-dashed text-gray-800 table-row-gray-300 table-hover" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Registration Number</th>
                                            <th>Date of Creation</th>
                                            <th>Address</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item of searchableList" v-bind:key="item">
                                            <td>
                                                <strong>{{ item.title }}</strong> <br>
                                                <small>{{ item.description }}</small>
                                            </td>
                                            <td>{{ item.companyNumber }}</td> 
                                            <td>{{ previewDate(item.dateOfCreation) }}</td>
                                            <td> 
                                                <small>
                                                    {{ item.address.addressLine1 }} {{ item.address.postalCode }}<br>
                                                    {{ item.address.locality }} / {{ item.address.country }}
                                                </small>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-sm btn-primary" @click="onSelectSearchResult(item.companyNumber)">Select</button>
                                            </td>
                                        </tr> 
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxScrolling, DxEditing, DxLookup } from 'devextreme-vue/data-grid';
// import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxPopover } from 'devextreme-vue/popover';
import AddNewStock from '../Modals/AddNewStock.vue';
import PrintModal from '../Modals/PrintModal.vue';
import { saveAs } from 'file-saver';
import SearchAccountWithSelect from '../../Accounts/Modals/SearchAccountWithSelect.vue';
import TransactionListModal from '../TransactionListModal.vue';
import AddNewAsset from '../Modals/AddNewAsset.vue';
import AddDefaultVendor from '../../Policies/Modals/AddDefaultVendor.vue'; 
import UpdateAccount from '../Modals/UpdateAccount.vue'; 
 
export default {
  name: "OfferUpdate",
  components:{
      DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxPopover, AddNewStock, PrintModal, SearchAccountWithSelect, TransactionListModal, DxSummary, DxTotalItem, AddNewAsset, DxScrolling, AddDefaultVendor,UpdateAccount, DxEditing, DxLookup
  },
  data() {
    return {
        userData: this.$getUser(),
        pageSize: null,
        pageIndex: 0,
        dropDownOptions: { width: 600 },
        isDownloadDropdown: false,
        searchbar: '',
        itemList: [],
        currencyList: [],
        vatRateList: [{ id: 0, text: 'Select an option'}], 
        processList:[{ id: 1, text: 'Standard'}, { id: 2, text: 'Fixed Asset'}],
        salesChannelList: [],
        campaignList: [],
        paymentList: [],
        shippingList: [],
        branchList: [],
        stockTrackingListItems: [],
        localCurrency: null,
        form: {
            account_id: '-1',
            offer_type: 1,
            account_type: 1,
            activeView: 2,
            offer_date: moment(),
            option_date: moment(),
            currency: '-1',
            payment_id: '-1',
            shipping_id: '-1',
            campaign_id: '-1',
            sales_channel_id: '-1',
            branch_id: '-1',
            rate: 1.00000,
            discount: 0.00,
            sales_type: 1,
            rate_date: null,
            process_type: 1,
            isVatActive: false,
            is_accounted: 0,
            position_name: null,
            position: 1,
            is_invoice_issued: false,
            position_date: null,
            document_no: null,
            invoice_no: null,
            description: null,
            is_invoice_arranged: null,
        },
        selectedAccount: {
            id:null,
            name: null,
            account_type: null,
            account_type_name: null,
            account_id: null,
            qr_code: null,
            notes: null,
            company_type_id: null,
            account_number: null,
            contact_name: null,
            email: null,
            mobile_phone: null,
            phone: null,
            postcode: null,
            address: null,
            country: null,
            city: null,
            vat_number: null,
            payment_value: null
        },
        stockForm: {
            id: null, 
            total_price: 0.00,
            net_price: 0.00,
            gross_amount: 0.00,
            price: 0.00,
            discount: 0.00,
            tax_rate: 0.00,
            tax_amount: 0.00,
            description: null,
            discount_amount: null,
            discount_amount_local: null,
            gross_amount_local: 0.00,
            net_price_local: 0.00,
            price_local: 0.00,
            quantity1: 1,
            quantity2: 0,
            session_id: null, 
            stock_account_name: null,
            stock_account_code: null,
            tax_amount_local: 0.00,
            tmp_session_id: null,
            total_price_local: 0.00,
            stock_account_id: null,
            stock_stock_group_id: null,
            stock_product_group_id: null,
            stock_unit_id: null,
            stock_unit_name: null,
            stock_unit_2_name: null,
            stock_unit_2_id: null,
            unit_id: null,
            unit_2_id: null,
            campaign_id: '-1',
            sales_channel_id: '-1',
            item_remain: 0,
            item_amount: 0,
            item_used: 0,
            rate_date: null,
            stock_currency: null,
            stock_currency_id: '-1',
            stock_price: 0.00,
            stock_rate: 1.00000,            
            vat_status_id: 1,
            stock_price_tax_rate: 0.00,
            account_type_name: null,
            vendor_commission: 0.00,
            vendor_commission_rate: 0
        },
        totals: {
            totalAmount: 0.00,
            grossAmount: 0.00,
            netAmount: 0.00,
            discountAmount: 0.00,
            taxAmount: 0.00,
            totalAmountLocal: 0.00,
            grossAmountLocal: 0.00,
            netAmountLocal: 0.00,
            discountAmountLocal: 0.00,
            taxAmountLocal: 0.00
        },
        modalType: 'addItem',
        isOfferProgressing: 'off',
        stockPricesList: [],
        vatStatusList: [
            { id: 1, text: 'Select an option'},
            { id: 2, text: 'VAT (+)'},
            { id: 3, text: 'VAT (in)'}
        ],
        totalsCardView: 'processTotal',
        grandTotalsCardView: 'processTotal',
        stock_qr_code: null,
        isStockItemSaving: false,
        isModalButtonDisabled: false,
        activeTab: 'Account',
        menuSelection: '/InvoiceHelppage',
        iconView : false,
        offerDescription: null,
        isShowDueDate: false,
        isPrintModalOpen: false,
        isViewSearchStockAccountModal: false,
        isViewStockItemModal: false,
        typeSelection: null,
        rateType: 0,
        typeList: [],
        operationList: [],
        activeSubTab: 'OfferItems',
        vendorDefaultList: [],
        positionList: [
            { id: 1, text: 'Offer'},
            { id: 2, text: 'Order'},
            { id: 3, text: 'To Delivery'},
            { id: 4, text: 'Cancel'},
            { id: 5, text: 'Delivered'},
            // { id: 6, text: 'Activated'},
            // { id: 7, text: 'Deactivated'},
        ],
        isPositionSaving: false,
        positionForm: {
            position: 1,
            position_date: moment(),
            document_no: null,
            invoice_no: null,
            description: null,
            is_invoice_arranged: false,
        },
        scheduledTransactions: [],
        periodTypeList: [
            { id: 1, text: 'Weekly'},
            { id: 2, text: 'Monthly'},
            { id: 3, text: 'Annual'}
        ],
        scheduleForm: {
            period_type: 1,
            transaction_date: moment(),
            period_count: 1,
            description:null
        },
        isSaving: false,
        scheduleStatusList: [
            { id: 0, text: 'Pending'},
            { id: 1, text: 'Canceled'},
            { id: 2, text: 'Completed'},
            { id: 3, text: 'Failed'}
        ],
        scheduleStatus: 0,
        scheduleStatusControl : true
    };
  },
  computed: {
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    isItemButtonDisabled() {
        return this.itemList.find(item =>item.isSelected == true );
    },
    isVendorButtonDisabled() {
        return this.vendorDefaultList.find(item => item.isSelected == true );
    },
    showPageSizeSelector() {
      return (list_name)=>{
        if (list_name) {
            return list_name.length > this.pageSize;
        }
      }
    },
    pageSizes() {
        return (list_name)=>{
            if (list_name) {
                let arr = [];
                let x = 1;
                let loop = (list_name.length / this.pageSize);
                for (let i = 1; i <= loop && i <= 5; i++) {
                    arr.push(this.pageSize * x);
                    x++;
                }
                if(loop > 5) arr.push(100);
                if(loop > 1) arr.push('all');
                return arr;
            }
        }
    },
    isFreeOfChargeItemsExist() {
       return this.itemList.find(item => item.gross_amount == 0 || item.gross_amount == 0.00 );
    },
    getSubCredentials() {
      return (operation)=>{
        let isView = false;        
        if(this.form.offer_type == 1) isView = this.$isCredentialActive(82, operation, 81);
        if(this.form.offer_type == 2) isView = this.$isCredentialActive(83, operation, 81);
        return isView;
      }
    },
    getOfferName() {
        let name = false;
        switch(this.form.offer_type) {
            case 1:
                name = "Sales Offer-" + this.form.offer_no;
                break;
            case 2:
                name = "Purchase Offer-" + this.form.offer_no
                break;
            default: name = "";
        }
        return name;
    },
    isPendingTrnExist() {
        return this.scheduledTransactions.find(item => item.status == 0 );
    },
  },
  methods: {
    getData() {
        this.$Progress.start();  
        this.isPrintModalOpen = false;
        this.$removeSessionStorage('tmp_session_id');
        this.$removeSessionStorage('data'+ '_ITMLST');
        this.isOfferProgressing = false;  
        this.itemList = []; 
        this.paymentList = [];
        this.shippingList = [];
        this.branchList = [];
        this.campaignList = [];
        this.salesChannelList = [];
        this.currencyList = this.currencyList.filter((element) => {
            return element.id !== -1;
        });  
         const parameters = { 
            session_id: this.$getUser().session_id,
            offer_header_id: this.$getSessionStorage('record_id'),
            account_type: 12, // Customer: 1 + Supplier: 2
            list_type: [11, 12],
            ai_type: 11
        }; 
        axios.post('api/Offer/GetAllDetails', parameters, {'Content-Type': 'application/json'})
            .then((response) => {   
                if(!response.data.error){  
                    // Offer Header Info
                    this.form = JSON.parse(JSON.stringify(response.data.offer_data)); 
                    this.form.activeView = 2;
                    this.form.shipping_id = (response.data.offer_data.shipping_id == null || response.data.offer_data.shipping_id == '') ? '-1': response.data.offer_data.shipping_id;
                    this.form.payment_id = (response.data.offer_data.payment_id == null || response.data.offer_data.payment_id == '') ? '-1': response.data.offer_data.payment_id;
                    this.form.branch_id = (response.data.offer_data.branch_id == null || response.data.offer_data.branch_id == '') ? '-1': response.data.offer_data.branch_id;
                    this.form.campaign_id = (response.data.offer_data.campaign_id == null || response.data.offer_data.campaign_id == '') ? '-1': response.data.offer_data.campaign_id;
                    this.form.sales_channel_id = (response.data.offer_data.sales_channel_id == null || response.data.offer_data.sales_channel_id == '') ? '-1': response.data.offer_data.sales_channel_id;
                    
                    // Stock Info
                    this.itemList = JSON.parse(JSON.stringify(response.data.offer_items)); 

                    // Stock listesinin son hali ve kullanılan tmp_session_id LocalStorage'e atılır.
                    if(this.itemList.length > 0){ this.$setSessionStorage('tmp_session_id', this.itemList[0].tmp_session_id); } 
                    this.$setSessionStorage('data'+ '_ITMLST', this.itemList); 

                    // Total verileri eklenir  
                    this.totals = JSON.parse(JSON.stringify(response.data.offer_summary)); 

                    // Currency Ayarlardan Setlenir
                    this.localCurrency = this.$getUser().currency_code;

                    // Payment List ve Shipping List Setlenir
                    this.paymentList = response.data.payment_list; 
                    this.shippingList = response.data.shipping_list; 
                    this.shippingList.unshift({ id: '-1', text: 'Select an option'});
                    this.paymentList.unshift({ id: '-1', text: 'Select an option'}); 

                    // Branch List, Campaign List ve Sales Channels List Setlenir
                    this.branchList = response.data.branch_list; 
                    this.campaignList = response.data.campaign_list;
                    this.salesChannelList = response.data.sales_channels_list;
                    this.salesChannelList.unshift({ id: '-1', text: 'Select an option'});  
                    this.campaignList.unshift({ id: '-1', text: 'Select an option'});
                    this.offerDescription = response.data.offer_settings.offer_description;
                    this.isShowDueDate = response.data.offer_settings.is_show_offer_due_date;
                    this.$setSessionStorage('report_category', 5);
                    this.vendorDefaultList = JSON.parse(JSON.stringify(response.data.policy_vendors));
                    if(this.vendorDefaultList.length > 0){ this.$setSessionStorage('tmp_session_id', this.vendorDefaultList[0].tmp_session_id); } 

                    this.getAccountDetail(response.data.offer_data.account_id);

                    this.$setSessionStorage('refreshList', true); 
                    this.$Progress.finish(); 
                }
            })
            .catch(function (error) {
                console.log(error);
                // this.$Progress.finish();
            }
        );   
    },    
    handlePropertyChange(e) {
        if(e.name == "paging") {
            this.pageIndex = e.component.pageIndex();
        }
    },
    openStockItemModal(modalType) {
        try {
            this.$refs.openStockItemModal.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}

        this.modalType = modalType;
        this.isViewStockItemModal = true;
        this.isStockItemSaving = false;
        this.isModalButtonDisabled = false;
        this.totalsCardView = 'processTotal';
        this.stockPricesList = [{ id: '-1', text: 'Select an option'}];
        this.$Progress.start();
        
        this.stockTrackingListItems = [],
        this.stockForm.stock_account_id = null;
        this.stockForm.stock_account_name = null;
        this.stockForm.stock_account_code = null;
        this.stockForm.stock_stock_group_id = null;
        this.stockForm.stock_product_group_id = null;
        this.stockForm.stock_unit_id = null;
        this.stockForm.stock_unit_name = null;
        this.stockForm.stock_unit_2_name = null;
        this.stockForm.stock_unit_2_id = null; 
        this.stockForm.account_type = null;

        this.stockForm.quantity1 = 1;
        this.stockForm.quantity2 = 0;
        this.stockForm.price = 0;
        this.stockForm.total_price = 0;
        this.stockForm.discount = 0;
        this.stockForm.discount_amount = 0.00;
        this.stockForm.net_price = 0;
        this.stockForm.tax_amount = 0;
        this.stockForm.gross_amount = 0;
        this.stockForm.description = null;   
        this.stockForm.sales_channel_id = this.form.sales_channel_id;
        this.stockForm.campaign_id = this.form.campaign_id;
        this.stockForm.price_local = 0.00;
        this.stockForm.stock_price = 0.00;
        this.stockForm.stock_rate = 1.00000;
        this.stockForm.total_price_local = 0.00;
        this.stockForm.discount_amount_local = 0.00;
        this.stockForm.net_price_local = 0.00;
        this.stockForm.tax_rate = 0.00;
        this.stockForm.tax_amount_local = 0.00;
        this.stockForm.gross_amount_local = 0.00;
        this.stockForm.stock_currency = null;
        this.stockForm.stock_currency_id = '-1';        
        this.stockForm.item_used = 0;
        this.stockForm.item_remain = 0;
        this.stockForm.item_amount = 0;
        this.stockForm.vat_status_id = 1;
        this.stockForm.stock_price_tax_rate = 0.00;
        this.stockForm.vendor_commission = 0.00;
        this.stockForm.vendor_commission_rate = 0;
        
        if (this.modalType == 'editItem') {                        
            this.itemList.forEach((item) => {
                if (item.isSelected) { 
                    this.stockForm.description = item.description;
                    this.stockForm.discount = item.discount.replace(/,/g, '');
                    this.stockForm.discount_amount = item.discount_amount.replace(/,/g, '');
                    this.stockForm.discount_amount_local = item.discount_amount_local.replace(/,/g, '');
                    this.stockForm.gross_amount = item.gross_amount.replace(/,/g, '');
                    this.stockForm.gross_amount_local = item.gross_amount_local.replace(/,/g, '');
                    this.stockForm.id = item.id;
                    this.stockForm.net_price = item.net_price.replace(/,/g, '');
                    this.stockForm.net_price_local = item.net_price_local.replace(/,/g, '');
                    this.stockForm.price = item.price.replace(/,/g, '');
                    this.stockForm.price_local = item.price_local.replace(/,/g, '');
                    this.stockForm.quantity1 = item.quantity1;
                    this.stockForm.quantity2 = item.quantity2;
                    this.stockForm.session_id = item.session_id;
                    this.stockForm.stock_account_id = item.stock_account_id;
                    this.stockForm.stock_account_code = item.stock_account_code;
                    this.stockForm.stock_account_name = item.stock_account_name;
                    this.stockForm.account_type_name = item.stock_account_type_name;
                    this.stockForm.tax_amount = item.tax_amount.replace(/,/g, '');
                    this.stockForm.tax_amount_local = item.tax_amount_local.replace(/,/g, '');
                    this.stockForm.tax_rate = parseFloat(item.tax_rate.replace(/,/g, '.'));
                    this.stockForm.stock_price_tax_rate = parseFloat(item.tax_rate.replace(/,/g, '.'));
                    this.stockForm.tmp_session_id = item.tmp_session_id;
                    this.stockForm.total_price = item.total_price.replace(/,/g, '');
                    this.stockForm.total_price_local = item.total_price_local.replace(/,/g, '');
                    this.stockForm.stock_stock_group_id = item.stock_stock_group_id;
                    this.stockForm.stock_product_group_id = item.stock_product_group_id;
                    this.stockForm.stock_unit_name = item.unit_name;
                    this.stockForm.stock_unit_2_name = item.unit_2_name;
                    this.stockForm.unit_id = item.unit_id;
                    this.stockForm.unit_2_id = item.unit_2_id;
                    this.stockForm.stock_unit_id = item.unit_id;
                    this.stockForm.stock_unit_2_id = item.unit_2_id; 
                    this.stockForm.sales_channel_id = (item.sales_channel_id !== null && item.sales_channel_id !== '') ? item.sales_channel_id : '-1';
                    this.stockForm.campaign_id = (item.campaign_id !== null && item.campaign_id !== '') ? item.campaign_id : '-1';
                    this.stockForm.stock_currency = item.stock_currency;
                    this.stockForm.stock_rate = item.stock_rate.replace(/,/g, '');
                    this.stockForm.stock_price = parseFloat(item.stock_price.replace(/,/g, ''));
                    this.stockForm.item_amount = 0;
                    // this.stockForm.item_remain = 0;
                    this.stockForm.item_used = 0;
                    this.stockForm.vat_status_id = item.vat_status_id;
                    this.stockForm.vendor_commission = Math.abs(parseFloat(item.vendor_commission.replaceAll(',', '')));
                    this.stockForm.vendor_commission_rate = Math.abs(parseFloat(item.vendor_commission_rate.replaceAll(',', '')));
                }
            });
        }
        
        const parameters1 = {
            session_id: this.$getUser().session_id,
            list_types: [
                {
                    list_type: 15,
                    list_name: "vat_rate"
                }
            ],
        };  
        const parameters2 = {
            stock_account_id: this.stockForm.stock_account_id,
            session_id: this.$getUser().session_id
        };
        const parameters3 = {
            session_id: this.$getUser().session_id,
            transaction_date: moment(this.form.offer_date).format('YYYY-MM-DD[T]HH:mm:ss')
        };
        const parameters4 = {
            route: this.modalType == 'addItem'  ? 'OfferItemCreate' : 'OfferItemUpdate',
            session_id: this.$getUser().session_id
        };  
        
        const requestOne =  axios.post('/api/Helper/GetMultiLists', parameters1, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/CompanyAccounts/GetStockCurrencies', parameters2, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Invoice/GetInstantVatStatus', parameters3, {'Content-Type': 'application/json'}); 
        const requestFour =  axios.post('/api/FormValidations/GetFormFields', parameters4, {'Content-Type': 'application/json'});
        
        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
            this.vatRateList = responses[0].data.vat_rate;
            this.vatRateList.unshift({ id: 0, text: 'Select an option'});
            this.vatRateList.forEach((value) => {
                value.id = parseFloat(value.val);
            });
            if(responses[3].data != null){  
                sessionStorage.setItem('frm_val', JSON.stringify(responses[3].data));
            }    
            
            if (this.modalType == 'editItem') {
                this.stockPricesList = responses[1].data;
                this.stockPricesList.forEach((item) => {
                    item.prefix = item.price_type_name !== '' && item.price_type_name !== null ? ' - ' : '';
                    if (this.form.offer_type == 2) {
                        item.text = item.currency + ' (' + item.sales_price + ')' + item.prefix + item.price_type_name;
                    }
                    if (this.form.offer_type == 1) {
                        item.text = item.currency + ' (' + item.purchase_price + ')' + item.prefix + item.price_type_name;
                    }
                    item.sales_price = item.sales_price.replace(/,/g, '');
                    item.sales_tax_amount = item.sales_tax_amount.replace(/,/g, '');
                    item.purchase_price = item.purchase_price.replace(/,/g, '');
                    item.purchase_tax_amount = item.purchase_tax_amount.replace(/,/g, '');     
                });
                const defaultStockPrice = this.stockPricesList.find(item => item.currency == this.stockForm.stock_currency && this.stockForm.stock_currency !== null);
                if (defaultStockPrice) {
                    this.stockForm.stock_currency_id = defaultStockPrice.id;
                }
                this.stockPricesList.unshift({ id: '-1', text: 'Select an option'});
                this.getStockTrackingGroups();
            }
            this.form.isVatActive = responses[2].data;
            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();
        });
    },
    onResetStockInfo() {
        this.stockForm.stock_account_id = null;
        this.stockForm.account_type = null;
        this.stockForm.total_price = 0.00;
        this.stockForm.net_price = 0.00;
        this.stockForm.gross_amount = 0.00;
        this.stockForm.price = 0.00;
        this.stockForm.discount = 0.00;
        this.stockForm.tax_rate = 0.00;
        this.stockForm.tax_amount = 0.00;
        this.stockForm.discount_amount = 0.00;
        this.stockForm.discount_amount_local = null;
        this.stockForm.gross_amount_local = 0.00;
        this.stockForm.net_price_local = 0.00;
        this.stockForm.price_local = 0.00;
        this.stockForm.quantity1 = 1;
        this.stockForm.quantity2 = 0;
        this.stockForm.tax_amount_local = 0.00;
        this.stockForm.total_price_local = 0.00;
        this.stockForm.item_remain = null;
        this.stockForm.item_amount = 0;
        this.stockForm.item_used = 0;
        this.stockForm.stock_currency = null;
        this.stockForm.stock_currency_id = '-1';
        this.stockForm.stock_price = 0.00;
        this.stockForm.stock_rate = 1.00000;
        this.stockForm.vat_status_id = 1;
        this.stockForm.stock_price_tax_rate = 0.00;
        this.stockForm.vendor_commission = 0.00;
        this.stockForm.vendor_commission_rate = 0;
        this.stockPricesList = [{ id: '-1', text: 'Select an option'}];
        this.stockTrackingListItems = [];
    },
    onSelectItem(item) {
        this.itemList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });
    },
    onEnterPopover(value){ 
        value.isPopover = true; 
    },
    onLeavePopover(value){ 
        value.isPopover = false;
    },
    getAccountDetail(id) {
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            response.data.account_id = this.$unlock(response.data.account_id);
            response.data.name = this.$unlock(response.data.name);
            response.data.qr_code = this.$unlock(response.data.qr_code);
            response.data.bank_short_code = this.$unlock(response.data.bank_short_code);
            response.data.bank_account_number = this.$unlock(response.data.bank_account_number);
            response.data.customer_account_number = this.$unlock(response.data.customer_account_number);
            response.data.customer_contact_name = this.$unlock(response.data.customer_contact_name);
            response.data.customer_email = this.$unlock(response.data.customer_email);
            response.data.customer_mobile_phone = this.$unlock(response.data.customer_mobile_phone);
            response.data.customer_phone = this.$unlock(response.data.customer_phone);
            response.data.customer_postcode = this.$unlock(response.data.customer_postcode);
            response.data.customer_address = this.$unlock(response.data.customer_address);
            response.data.customer_country = this.$unlock(response.data.customer_country);
            response.data.customer_city = this.$unlock(response.data.customer_city);
            response.data.customer_vat_number = this.$unlock(response.data.customer_vat_number);
            response.data.customer_phone_country_code = this.$unlock(response.data.customer_phone_country_code);
            response.data.customer_land_phone_country_code = this.$unlock(response.data.customer_land_phone_country_code);
            response.data.customer_district = this.$unlock(response.data.customer_district);
            response.data.customer_x_coordinate = this.$unlock(response.data.customer_x_coordinate);
            response.data.customer_y_coordinate = this.$unlock(response.data.customer_y_coordinate);
            response.data.customer_uprn = this.$unlock(response.data.customer_uprn);
            response.data.customer_udprn = this.$unlock(response.data.customer_udprn);
            response.data.personnel_account_number = this.$unlock(response.data.personnel_account_number);
            response.data.personnel_contact_name = this.$unlock(response.data.personnel_contact_name);
            response.data.personnel_email = this.$unlock(response.data.personnel_email);
            response.data.personnel_mobile_phone = this.$unlock(response.data.personnel_mobile_phone);
            response.data.personnel_phone = this.$unlock(response.data.personnel_phone);
            response.data.personnel_postcode = this.$unlock(response.data.personnel_postcode);
            response.data.personnel_address = this.$unlock(response.data.personnel_address);
            response.data.personnel_country = this.$unlock(response.data.personnel_country);
            response.data.personnel_city = this.$unlock(response.data.personnel_city);
            response.data.personnel_nino = this.$unlock(response.data.personnel_nino);
            response.data.personnel_phone_country_code = this.$unlock(response.data.personnel_phone_country_code);
            response.data.personnel_land_phone_country_code = this.$unlock(response.data.personnel_land_phone_country_code);
            response.data.personnel_district = this.$unlock(response.data.personnel_district);
            response.data.personnel_x_coordinate = this.$unlock(response.data.personnel_x_coordinate);
            response.data.personnel_y_coordinate = this.$unlock(response.data.personnel_y_coordinate);
            response.data.personnel_uprn = this.$unlock(response.data.personnel_uprn);
            response.data.personnel_udprn = this.$unlock(response.data.personnel_udprn);
            response.data.personnel_position_name = this.$unlock(response.data.personnel_position_name);
            response.data.supplier_account_number = this.$unlock(response.data.supplier_account_number);
            response.data.supplier_contact_name = this.$unlock(response.data.supplier_contact_name);
            response.data.supplier_email = this.$unlock(response.data.supplier_email);
            response.data.supplier_mobile_phone = this.$unlock(response.data.supplier_mobile_phone);
            response.data.supplier_phone = this.$unlock(response.data.supplier_phone);
            response.data.supplier_postcode = this.$unlock(response.data.supplier_postcode);
            response.data.supplier_address = this.$unlock(response.data.supplier_address);
            response.data.supplier_country = this.$unlock(response.data.supplier_country);
            response.data.supplier_city = this.$unlock(response.data.supplier_city);
            response.data.supplier_vat_number = this.$unlock(response.data.supplier_vat_number);
            response.data.supplier_phone_country_code = this.$unlock(response.data.supplier_phone_country_code);
            response.data.supplier_land_phone_country_code = this.$unlock(response.data.supplier_land_phone_country_code);
            response.data.supplier_district = this.$unlock(response.data.supplier_district);
            response.data.supplier_x_coordinate = this.$unlock(response.data.supplier_x_coordinate);
            response.data.supplier_y_coordinate = this.$unlock(response.data.supplier_y_coordinate);
            response.data.supplier_uprn = this.$unlock(response.data.supplier_uprn);
            response.data.supplier_udprn = this.$unlock(response.data.supplier_udprn);
            response.data.other_contact_name = this.$unlock(response.data.other_contact_name);
            response.data.other_email = this.$unlock(response.data.other_email);
            response.data.other_mobile_phone = this.$unlock(response.data.other_mobile_phone);
            response.data.other_phone = this.$unlock(response.data.other_phone);
            response.data.other_phone_country_code = this.$unlock(response.data.other_phone_country_code);
            response.data.other_land_phone_country_code = this.$unlock(response.data.other_land_phone_country_code);

            this.stockForm.stock_account_id = response.data.id;
            this.stockForm.account_type = response.data.account_type;
            this.stockForm.price = 0.00;
            this.stockForm.tax_rate = 0.00;
            if(this.modalType !== 'editItem'){
                this.stockForm.stock_price = 0.00;
                this.stockForm.stock_rate = 1.00000;
            }

            if (response.data.account_type == 1 || response.data.account_type == 2) {
                this.form.account_id = response.data.id;
                this.form.account_name = response.data.name;
                this.form.account_type = response.data.account_type;
                this.selectedAccount.id = response.data.id;
                this.selectedAccount.name = response.data.name;
                this.selectedAccount.account_type = response.data.account_type;
                this.selectedAccount.account_type_name = response.data.account_type_name;
                this.selectedAccount.account_id = response.data.account_id;
                this.selectedAccount.qr_code = response.data.qr_code;
                this.selectedAccount.notes = response.data.notes;
                // Customer
                if (response.data.account_type == 1) {
                    this.selectedAccount.company_type_id = response.data.customer_company_type_id;
                    this.selectedAccount.account_number = response.data.customer_account_number;
                    this.selectedAccount.contact_name = response.data.customer_contact_name;
                    this.selectedAccount.email = response.data.customer_email;
                    this.selectedAccount.mobile_phone = response.data.customer_mobile_phone;
                    this.selectedAccount.phone = response.data.customer_phone;
                    this.selectedAccount.postcode = response.data.customer_postcode;
                    this.selectedAccount.address = response.data.customer_address;
                    this.selectedAccount.country = response.data.customer_country;
                    this.selectedAccount.city = response.data.customer_city;
                    this.selectedAccount.vat_number = response.data.customer_vat_number;
                    this.selectedAccount.payment_value = response.data.customer_payment_value;
                }
                // Supplier
                if (response.data.account_type == 2) {
                    this.selectedAccount.company_type_id = response.data.supplier_company_type_id;
                    this.selectedAccount.account_number = response.data.supplier_account_number;
                    this.selectedAccount.contact_name = response.data.supplier_contact_name;
                    this.selectedAccount.email = response.data.supplier_email;
                    this.selectedAccount.mobile_phone = response.data.supplier_mobile_phone;
                    this.selectedAccount.phone = response.data.supplier_phone;
                    this.selectedAccount.postcode = response.data.supplier_postcode;
                    this.selectedAccount.address = response.data.supplier_address;
                    this.selectedAccount.country = response.data.supplier_country;
                    this.selectedAccount.city = response.data.supplier_city;
                    this.selectedAccount.vat_number = response.data.supplier_vat_number;
                    this.selectedAccount.payment_value = response.data.supplier_payment_value;
                } 
            }

            // Stock seçimi yapıldığında varsayılan alanları setlemek için kullanılır.
            // Offer Header üzerinden Discount miktarlarını alır gelir vs.
            if (response.data.account_type == 7) {
                this.stockForm.stock_account_id = response.data.id;
                this.stockForm.stock_account_name = response.data.name;
                this.stockForm.account_type = response.data.account_type;
                this.stockForm.account_type_name = response.data.account_type_name;
                this.stockForm.stock_account_code = response.data.account_id;
                this.stockForm.qr_code = response.data.qr_code;
                this.stockForm.notes = response.data.notes;
                this.stockForm.stock_stock_group_id = response.data.stock_stock_group_id;
                this.stockForm.stock_product_group_id = response.data.stock_product_group_id;
                this.stockForm.stock_unit_id = response.data.stock_unit_id;
                this.stockForm.stock_unit_2_id = response.data.stock_unit_2_id;
                this.stockForm.discount = this.form.discount;
                this.stockForm.discount_amount = this.form.discount_amount;
                this.stockForm.stock_unit_name = response.data.stock_unit_name;
                this.stockForm.stock_unit_2_name = response.data.stock_unit_2_name;
                this.stockForm.item_amount = response.data.item_amount;     
                this.stockForm.item_remain = response.data.item_remain;
                this.stockForm.item_used = response.data.item_used;
                this.stockForm.price = 0.00;
                this.stockForm.tax_rate = 0.00;
                this.stockForm.stock_rate = 1.00000;
                this.stockForm.stock_price = 0.00;
                this.stockPricesList = [];

                if(this.vendorDefaultList.length > 0) { this.getVendorSettings(); }

                const params = {
                    stock_account_id: this.stockForm.stock_account_id,
                    session_id: this.$getUser().session_id
                };
                axios.post("/api/CompanyAccounts/GetStockCurrencies", params, {'Content-Type': 'application/json'}).then((stockPriceResponse) => {
                    
                    this.stockPricesList = stockPriceResponse.data;
                    this.stockPricesList.forEach((item) => {
                        item.prefix = item.price_type_name !== '' && item.price_type_name !== null ? ' - ' : '';
                        if (this.form.offer_type == 2) {
                            item.text = item.currency + ' (' + item.sales_price + ')' + item.prefix + item.price_type_name;
                        }
                        if (this.form.offer_type == 1) {
                            item.text = item.currency + ' (' + item.purchase_price + ')' + item.prefix + item.price_type_name;
                        }                        
                        item.sales_price = item.sales_price.replace(/,/g, '');
                        item.sales_tax_amount = item.sales_tax_amount.replace(/,/g, '');
                        item.purchase_price = item.purchase_price.replace(/,/g, '');
                        item.purchase_tax_amount = item.purchase_tax_amount.replace(/,/g, '');   
                    });

                    const defaultStockPrice = this.stockPricesList.find(item => item.currency == this.form.currency && item.is_default);
                    if (defaultStockPrice) {
                        this.stockForm.stock_currency_id = defaultStockPrice.id;
                        this.stockForm.stock_currency = defaultStockPrice.currency;
                        if (this.form.offer_type == 2) {
                            this.stockForm.stock_price = defaultStockPrice.sales_price !== '' ? parseFloat(defaultStockPrice.sales_price) : 0.00;
                            this.stockForm.price = defaultStockPrice.sales_price !== '' ? parseFloat(defaultStockPrice.sales_price) : 0.00;
                            this.stockForm.tax_rate = (defaultStockPrice.sales_tax_amount !== '' && defaultStockPrice.sales_tax_amount !== null) ? parseFloat(defaultStockPrice.sales_tax_amount) : 0.00;
                            this.stockForm.stock_price_tax_rate = (defaultStockPrice.sales_tax_amount !== '' && defaultStockPrice.sales_tax_amount !== null) ? parseFloat(defaultStockPrice.sales_tax_amount) : 0.00;
                            this.stockForm.vat_status_id = defaultStockPrice.sales_vat_status_id;
                        }
                        if (this.form.offer_type == 1) {
                            this.stockForm.stock_price = defaultStockPrice.purchase_price !== '' ? parseFloat(defaultStockPrice.purchase_price) : 0.00;
                            this.stockForm.price = defaultStockPrice.purchase_price !== '' ? parseFloat(defaultStockPrice.purchase_price) : 0.00;
                            this.stockForm.tax_rate = (defaultStockPrice.purchase_tax_amount !== '' && defaultStockPrice.purchase_tax_amount !== null) ? parseFloat(defaultStockPrice.purchase_tax_amount) : 0.00;
                            this.stockForm.stock_price_tax_rate = (defaultStockPrice.purchase_tax_amount !== '' && defaultStockPrice.purchase_tax_amount !== null) ? parseFloat(defaultStockPrice.purchase_tax_amount) : 0.00;
                            this.stockForm.vat_status_id = defaultStockPrice.purchase_vat_status_id;
                        }
                        /* Stok içerisinden gelen price için VAT (None) seçildiyse Tax Rate sıfırlanır  */
                        if (this.stockForm.vat_status_id == 1) {
                            this.stockForm.tax_rate = 0.00;
                        }
                        if (this.form.isVatActive == false) {
                            this.stockForm.vat_status_id = 1;
                            this.stockForm.tax_rate = 0.00;
                        }
                    } else {
                        this.clearStockForm();
                    }
                    this.stockPricesList.unshift({ id: '-1', text: 'Select an option'});
                    this.calculateTotals(function() {});
                    this.getStockTrackingGroups();
                })
                .catch(function (error) {
                    console.log(error);
                    this.calculateTotals(function() {});
                });
            }

            /* Other Account */
            if (response.data.account_type == 15) {
                this.stockPricesList = [{id: '-1', text: 'Select an option'}];
                this.stockTrackingListItems = [];
                this.stockForm.stock_account_name = response.data.name;
                this.stockForm.account_type = response.data.account_type;
                this.stockForm.account_type_name = response.data.account_type_name;
                this.stockForm.stock_account_code = response.data.account_id;
                this.stockForm.stock_currency_id = '-1';
                this.stockForm.stock_unit_id = null;
                this.stockForm.stock_unit_2_id = null;
                this.stockForm.stock_unit_name = null;
                this.stockForm.stock_unit_2_name = null;
                this.stockForm.item_remain = null;
                this.stockForm.vat_status_id = response.data.other_vat_status_id ;
                this.stockForm.tax_rate = response.data.other_account_vat_rate !== '' ? parseFloat(response.data.other_account_vat_rate) : 0.00;
                this.stockForm.stock_price_tax_rate = response.data.other_account_vat_rate !== '' ? parseFloat(response.data.other_account_vat_rate) : 0.00;

                if (this.form.isVatActive == false) {
                    this.stockForm.vat_status_id = 1;
                    this.stockForm.tax_rate = 0.00;
                }
                this.calculateTotals();
            }

            /* Expense Account */
            if (response.data.account_type == 6) {
                this.stockPricesList = [{id: '-1', text: 'Select an option'}];
                this.stockTrackingListItems = [];
                this.stockForm.stock_account_name = response.data.name;
                this.stockForm.account_type = response.data.account_type;
                this.stockForm.account_type_name = response.data.account_type_name;
                this.stockForm.stock_account_code = response.data.account_id;
                this.stockForm.stock_currency_id = '-1';
                this.stockForm.stock_unit_id = null;
                this.stockForm.stock_unit_2_id = null;
                this.stockForm.stock_unit_name = null;
                this.stockForm.stock_unit_2_name = null;
                this.stockForm.item_remain = null;
                this.stockForm.vat_status_id = response.data.expense_vat_status_id ;
                this.stockForm.tax_rate = response.data.expense_vat_rate !== '' ? parseFloat(response.data.expense_vat_rate) : 0.00;
                this.stockForm.stock_price_tax_rate = response.data.expense_vat_rate !== '' ? parseFloat(response.data.expense_vat_rate) : 0.00;
                
                if (this.form.isVatActive == false) {
                    this.stockForm.vat_status_id = 1;
                    this.stockForm.tax_rate = 0.00;
                }

                this.calculateTotals();
            }

            /* Fixed Asset Account */
            if (response.data.account_type == 8) {
                this.stockForm.stock_account_name = response.data.name;
                this.stockForm.account_type = response.data.account_type;
                this.stockForm.account_type_name = response.data.account_type_name;
                this.stockForm.stock_account_code = response.data.account_id;
            }
        })
        .catch(function () {
            this.$Progress.finish();
        });  
    },
    getVendorSettings(callback) {
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.stockForm.stock_account_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id')
        };
        const that = this;
        axios.post('/api/Invoice/SetAccountVendorSettings', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                that.stockForm.vendor_commission_rate = response.data;
                
                if(typeof callback == 'function'){
                    callback();
                }
            }
        });
    },
    saveData(isAutoSave) {
        if(!isAutoSave) {
            this.$checkSelect2Validation('offerItemForm');
            if(!document.forms['offerItemForm'].reportValidity()){
                return;
            }
        }
        this.isModalButtonDisabled = true;
        this.isStockItemSaving = true;
        if (this.stockForm.quantity1 == null || this.stockForm.quantity1 == '' || this.stockForm.quantity1 == undefined) { this.stockForm.quantity1 = 0; }
        if (this.stockForm.quantity2 == null || this.stockForm.quantity2 == '' || this.stockForm.quantity2 == undefined) { this.stockForm.quantity2 = 0; }
        const that = this;
        const params = {
            stock_account_id: this.stockForm.stock_account_id,
            quantity1: this.stockForm.quantity1,
            quantity2: this.stockForm.quantity2,
            rate: this.form.rate,
            price: (this.stockForm.price !== null && this.stockForm.price !== '' && this.stockForm.price !== undefined) ? this.stockForm.price : 0,
            discount: (this.stockForm.discount !== null && this.stockForm.discount !== '' && this.stockForm.discount !== undefined) ? this.stockForm.discount : 0,
            tax_rate: (this.stockForm.tax_rate !== null && this.stockForm.tax_rate !== '' && this.stockForm.tax_rate !== undefined) ? this.stockForm.tax_rate : 0,
            description: this.stockForm.description,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            sales_channel_id: this.stockForm.sales_channel_id,
            campaign_id: this.stockForm.campaign_id,
            stock_currency: this.stockForm.stock_currency,
            stock_price: (this.stockForm.stock_price !== null && this.stockForm.stock_price !== '' && this.stockForm.stock_price !== undefined) ? this.stockForm.stock_price : 0,
            stock_rate: (this.stockForm.stock_rate !== null && this.stockForm.stock_rate !== '' && this.stockForm.stock_rate !== undefined) ? this.stockForm.stock_rate : 0,
            vat_status_id: this.stockForm.vat_status_id,
            vat_amount: (this.stockForm.tax_amount !== null && this.stockForm.tax_amount !== '' && this.stockForm.tax_amount !== undefined) ? this.stockForm.tax_amount : 0,
            offer_type: this.form.offer_type,
            vendor_commission_rate: (this.stockForm.vendor_commission_rate !== null && this.stockForm.vendor_commission_rate !== '' && this.stockForm.vendor_commission_rate !== undefined) ? this.stockForm.vendor_commission_rate : 0,
        };
        if (params.sales_channel_id == '-1' || params.sales_channel_id == '' || params.sales_channel_id == undefined) { params.sales_channel_id = null; }
        if (params.campaign_id == '-1' || params.campaign_id == '' || params.campaign_id == undefined) { params.campaign_id = null; }
        if (params.stock_currency == null || params.stock_currency == '' || params.stock_currency == undefined || params.stock_currency == '-1') {
            params.stock_currency = this.form.currency;
        }
        if(params.vendor_commission_rate == null || isNaN(params.vendor_commission_rate) || params.vendor_commission_rate == undefined || params.vendor_commission_rate == '') {
            params.vendor_commission_rate = 0;
        }
        axios.post('api/Offer/AddOfferItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
             if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.itemList = [];
                response.data.offer_items.forEach((item) => { 
                    this.itemList.push({
                        created_at: item.created_at,
                        description: item.description,
                        discount: item.discount,
                        discount_amount: item.discount_amount,
                        discount_amount_local: item.discount_amount_local,
                        gross_amount: item.gross_amount,
                        gross_amount_local: item.gross_amount_local,
                        id: item.id,
                        net_price: item.net_price,
                        net_price_local: item.net_price_local,
                        price: item.price,
                        price_local: item.price_local,
                        quantity1: item.quantity1,
                        quantity2: item.quantity2,
                        session_id: item.session_id,
                        stock_account_id: item.stock_account_id,
                        stock_account_code: item.stock_account_code,
                        stock_account_name: item.stock_account_name,
                        tax_amount: item.tax_amount,
                        tax_amount_local: item.tax_amount_local,
                        tax_rate: item.tax_rate,
                        tmp_session_id: item.tmp_session_id,
                        total_price: item.total_price,
                        total_price_local: item.total_price_local,
                        unit_name: item.unit_name,
                        unit_2_name: item.unit_2_name,
                        unit_id: item.unit_id,
                        unit_2_id: item.unit_2_id,
                        sales_channel_id: item.sales_channel_id !== '' ? item.sales_channel_id : '-1',
                        campaign_id: item.campaign_id !== '' ? item.campaign_id : '-1',
                        stock_currency: item.stock_currency,
                        stock_price: item.stock_price,
                        stock_rate: item.stock_rate,
                        sales_channel_name: item.sales_channel_name,
                        campaign_name: item.campaign_name,
                        vat_status_id: item.vat_status_id,
                        isPopover: false,
                        vendor_commission: item.vendor_commission,
                        vendor_commission_rate: item.vendor_commission_rate,
                    });
                }); 
    
                this.totals.totalAmount = response.data.offer_summary.totalAmount;
                this.totals.grossAmount = response.data.offer_summary.grossAmount;
                this.totals.netAmount = response.data.offer_summary.netAmount; 
                this.totals.discountAmount = response.data.offer_summary.discountAmount; 
                this.totals.taxAmount = response.data.offer_summary.taxAmount;
                this.totals.totalAmountLocal = response.data.offer_summary.totalAmountLocal;
                this.totals.grossAmountLocal = response.data.offer_summary.grossAmountLocal;
                this.totals.netAmountLocal = response.data.offer_summary.netAmountLocal; 
                this.totals.discountAmountLocal = response.data.offer_summary.discountAmountLocal; 
                this.totals.taxAmountLocal = response.data.offer_summary.taxAmountLocal;
                
                if(this.itemList.length > 0){
                    this.$setSessionStorage('tmp_session_id', this.itemList[0].tmp_session_id);
                } 
                this.$setSessionStorage('data'+ '_ITMLST', this.itemList);
                this.$refs.closeModal.click();
            }
            this.isStockItemSaving = false;
        })
        .catch(function () {
            this.isStockItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isStockItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });   
    },
    updateData() {
        this.$checkSelect2Validation('offerItemForm');
        if(!document.forms['offerItemForm'].reportValidity()){
            return;
        }
        this.itemList.forEach((item) => { 
            if (item.isSelected) {
                this.isModalButtonDisabled = true;
                this.isStockItemSaving = true;
                if (this.stockForm.quantity1 == null || this.stockForm.quantity1 == '' || this.stockForm.quantity1 == undefined) { this.stockForm.quantity1 = 0; }
                if (this.stockForm.quantity2 == null || this.stockForm.quantity2 == '' || this.stockForm.quantity2 == undefined) { this.stockForm.quantity2 = 0; }
                const that = this;
                const params = {
                    id: this.stockForm.id,
                    stock_account_id: this.stockForm.stock_account_id,
                    quantity1: this.stockForm.quantity1,
                    quantity2: this.stockForm.quantity2,
                    rate: this.form.rate,
                    price: (this.stockForm.price !== null && this.stockForm.price !== '' && this.stockForm.price !== undefined) ? this.stockForm.price : 0,
                    discount: (this.stockForm.discount !== null && this.stockForm.discount !== '' && this.stockForm.discount !== undefined) ? this.stockForm.discount : 0,
                    tax_rate: (this.stockForm.tax_rate !== null && this.stockForm.tax_rate !== '' && this.stockForm.tax_rate !== undefined) ? this.stockForm.tax_rate : 0,
                    description: this.stockForm.description,
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    sales_channel_id: this.stockForm.sales_channel_id,
                    campaign_id: this.stockForm.campaign_id,
                    stock_currency: this.stockForm.stock_currency,
                    stock_price: (this.stockForm.stock_price !== null && this.stockForm.stock_price !== '' && this.stockForm.stock_price !== undefined) ? this.stockForm.stock_price : 0,
                    stock_rate: (this.stockForm.stock_rate !== null && this.stockForm.stock_rate !== '' && this.stockForm.stock_rate !== undefined) ? this.stockForm.stock_rate : 0,
                    vat_status_id: this.stockForm.vat_status_id,
                    vat_amount: (this.stockForm.tax_amount !== null && this.stockForm.tax_amount !== '' && this.stockForm.tax_amount !== undefined) ? this.stockForm.tax_amount : 0,
                    offer_type: this.form.offer_type,
                    vendor_commission_rate: (this.stockForm.vendor_commission_rate !== null && this.stockForm.vendor_commission_rate !== '' && this.stockForm.vendor_commission_rate !== undefined) ? this.stockForm.vendor_commission_rate : 0,
                };
                if (params.sales_channel_id == '-1' || params.sales_channel_id == '' || params.sales_channel_id == undefined) { params.sales_channel_id = null; }
                if (params.campaign_id == '-1' || params.campaign_id == '' || params.campaign_id == undefined) { params.campaign_id = null; }
                if (params.stock_currency == null || params.stock_currency == '' || params.stock_currency == undefined || params.stock_currency == '-1') {
                    params.stock_currency = this.form.currency;
                }
                if(params.vendor_commission_rate == null || isNaN(params.vendor_commission_rate) || params.vendor_commission_rate == undefined || params.vendor_commission_rate == '') {
                    params.vendor_commission_rate = 0;
                }
                axios.post('api/Offer/UpdateOfferItem', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.itemList = [];
                        response.data.offer_items.forEach((item) => { 
                            this.itemList.push({
                                created_at: item.created_at,
                                description: item.description,
                                discount: item.discount,
                                discount_amount: item.discount_amount,
                                discount_amount_local: item.discount_amount_local,
                                gross_amount: item.gross_amount,
                                gross_amount_local: item.gross_amount_local,
                                id: item.id,
                                net_price: item.net_price,
                                net_price_local: item.net_price_local,
                                price: item.price,
                                price_local: item.price_local,
                                quantity1: item.quantity1,
                                quantity2: item.quantity2,
                                session_id: item.session_id,
                                stock_account_id: item.stock_account_id,
                                stock_account_code: item.stock_account_code,
                                stock_account_name: item.stock_account_name,
                                tax_amount: item.tax_amount,
                                tax_amount_local: item.tax_amount_local,
                                tax_rate: item.tax_rate,
                                tmp_session_id: item.tmp_session_id,
                                total_price: item.total_price,
                                total_price_local: item.total_price_local,
                                unit_name: item.unit_name,
                                unit_2_name: item.unit_2_name,
                                unit_id: item.unit_id,
                                unit_2_id: item.unit_2_id,
                                sales_channel_id: item.sales_channel_id,
                                campaign_id: item.campaign_id,
                                stock_currency: item.stock_currency,
                                stock_price: item.stock_price,
                                stock_rate: item.stock_rate,
                                sales_channel_name: item.sales_channel_name,
                                campaign_name: item.campaign_name,
                                vat_status_id: item.vat_status_id,
                                isPopover: false,
                                vendor_commission: item.vendor_commission,
                                vendor_commission_rate: item.vendor_commission_rate,
                            });
                        }); 

                        this.totals.totalAmount = response.data.offer_summary.totalAmount;
                        this.totals.grossAmount = response.data.offer_summary.grossAmount;
                        this.totals.netAmount = response.data.offer_summary.netAmount; 
                        this.totals.discountAmount = response.data.offer_summary.discountAmount; 
                        this.totals.taxAmount = response.data.offer_summary.taxAmount;
                        this.totals.totalAmountLocal = response.data.offer_summary.totalAmountLocal;
                        this.totals.grossAmountLocal = response.data.offer_summary.grossAmountLocal;
                        this.totals.netAmountLocal = response.data.offer_summary.netAmountLocal; 
                        this.totals.discountAmountLocal = response.data.offer_summary.discountAmountLocal; 
                        this.totals.taxAmountLocal = response.data.offer_summary.taxAmountLocal;

                        if(this.itemList.length > 0){
                            this.$setSessionStorage('tmp_session_id', this.itemList[0].tmp_session_id);
                        } 
                        this.$setSessionStorage('data'+ '_ITMLST', this.itemList);
                        this.$refs.closeModal.click();
                    }
                    this.isStockItemSaving = false;
                })
                .catch(function () {
                    this.isStockItemSaving = false;
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isStockItemSaving = false;
                        that.isModalButtonDisabled = false;
                    }, 500);
                }); 
            }
        }); 
    },
    changeView(activeView) {
        this.form.activeView = activeView;
    },
    onChangeVendorCommission(type) {
        if(type == 'rate') {
            this.stockForm.vendor_commission_rate = this.stockForm.vendor_commission_rate > 100 ? 100 : this.stockForm.vendor_commission_rate;
            this.stockForm.vendor_commission = (this.stockForm.gross_amount / 100) * this.stockForm.vendor_commission_rate;
        } else {
            this.stockForm.vendor_commission_rate = (this.stockForm.vendor_commission * 100) / this.stockForm.gross_amount;
        }
    },
    calculateDiscountTotals() {
        if(this.$getUser().currency_search_type == 1) {
            this.stockForm.price = this.stockForm.stock_price / this.stockForm.stock_rate;
        } else {
            this.stockForm.price = this.stockForm.stock_price * this.stockForm.stock_rate;
        }
        this.stockForm.total_price = this.stockForm.quantity1 * this.stockForm.price; 
        this.stockForm.net_price = this.stockForm.total_price - this.stockForm.discount_amount;
        this.stockForm.discount = 100 - ((this.stockForm.net_price * 100) / this.stockForm.total_price);
        
        if (this.stockForm.vat_status_id == 1) {
            this.stockForm.tax_rate = 0.00;
        } 
        if (this.stockForm.vat_status_id == 3) {            
            this.stockForm.net_price = this.stockForm.net_price / (1 + (this.stockForm.tax_rate / 100));
        }

        this.stockForm.tax_amount = (this.stockForm.net_price / 100) * this.stockForm.tax_rate;
        this.stockForm.gross_amount = (this.stockForm.net_price + this.stockForm.tax_amount);
        
        // Vendor varsa vendor komisyonu hesaplanir
        if(this.vendorDefaultList.length > 0) {
            this.onChangeVendorCommission('rate');
        }

        this.getLocalTotals();
    },
    calculateTotals(callback, exceptVatAmount) {
        if(this.$getUser().currency_search_type == 1) {
            this.stockForm.price = this.stockForm.stock_price / this.stockForm.stock_rate;
        } else {
            this.stockForm.price = this.stockForm.stock_price * this.stockForm.stock_rate;
        }
        this.stockForm.total_price = this.stockForm.quantity1 * this.stockForm.price;
        this.stockForm.discount_amount = (this.stockForm.total_price / 100) * this.stockForm.discount;
        if (this.stockForm.discount < 0) {
            this.stockForm.discount = this.form.discount;
            this.stockForm.discount_amount = (this.stockForm.price / 100) * this.form.discount;
        }
        this.stockForm.net_price = this.stockForm.total_price - this.stockForm.discount_amount;

        if (this.stockForm.vat_status_id == 3 && this.stockForm.account_type != 6) {            
            this.stockForm.net_price = this.stockForm.net_price / (1 + (this.stockForm.tax_rate / 100));
        }
        if(!exceptVatAmount){
             this.stockForm.tax_amount = (this.stockForm.net_price / 100) * this.stockForm.tax_rate;
        } 

        if (this.stockForm.vat_status_id == 3 && this.stockForm.account_type == 6) {            
            this.stockForm.net_price = this.stockForm.net_price - this.stockForm.tax_amount;
        }

        this.stockForm.gross_amount = (this.stockForm.net_price + this.stockForm.tax_amount);
        
        // Vendor varsa vendor komisyonu hesaplanir
        if(this.vendorDefaultList.length > 0) {
            this.onChangeVendorCommission('rate');
        }

        this.getLocalTotals(function() {
            if(typeof callback == 'function'){
                callback();
            }
        });
    },
    getLocalTotals(callback) {
        if(this.$getUser().currency_search_type == 1) {
            this.stockForm.price_local = this.stockForm.price / this.form.rate;
        } else {
            this.stockForm.price_local = this.stockForm.price * this.form.rate;
        }
        this.stockForm.total_price_local = this.stockForm.quantity1 * this.stockForm.price_local;

        this.stockForm.discount_amount_local = (this.stockForm.total_price_local / 100) * this.stockForm.discount;        
        if (this.stockForm.discount < 0) {
            this.stockForm.discount = this.form.discount;
            this.stockForm.discount_amount_local = (this.stockForm.price_local / 100) * this.form.discount;
        }
        this.stockForm.net_price_local = this.stockForm.total_price_local - this.stockForm.discount_amount_local;
        
        if (this.stockForm.vat_status_id == 1) {
            this.stockForm.tax_rate = 0.00;
        } 
        if (this.stockForm.vat_status_id == 3) {            
            this.stockForm.net_price_local = this.stockForm.net_price_local / (1 + (this.stockForm.tax_rate / 100));
        }

        this.stockForm.tax_amount_local = (this.stockForm.net_price_local / 100) * this.stockForm.tax_rate;
        this.stockForm.gross_amount_local = (this.stockForm.net_price_local + this.stockForm.tax_amount_local);
        
        if(typeof callback == 'function'){
            callback();
        }
    },
    getStockTrackingGroups(){
        this.stockTrackingListItems = [];
        const parameters = { 
        session_id: this.$getUser().session_id,
        account_id: this.stockForm.stock_account_id
        }; 
        axios.post('/api/StockTrackingGroups/GetStockTrackingGroupsItemsSelected', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.stockTrackingListItems = response.data;
        }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    getTotals() {
        if(this.$getSessionStorage('data'+ '_ITMLST') !== null && this.$getSessionStorage('data' + '_ITMLST') !== undefined && this.$getSessionStorage('data' + '_ITMLST') !== ''){
            this.itemList = this.$getSessionStorage('data'+ '_ITMLST');
            this.totals.totalAmount = 0;
            this.totals.grossAmount = 0;
            this.totals.netAmount = 0; 
            this.totals.discountAmount = 0; 
            this.totals.taxAmount = 0; 
            this.totals.totalAmountLocal = 0;
            this.totals.grossAmountLocal = 0;
            this.totals.netAmountLocal = 0; 
            this.totals.discountAmountLocal = 0; 
            this.totals.taxAmountLocal = 0; 
        }
    },
    deleteAllItems(){ 
        this.$swal.fire({
            title: "Are you sure you want to delete all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE ALL (!)",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const params = { 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                }; 
                axios.post('api/Offer/DeleteAllOfferItems', params, {'Content-Type': 'application/json'})
                    .then(() => { 
                        this.itemList = [];  
                        this.$removeSessionStorage('data'+ '_ITMLST');  
                    })
                    .catch(function () {
                    });   
                this.$setSessionStorage('data'+ '_ITMLST', this.itemList); 
                this.getTotals();
            }
        }); 
    },
    deleteItem() {
        this.$swal.fire({
            title: "Are you sure you want to delete this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.itemList.forEach((item) => {
                    if(item.isSelected) {
                        this.$Progress.start(); 
                        const params = {
                            id: item.id,
                            session_id: this.$getUser().session_id,
                            tmp_session_id: this.$getSessionStorage('tmp_session_id')
                        }; 
                        axios.post('api/Offer/DeleteOfferItem', params, {'Content-Type': 'application/json'})
                        .then((response) => {
                            this.itemList = [];
                            response.data.offer_items.forEach((tmpItem) => { 
                                this.itemList.push({
                                    created_at: tmpItem.created_at,
                                    description: tmpItem.description,
                                    discount: tmpItem.discount,
                                    discount_amount: tmpItem.discount_amount,
                                    discount_amount_local: tmpItem.discount_amount_local,
                                    gross_amount: tmpItem.gross_amount,
                                    gross_amount_local: tmpItem.gross_amount_local,
                                    id: tmpItem.id,
                                    net_price: tmpItem.net_price,
                                    net_price_local: tmpItem.net_price_local,
                                    price: tmpItem.price,
                                    price_local: tmpItem.price_local,
                                    quantity1: tmpItem.quantity1,
                                    quantity2: tmpItem.quantity2,
                                    session_id: tmpItem.session_id,
                                    stock_account_id: tmpItem.stock_account_id,
                                    stock_account_code: tmpItem.stock_account_code,
                                    stock_account_name: tmpItem.stock_account_name,
                                    tax_amount: tmpItem.tax_amount,
                                    tax_amount_local: tmpItem.tax_amount_local,
                                    tax_rate: tmpItem.tax_rate,
                                    tmp_session_id: tmpItem.tmp_session_id,
                                    total_price: tmpItem.total_price,
                                    total_price_local: tmpItem.total_price_local,
                                    unit_name: tmpItem.unit_name,
                                    unit_2_name: tmpItem.unit_2_name,
                                    unit_id: tmpItem.unit_id,
                                    unit_2_id: tmpItem.unit_2_id,
                                    sales_channel_id: tmpItem.sales_channel_id,
                                    campaign_id: tmpItem.campaign_id,
                                    stock_currency: tmpItem.stock_currency,
                                    stock_price: tmpItem.stock_price,
                                    stock_rate: tmpItem.stock_rate,
                                    sales_channel_name: tmpItem.sales_channel_name,
                                    campaign_name: tmpItem.campaign_name,
                                    vat_status_id: tmpItem.vat_status_id,
                                    isPopover: false,
                                    vendor_commission: item.vendor_commission,
                                    vendor_commission_rate: item.vendor_commission_rate,
                                });
                            });  

                            this.totals.totalAmount = response.data.offer_summary.totalAmount;
                            this.totals.grossAmount = response.data.offer_summary.grossAmount;
                            this.totals.netAmount = response.data.offer_summary.netAmount; 
                            this.totals.discountAmount = response.data.offer_summary.discountAmount; 
                            this.totals.taxAmount = response.data.offer_summary.taxAmount;
                            this.totals.totalAmountLocal = response.data.offer_summary.totalAmountLocal;
                            this.totals.grossAmountLocal = response.data.offer_summary.grossAmountLocal;
                            this.totals.netAmountLocal = response.data.offer_summary.netAmountLocal; 
                            this.totals.discountAmountLocal = response.data.offer_summary.discountAmountLocal; 
                            this.totals.taxAmountLocal = response.data.offer_summary.taxAmountLocal;

                            if(this.itemList.length > 0){
                                this.$setSessionStorage('tmp_session_id', this.itemList[0].tmp_session_id);
                            }

                            this.$setSessionStorage('data'+ '_ITMLST', this.itemList);  
                            this.$Progress.finish(); 
                        })
                        .catch(function () {
                            this.$Progress.finish(); 
                        });
                    }
                }) 
                this.$setSessionStorage('data'+ '_ITMLST', this.itemList); 
                this.getTotals();
            }
        });
    },
    getRowNumber(data) {
      return data.rowIndex + 1;
    },

    onOpenUpdateAccount(item){
        let accountInfo = {
            id: (item !== null && item !== undefined && item !== '') ? item.id : this.form.account_id, 
        };
        this.$refs.UpdateAccount.getData(accountInfo);
    },
    updateOffer(){ 
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.$swal.fire({
            title: "Are you sure you want to update this Offer?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "UPDATE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.$Progress.start();
                this.isOfferProgressing = true;
                const that = this;
                const params = { 
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    offer_type: this.form.offer_type,
                    account_id: this.form.account_id,
                    sales_channel_id: this.form.sales_channel_id,
                    branch_id: this.form.branch_id,
                    campaign_id: this.form.campaign_id,
                    offer_date: this.form.offer_date,
                    offer_no: this.form.offer_no,
                    currency: this.form.currency,
                    option_date: this.form.option_date,
                    payment_id: this.form.payment_id,
                    shipping_id: this.form.shipping_id,
                    rate: (this.form.rate !== null && this.form.rate !== '' && this.form.rate !== undefined) ? this.form.rate : 0,
                    discount: (this.form.discount !== null && this.form.discount !== '' && this.form.discount !== undefined) ? this.form.discount : 0,
                    sales_type: this.form.sales_type,
                    process_type: this.form.process_type
                }; 

                if(params.sales_channel_id == '-1'){ params.sales_channel_id = null;}
                if(params.branch_id == '-1'){ params.branch_id = null;}
                if(params.campaign_id == '-1'){ params.campaign_id = null;}
                if(params.payment_id == '-1'){ params.payment_id = null;}
                if(params.shipping_id == '-1'){ params.shipping_id = null;}
                
                // Timezone bilgisini tarihten yok ediyoruz
                params.offer_date = moment(params.offer_date).format('YYYY-MM-DD[T]HH:mm:ss');
                params.option_date = moment(params.option_date).format('YYYY-MM-DD[T]HH:mm:ss');

                axios.post('api/Offer/UpdateOffer', params, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                            this.$removeSessionStorage('tmp_session_id');
                            this.$setSessionStorage('refreshList', true); 
                            this.getData();
                            this.$swal("Success", "Updated succesfully..!", 'success');
                        } 
                        this.$Progress.finish(); 
                    })
                    .catch(function () { 
                        this.$Progress.finish(); 
                        new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
                    })
                    .finally(function () { 
                        setTimeout(() => {
                            that.isOfferProgressing = false;
                            that.$Progress.finish();
                        }, 500);
                    });   
            }
        });
    },
    getIfOfferProgressing(){
        return this.isOfferProgressing;
    },
    deleteOffer(){
        this.$swal.fire({
            title: "Are you sure you want to delete this Offer?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            icon: 'error'
        })
        .then((result) => {
            if (result.isConfirmed) {
                
            this.$Progress.start();
            const params = { 
                id: this.$getSessionStorage('record_id'),
                session_id: this.$getUser().session_id
            }
            axios.post('api/Offer/DeleteOffer', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$setSessionStorage('refreshList', true);
                        this.$router.push({ name: 'OfferCreate' });
                    } 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                });   
            }
        });
    },
    onChangeVatStatus() {
        if (this.stockForm.vat_status_id == 1) {
            this.stockForm.tax_rate = 0.00;
            this.stockForm.tax_amount = 0.00;
        } else {
            this.stockForm.tax_rate = this.stockForm.stock_price_tax_rate;
        }
        this.calculateTotals(function(){
        });
    },
    onChangeStockCurrency() {
        const isCurrencyExist = this.stockPricesList.find(item => item.id !== '-1' && item.id == this.stockForm.stock_currency_id);
        if (isCurrencyExist) {
            this.stockForm.stock_currency = isCurrencyExist.currency;
            if (this.form.offer_type == 2) {
                this.stockForm.stock_price = isCurrencyExist.sales_price;
                this.stockForm.price = isCurrencyExist.sales_price;
                this.stockForm.tax_rate = (isCurrencyExist.sales_tax_amount == null || isCurrencyExist.sales_tax_amount == '') ? 0.00 : parseFloat(isCurrencyExist.sales_tax_amount);
                this.stockForm.stock_price_tax_rate = (isCurrencyExist.sales_tax_amount == null || isCurrencyExist.sales_tax_amount == '') ? 0.00 : parseFloat(isCurrencyExist.sales_tax_amount);
            }
            if (this.form.offer_type == 1) {
                this.stockForm.stock_price = isCurrencyExist.purchase_price;
                this.stockForm.price = isCurrencyExist.purchase_price;
                this.stockForm.tax_rate = (isCurrencyExist.purchase_tax_amount == null || isCurrencyExist.purchase_tax_amount == '') ? 0.00 : parseFloat(isCurrencyExist.purchase_tax_amount);
                this.stockForm.stock_price_tax_rate = (isCurrencyExist.purchase_tax_amount == null || isCurrencyExist.purchase_tax_amount == '') ? 0.00 : parseFloat(isCurrencyExist.purchase_tax_amount);
            }
            /* Stok içerisinden gelen price için VAT (None) seçildiyse Tax Rate sıfırlanır  */
            if (this.stockForm.vat_status_id == 1) {
                this.stockForm.tax_rate = 0.00;
            }
            if (this.form.isVatActive == false) {
                this.stockForm.vat_status_id = 1;
                this.stockForm.tax_rate = 0.00;
            }
            
            this.calculateTotals(function(){});
        } else {
            this.clearStockForm();
        }

        // Farkli bir para birimi secildiyse rate bulunur
        if(this.form.currency !== this.stockForm.stock_currency && this.stockForm.stock_currency !== null) {
            axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
            .then((typeResponse) => {
                this.typeList = typeResponse.data;
                this.typeList.forEach((item) => { 
                    if(item.is_default == 1){
                        this.typeSelection = item.id;
                        this.rateType = item.type;
                    }
                });
                const params = {
                    session_id: this.$getUser().session_id,
                    rate_date: moment(this.form.offer_date),
                    bank_id: !this.rateType ? 0 : this.rateType,// 1: European Bank 2: HMRC 3: Live Rate
                    currency: this.stockForm.stock_currency
                }; 
                params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.length > 0){
                        this.stockForm.stock_rate = parseFloat(response.data[0].rate.replace(',', '.'));
                        this.stockForm.rate_date = response.data[0].rate_date;
                    }
                    else{
                        this.stockForm.stock_rate = 1;
                        this.stockForm.rate_date = null;
                    }
                    this.calculateTotals(function(){
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
            })
            .catch(function (error) {
                console.log(error); 
            });
        } else {
            this.stockForm.stock_rate = 1;
            this.stockForm.rate_date = null;
            this.calculateTotals(function(){
            });
        }
    },    
    onChangeTotalsCurrency(type, value) {
        if (type == 'stockModal') {
            this.totalsCardView = value;
        }

        if (type == 'grandTotal') {
            this.grandTotalsCardView = value;
        }
    },
    addStockItemByStockCode() {
        if(this.stock_qr_code == null || this.stock_qr_code == '' || this.stock_qr_code == undefined) {return;}  
        this.stockForm.stock_account_id = '-1';
        this.stockForm.stock_account_name = null;
        this.stockForm.stock_account_code = null;
        this.stockForm.stock_stock_group_id = null;
        this.stockForm.stock_product_group_id = null;
        this.stockForm.stock_unit_id = null;
        this.stockForm.stock_unit_name = null;
        this.stockForm.stock_unit_2_name = null;
        this.stockForm.stock_unit_2_id = null; 

        this.stockForm.quantity1 = 1;
        this.stockForm.quantity2 = 0.00;
        this.stockForm.price = 0.00;
        this.stockForm.total_price = 0.00;
        this.stockForm.discount = 0.00;
        this.stockForm.discount_amount = 0.00;
        this.stockForm.net_price = 0.00;
        this.stockForm.tax_rate = 0.00;
        this.stockForm.tax_amount = 0.00;
        this.stockForm.gross_amount = 0.00;
        this.stockForm.description = null;
        this.stockForm.sales_channel_id = this.form.sales_channel_id;
        this.stockForm.campaign_id = this.form.campaign_id;
        this.stockForm.price_local = 0.00;
        this.stockForm.stock_price = 0.00;
        this.stockForm.stock_rate = 1.00000;
        this.stockForm.total_price_local = 0.00;
        this.stockForm.discount_amount_local = 0.00;
        this.stockForm.net_price_local = 0.00;
        this.stockForm.tax_amount_local = 0.00;
        this.stockForm.gross_amount_local = 0.00;
        this.stockForm.stock_currency_id = '-1';
        this.stockForm.stock_currency = null;
        const params = {
            code: this.stock_qr_code,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountDetailByAccountId', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if (response.data.account_type == 7) {
                this.stockForm.stock_account_id = response.data.id;
                this.stockForm.stock_account_name = response.data.name;
                this.stockForm.account_type = response.data.account_type;
                this.stockForm.stock_account_code = response.data.account_id;
                this.stockForm.qr_code = response.data.qr_code;
                this.stockForm.notes = response.data.notes;
                this.stockForm.stock_stock_group_id = response.data.stock_stock_group_id;
                this.stockForm.stock_product_group_id = response.data.stock_product_group_id;
                this.stockForm.stock_unit_id = response.data.stock_unit_id;
                this.stockForm.stock_unit_2_id = response.data.stock_unit_2_id;
                this.stockForm.stock_unit_name = response.data.stock_unit_name;
                this.stockForm.stock_unit_2_name = response.data.stock_unit_2_name;
                this.stockForm.price = 0.00;
                this.stockForm.tax_rate = 0.00;
                this.stockForm.stock_rate = 1.00000;
                this.stockForm.discount = this.form.discount;
                this.stockForm.discount_amount = this.form.discount_amount;
                this.stockForm.stock_currency = null;
                this.stockForm.stock_currency_id = '-1';
                this.stockForm.stock_price = 0.00;

                this.stockPricesList = [];
                const params = {
                    stock_account_id: this.stockForm.stock_account_id,
                    session_id: this.$getUser().session_id
                };
                axios.post("/api/CompanyAccounts/GetStockCurrencies", params, {'Content-Type': 'application/json'}).then((stockPriceResponse) => {
                    this.stockPricesList = stockPriceResponse.data;
                    this.stockPricesList.forEach((item) => {
                        item.prefix = item.price_type_name !== '' && item.price_type_name !== null ? ' - ' : '';
                        if (this.form.offer_type == 2) {
                            item.text = item.currency + ' (' + item.sales_price + ')' + item.prefix + item.price_type_name;
                        }
                        if (this.form.offer_type == 1) {
                            item.text = item.currency + ' (' + item.purchase_price + ')' + item.prefix + item.price_type_name;
                        }
                        item.sales_price = item.sales_price.replace(/,/g, '');
                        item.sales_tax_amount = item.sales_tax_amount.replace(/,/g, '');
                        item.purchase_price = item.purchase_price.replace(/,/g, '');
                        item.purchase_tax_amount = item.purchase_tax_amount.replace(/,/g, '');     
                    });

                    const defaultStockPrice = this.stockPricesList.find(item => item.currency == this.form.currency && item.is_default);
                    if (defaultStockPrice) {
                        this.stockForm.stock_currency_id = defaultStockPrice.id;
                        this.stockForm.stock_currency = defaultStockPrice.currency;
                        if (this.form.offer_type == 2) {
                            this.stockForm.stock_price = defaultStockPrice.sales_price !== '' ? parseFloat(defaultStockPrice.sales_price) : 0.00;
                            this.stockForm.price = defaultStockPrice.sales_price !== '' ? parseFloat(defaultStockPrice.sales_price) : 0.00;
                            this.stockForm.tax_rate = (defaultStockPrice.sales_tax_amount !== '' && defaultStockPrice.sales_tax_amount !== null) ? parseFloat(defaultStockPrice.sales_tax_amount) : 0.00;
                            this.stockForm.stock_price_tax_rate = (defaultStockPrice.sales_tax_amount !== '' && defaultStockPrice.sales_tax_amount !== null) ? parseFloat(defaultStockPrice.sales_tax_amount) : 0.00;
                        }
                        if (this.form.offer_type == 1) {
                            this.stockForm.stock_price = defaultStockPrice.purchase_price !== '' ? parseFloat(defaultStockPrice.purchase_price) : 0.00;
                            this.stockForm.price = defaultStockPrice.purchase_price !== '' ? parseFloat(defaultStockPrice.purchase_price) : 0.00;
                            this.stockForm.tax_rate = (defaultStockPrice.purchase_tax_amount !== null && defaultStockPrice.purchase_tax_amount !== '') ? parseFloat(defaultStockPrice.purchase_tax_amount) : 0.00;
                            this.stockForm.stock_price_tax_rate = (defaultStockPrice.purchase_tax_amount !== null && defaultStockPrice.purchase_tax_amount !== '') ? parseFloat(defaultStockPrice.purchase_tax_amount) : 0.00;
                        }
                        /* Stok içerisinden gelen price için VAT (None) seçildiyse Tax Rate sıfırlanır  */
                        if (this.stockForm.vat_status_id == 1) {
                            this.stockForm.tax_rate = 0.00;
                        }
                        if (this.form.isVatActive == false) {
                            this.stockForm.vat_status_id = 1;
                            this.stockForm.tax_rate = 0.00;
                        }
                    } else {
                        this.clearStockForm();
                    }
                    this.stockPricesList.unshift({ id: '-1', text: 'Select an option'});                    
                    if(this.$getUser().currency_search_type == 1) {
                        this.stockForm.price = this.stockForm.stock_price / this.stockForm.stock_rate;
                    } else {
                        this.stockForm.price = this.stockForm.stock_price * this.stockForm.stock_rate;
                    }
                    this.stockForm.total_price = this.stockForm.quantity1 * this.stockForm.price;

                    this.stockForm.discount_amount = (this.stockForm.total_price / 100) * this.stockForm.discount;        
                    if (this.stockForm.discount < 0) {
                        this.stockForm.discount = this.form.discount;
                        this.stockForm.discount_amount = (this.stockForm.price / 100) * this.form.discount;
                    }
                    this.stockForm.net_price = this.stockForm.total_price - this.stockForm.discount_amount;
                    
                    if (this.stockForm.vat_status_id == 1) {
                        this.stockForm.tax_rate = 0.00;
                    } 
                    if (this.stockForm.vat_status_id == 3) {            
                        this.stockForm.net_price = this.stockForm.net_price / (1 + (this.stockForm.tax_rate / 100));
                    }

                    this.stockForm.tax_amount = (this.stockForm.net_price / 100) * this.stockForm.tax_rate;
                    this.stockForm.gross_amount = (this.stockForm.net_price + this.stockForm.tax_amount);

                    if(this.$getUser().currency_search_type == 1) {
                        this.stockForm.price_local = this.stockForm.price / this.form.rate;
                    } else {
                        this.stockForm.price_local = this.stockForm.price * this.form.rate;
                    }
                    this.stockForm.total_price_local = this.stockForm.quantity1 * this.stockForm.price_local;

                    this.stockForm.discount_amount_local = (this.stockForm.total_price_local / 100) * this.stockForm.discount;        
                    if (this.stockForm.discount < 0) {
                        this.stockForm.discount = this.form.discount;
                        this.stockForm.discount_amount_local = (this.stockForm.price_local / 100) * this.form.discount;
                    }
                    this.stockForm.net_price_local = this.stockForm.total_price_local - this.stockForm.discount_amount_local;
                    if (this.stockForm.vat_status_id == 3) {            
                        this.stockForm.net_price_local = this.stockForm.net_price_local / (1 + (this.stockForm.tax_rate / 100));
                    }
                    this.stockForm.tax_amount_local = (this.stockForm.net_price_local / 100) * this.stockForm.tax_rate;
                    this.stockForm.gross_amount_local = (this.stockForm.net_price_local + this.stockForm.tax_amount_local);

                    this.saveData(true);
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                this.$swal("Warning", "Stock code not found..!", 'warning');
            }
        })
        .catch(function (error) {
            console.log(error);
        });  
    },
    onChangeRateType(){
        const params = {
            session_id: this.$getUser().session_id,
            rate_date: moment(this.form.offer_date),
            bank_id: 0,
            currency: this.form.currency
        };
        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.length > 0){
                this.rateList = response.data;
                this.form.rate_list_id = response.data[0].id;
                this.form.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                this.form.rate_date = response.data[0].rate_date;
            }
            else{
                this.form.rate = 1;
                this.form.rate_date = null; 
                this.rateList = null;
                this.rateList = 
                [{
                    id: '1',
                    text: '1,0000'
                }];
                this.form.rate_list_id = 1;
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    onChangeCurrency(){
        this.form.rate = 1;
        if (this.form.currency == this.localCurrency) {return;}

        axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
        .then((typeResponse) => {
                this.typeList = typeResponse.data;
                this.typeList.forEach((item) => { 
                    if(item.is_default == 1){
                        this.typeSelection = item.id;
                    }
                });  

                this.$Progress.start();
                const params = {
                    session_id: this.$getUser().session_id,
                    rate_date: moment(this.form.offer_date),
                    bank_id: 1, //EUROPEAN BANK
                    currency: this.stockForm.stock_currency
                }; 
                params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                var source = this.typeList.find((element) => element.id == this.typeSelection);
                params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
                axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.length > 0){
                        this.rateList = response.data;
                        this.form.rate_list_id = response.data[0].id;
                        this.form.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                        this.form.rate_date = response.data[0].rate_date;
                    }
                    else{
                        this.form.rate = 1;
                        this.form.rate_date = null;
                        this.rateList = [{
                            id: '1',
                            text: '1,0000'
                        }];
                        this.form.rate_list_id = 1;
                    }
                    this.$Progress.finish();
                })
                .catch(function (error) {
                    console.log(error);
                    this.$Progress.finish();
                });  

        })
        .catch(function (error) {
            console.log(error); 
        }); 
    },
    onChangeOfferDate(){
        if (this.selectedAccount.payment_value !== null && this.selectedAccount.payment_value !== '' && this.selectedAccount.payment_value !== undefined && this.selectedAccount.payment_value != 0) {
            this.form.option_date = moment(this.form.offer_date, "DD-MM-YYYY HH:mm:ss").add(parseInt(this.selectedAccount.payment_value), 'days');
        } else {            
            this.form.option_date = this.form.offer_date;
        }
        if (this.form.currency !== this.localCurrency) {
            this.onChangeCurrency();
        }
    },
    clearStockForm() {
        this.stockForm.stock_currency_id = '-1';
        this.stockForm.stock_currency = null;
        this.stockForm.stock_price = 0.00;
        this.stockForm.stock_rate = 1.00000;
        this.stockForm.price = 0.00;
        this.stockForm.tax_rate = 0.00;
        this.stockForm.quantity1 = 1;
        this.stockForm.quantity2 = 0.00;
        this.stockForm.total_price = 0.00;
        // this.stockForm.discount = 0.00;
        // this.stockForm.discount_amount = 0.00;
        this.stockForm.net_price = 0.00;
        this.stockForm.tax_amount = 0.00;
        this.stockForm.gross_amount = 0.00;
        this.stockForm.price_local = 0.00;
        this.stockForm.total_price_local = 0.00;
        this.stockForm.discount_amount_local = 0.00;
        this.stockForm.net_price_local = 0.00;
        this.stockForm.tax_amount_local = 0.00;
        this.stockForm.gross_amount_local = 0.00;
        this.stockForm.vendor_commission = 0.00;
        this.stockForm.vendor_commission_rate = 0;
    },
    openCreateStockModal() {
        this.$refs.AddNewStockButton.click();
        this.$refs.AddNewStock.getData();
    },
    openCreateAssetModal() {
        this.$refs.AddNewAssetButton.click();
        this.$refs.AddNewAsset.getData();
    },
    activateDocumentsSection(){
        this.activeTab = 'Documents'; 
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/OfferHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    printOffer(){
        if(!this.getSubCredentials('P')) return;
        this.$refs.PrintModal.isPrintModalOpen = true;
        this.$refs.PrintModal.isFrameLoading = true;
        this.$refs.PrintModal.documentName = this.form.account_name.substring(0, 10) + '-' + this.getOfferName;
        this.$refs.PrintModal.getData();
    },
    downloadOffer(){
        if(!this.getSubCredentials('P')) return;
        const parameters = {
            session_id: this.$getUser().session_id,
            category: 5
        };
        axios.post('/api/Reports/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'}).then((responseReport) => { 
            this.designList = responseReport.data.report_designs;
            this.designSelection = responseReport.data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.reportType = item.category;
                }
            });

            if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
            {
                fetch('https://localhost:5001/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.reportType)
                    .then(response => response.blob())
                    .then(data => {
                        saveAs(data, (this.form.account_name.substring(0, 10) + '-' + this.getOfferName) + '.pdf');
                    });
            }
            else{
                fetch('/reports/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.reportType)
                    .then(response => response.blob())
                    .then(data => {
                        saveAs(data, (this.form.account_name.substring(0, 10) + '-' + this.getOfferName) + '.pdf');
                    });
            } 
        })
        .catch(function (error) {
            console.log(error); 
        });
    },    
    openSearchModal(searchText) {
        this.$refs.SearchStockAccountWithSelect.getData(7, this.form.process_type, this.form.offer_type, searchText, true);
    },
    onSuccessNewAccount(account_id) {
        const modalButton = this.$refs.openStockItemModal;
        modalButton.click();
        this.getAccountDetail(account_id);
    },
    onCancelIntegration() {
        this.$swal.fire({
            title: "Are you sure you want to cancel accounting integration?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const params = {
                    session_id: this.$getUser().session_id,
                    header_id: this.$getSessionStorage('record_id')
                };
                axios.post('api/AccountingIntegration/CancelSingleIntegration', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$swal("Success", "Accounting Integration canceled..!", 'success');
                        this.getData();
                    }
                })
                .catch(function (error) { 
                    console.log(error)
                });  
            }
        });
    },
    onOpenTransactionListModal() {
        this.$refs.TransactionListModal.getData();
    },
    onViewOperations() {
         this.operationList = [];
        const queryParameter = {
            session_id: this.$getUser().session_id,
            offer_header_id: this.$getSessionStorage('record_id')
        };   
        axios.post('/api/Offer/GetPositions', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => {
            this.operationList = response.data;
        })
        .catch(function (error) {
            console.error(error);
        });
    },
    sendOfferByMail(){
        this.$swal({
            title: 'Your offer document will be sent to you via email. Do you confirm?',
            icon:'info',
            showCancelButton: true,
            confirmButtonText: 'SEND',
            confirmButtonColor: "#50cd89",
        }).then((result)=>{
            if(result.isConfirmed) {
                this.$refs.closeSendMailModal.click();
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: this.$getSessionStorage('record_id'),
                    file_name: this.form.account_name.substring(0, 10) + '-' + this.getOfferName,
                    category: 5
                };  
                axios.post('/api/Offer/SendDocumentByMail', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$swal("Success", "Email sent successfully.", 'success');
                    }
                }).catch(function () {
                    this.$swal("Failed", "An error occurred while sending email. You should check your Mail Settings in Settings menu.", 'error');
                });        
            }
        });
       
    },
    setSummaries(options){
        if(options.name == "t_stock_price_total_in"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var stockPriceTotalInValue = parseFloat(options.value.stock_price_total_in.replaceAll(',', ''));
                (options.totalValue += isNaN(stockPriceTotalInValue) ? 0 : stockPriceTotalInValue);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
        if(options.name == "t_stock_price_total_out"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var stockPriceTotalOutValue = parseFloat(options.value.stock_price_total_out.replaceAll(',', ''));
                (options.totalValue += isNaN(stockPriceTotalOutValue) ? 0 : stockPriceTotalOutValue);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
        if(options.name == "t_profit"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var profitTotalValue = parseFloat(options.value.profit.replaceAll(',', ''));
                (options.totalValue += isNaN(profitTotalValue) ? 0 : profitTotalValue);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
        if(options.name == "t_quantity"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var quantityTotalValue = parseFloat(options.value.quantity.replaceAll(',', ''));
                (options.totalValue += isNaN(quantityTotalValue) ? 0 : quantityTotalValue);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
    },
    onOpenAccountReport(){
        this.$refs.AccountReports.getData();
        this.$refs.AccountReports.setModalCriteriasToDefault();
    },
    onClickSearchButton() {
        this.$refs.searchStockButton.click();
    },
    openAddVendorModal(){
        this.$refs.AddDefaultVendor.getData();
    },
    deleteVendorItem(){
        let vendorId = null;
        this.vendorDefaultList.forEach((item) => {
            if (item.isSelected) {
                vendorId = item.vendor_id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    vendor_id: vendorId,
                    is_delete_all: 0
                }; 
                axios.post('/api/Insurance/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorDefaultList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllVendors(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    is_delete_all: 1,
                    vendor_id: null
                }; 
                axios.post('/api/Insurance/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorDefaultList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    saveAsInvoice() {
        this.$swal.fire({
            title: "Are you sure you want to save prepare this offer as an Invoice? If you have unsaved changes, please update this offer first.",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$router.push({ name: 'InvoiceCreate', params: { offer_id: this.$getSessionStorage('record_id') } });
            }
        });
    },
    isValidInput() {
    return !(this.positionForm.document_no == null || this.positionForm.document_no == '' || this.positionForm.document_no == undefined ||
             this.positionForm.invoice_no == null || this.positionForm.invoice_no == '' || this.positionForm.invoice_no == undefined ||
             this.positionForm.description == null || this.positionForm.description == '' || this.positionForm.description == undefined ||
             this.positionForm.is_invoice_arranged == null || this.positionForm.is_invoice_arranged == '' || this.positionForm.is_invoice_arranged == undefined);
    },
    onOpenUpdatePositionModal() {
        this.positionForm = {            
            position: 1,
            position_date: moment(),
            document_no: null,
            invoice_no: null,
            description: null,
            is_invoice_arranged: false,
        };
        const parameters = {
          session_id: this.$getUser().session_id,
          offer_header_id: this.$getSessionStorage('record_id')
        };  
        axios.post('/api/Offer/PositionInvoiceStatus', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.positionForm.invoice_no = response.data.invoice_no;
              this.positionForm.is_invoice_arranged = response.data.is_invoice_arranged;
           }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    savePosition(){
        const params = {
            session_id: this.$getUser().session_id,
            offer_header_id: this.$getSessionStorage('record_id'),
            position: this.positionForm.position,
            position_date: this.positionForm.position_date,
            document_no: this.positionForm.document_no,
            invoice_no: this.positionForm.invoice_no,
            description: this.positionForm.description,
            is_invoice_arranged: this.positionForm.is_invoice_arranged ? 1 : 0,
        } 
        params.position_date = moment(params.position_date).format('YYYY-MM-DD[T]HH:mm:ss');
        axios.post("api/Offer/CreatePosition",params,{'Content-Type' : 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.form.position = response.data.position;
                this.form.position_name = response.data.position_name;
                this.form.position_date = response.data.position_date;
                this.form.document_no = response.data.document_no;
                this.form.invoice_no = response.data.invoice_no;
                this.form.description = response.data.description;
                this.form.is_invoice_arranged = response.data.is_invoice_arranged;

                if(response.data.position == 4) this.form.is_active = 0;
                this.$swal("Success", "Saved succesfully..!", 'success');
                this.$refs.closeUpdatePositionModal.click();
            }
        }).catch(function (error) {
            console.log(error);
        });
    },
    onChangeOfferStatus() {
        var isActive = this.form.is_active == 1 ? 0 : 1;
        this.$swal.fire({
            title: isActive ? "Are you sure you want to save deactivate this offer?" : "Are you sure you want to save activate this offer?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                  session_id: this.$getUser().session_id,
                  id: this.$getSessionStorage('record_id'),
                  is_active: isActive
                };  
                axios.post('/api/Offer/UpdateOfferStatus', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.form.is_active = response.data.is_active;                      
                        this.form.position = response.data.position;
                        this.form.position_name = response.data.position_name;
                        this.form.position_date = response.data.position_date;
                        this.form.document_no = response.data.document_no;
                        this.form.invoice_no = response.data.invoice_no;
                        this.form.description = response.data.description;
                        this.form.is_invoice_arranged = response.data.is_invoice_arranged;
                    }
                })
                .catch(function (error) {
                   console.log(error);
                });
            }
        });
    },
    getScheduledTransactions() {
        const parameters = {
          session_id: this.$getUser().session_id,
          offer_header_id: this.$getSessionStorage('record_id'),
          transaction_type : 1
        };  
        axios.post('/api/Offer/GetScheduledTransactions', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.scheduledTransactions = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    saveScheduledTransaction() {
        const parameters = {
            session_id: this.$getUser().session_id,
            transaction_id: this.$getSessionStorage('record_id'),
            period_type: this.scheduleForm.period_type,
            transaction_date: this.scheduleForm.transaction_date,
            period_count: this.scheduleForm.period_count,
            transaction_type: 1,  //offer type
            description : this.scheduleForm.description
        };  
        parameters.transaction_date = moment(parameters.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss');        
        this.isSaving = true;
        const that = this;
        axios.post('/api/Offer/CreateScheduledTransactions', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.scheduledTransactions = response.data;
            this.$refs.closeScheduleModal.click();
           }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isSaving = false;
            }, 500);
        });
    },
    onOpenScheduleModal() {
        this.scheduleForm = {
            period_type: 1,
            transaction_date: moment(),
            period_count: 1,
            description:null
        };
    },
    setScheduleStatus(newData, value) {
        newData.status = value;
        let status_name = null;
        switch(value) {
            case 0:
                status_name = "Pending"
                break;
            case 1:
                status_name = "Canceled"
                break;
            case 2:
                status_name = "Completed"
                break;
            case 3:
                status_name = "Failed"
                break;
            default: status_name = "";
        }
        newData.status_name = status_name;
    },
    updateScheduledTransaction(e, isCancelAll) {
        if(isCancelAll) {
            this.$swal.fire({
                title: "Are you sure you want to cancel all pending transactions?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        event_id: null,
                        transaction_id: this.$getSessionStorage('record_id'),
                        transaction_date: null,
                        status: 0,
                        is_cancel_all: 1,
                        transaction_type: 1,
                        description:null
                    };
                    axios.post('/api/Offer/UpdateScheduledTransactions', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.getScheduledTransactions();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            });
        } else {
            const parameters = {
                session_id: this.$getUser().session_id,
                event_id: e.oldData.id,
                transaction_id: this.$getSessionStorage('record_id'),
                transaction_date: e.newData.transaction_date ? e.newData.transaction_date  : e.oldData.transaction_date,
                status: e.newData.status ? e.newData.status  : e.oldData.status,
                is_cancel_all: 0,
                transaction_type: 1,
                description: e.newData.description ? e.newData.description : e.oldData.description,
            };
            parameters.transaction_date = moment(parameters.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss');
            axios.post('/api/Offer/UpdateScheduledTransactions', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.getScheduledTransactions();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    },
    onEditingStart(e) {        
        this.scheduleStatus = e.data.status;
        if (e.data.status_name !== 'Pending') {
            e.cancel = true;  
        }
    }
  },
  created() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$setSessionStorage('report_category', 5);
    this.getData();
    this.getTotals();
    try{
        this.$Progress.finish();
    }
    catch(Err){console.error(Err);}
  },
};
</script>
<style scoped> 
.dx-texteditor.dx-editor-outlined {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 4px !important;
    color: black;
}
</style>