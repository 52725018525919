<template>
    <div>
        <div class="modal-dialog modal-xxl">
            <div class="modal-content">
                <div class="modal-header bg-primary p-5">
                    <h2 class="text-white">Person Details</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closePersonModal" @click="onCloseModal()">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-2"> 
                     <div class="card card-xxl-stretch pt-3">
                        <div class="card-body">
                            <div class="row">  
                                <div class="col-lg-6">
                                    <div class="row mb-2">
                                        <label class="col-lg-4 col-form-label form-label fw-bold p-2">
                                            {{ $t('Date of') }} <br> {{ $t('Birth') }}
                                        </label>
                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                            <Datepicker name="birth_date" :required="this.$checkIfDateFieldRequired ('birth_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorPersonForm.birth_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row mb-2">
                                        <label class="col-lg-4 col-form-label form-label fw-bold p-2">
                                            {{ $t('Gender') }}
                                        </label>
                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">                                                
                                            <Select2 v-model="factorPersonForm.gender" :options="genderList" name="gender" v-custom 
                                                :settings="{ width: '100%', dropdownParent: '#kt_modal_person .modal-content' }" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row mb-2">
                                        <label class="col-lg-4 col-form-label form-label fw-bold p-2">
                                            {{ $t('Position') }} <br> {{ $t('Type') }}
                                        </label>
                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">                                                
                                            <Select2 v-model="factorPersonForm.position_type_id" :options="personPositionList" name="position_type_id" v-custom 
                                                :settings="{ width: '100%', dropdownParent: '#kt_modal_person .modal-content' }" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row mb-2">
                                        <label class="col-lg-4 col-form-label form-label fw-bold p-2">
                                            {{ $t('Position') }} <br> {{ $t('Date') }}
                                        </label>
                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">                                               
                                            <Datepicker name="position_date" :required="this.$checkIfDateFieldRequired ('position_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorPersonForm.position_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row mb-2">
                                        <label class="col-lg-4 col-form-label form-label fw-bold p-2">
                                            {{ $t('Licence') }} <br> {{ $t('Number') }}
                                        </label>
                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                            <input type="text" class="form-control" name="licence_number" v-model="factorPersonForm.licence_number" v-custom>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row mb-2">
                                        <label class="col-lg-4 col-form-label form-label fw-bold p-2">
                                            {{ $t('Licence') }} <br> {{ $t('Date') }}
                                        </label>
                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">                                               
                                            <Datepicker name="licence_date" :required="this.$checkIfDateFieldRequired ('licence_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorPersonForm.licence_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                           <button class="btn btn-success btn-sm w-100" type="button" @click="this.onSavePerson()"> <i class="fa fa-save"></i> SAVE PERSON </button>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name:'FactorPersonModal',
    data(){
        return {
            factorPersonForm: {
                gender: '-1',
                birth_date: null,
                position_type_id: '-1',
                position_date: null,
                licence_number: null,
                licence_date: null,
            },
            genderList: [
                {id: '-1', text: 'Select an option'},
                {id: 'M', text: 'Male'},
                {id: 'F', text: 'Female'},
                {id: 'O', text: 'Other'},
            ],
            personPositionList: []
        }
    },
    methods:{
        getData(){
            this.clearForm();
            const parametersOne = {
                session_id : this.$getUser().session_id,
                list_types: [
                    { list_type: 19, list_name: "person_position" }
                ],
            };        
            const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parametersOne, {'Content-Type': 'application/json'});        
            axios.all([requestOne]).then(axios.spread((...responses) => {            
                this.personPositionList = responses[0].data.person_position;            
                this.personPositionList.unshift({ id: '-1', text: 'Select an option'});
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        onSavePerson() {
            this.$refs.closePersonModal.click();            
            // this.$swal.fire({
            //     title: "Are you sure you want to save person?",
            //     confirmButtonColor: "#50cd89",
            //     showDenyButton: false,
            //     showCancelButton: true,
            //     confirmButtonText: "SAVE",
            //     icon: 'info'
            // })
            // .then((result) => {
            //     if (result.isConfirmed) {
            //         const parametersPerson = this.factorPersonForm;
            //         parametersPerson.id = this.propertyPolicyData.id; //ID her zaman Data Import'daki Factor ID'sidir.
            //         parametersPerson.account_id = this.selectedTransaction.customer_account_id;
            //         parametersPerson.address_id = parametersPerson.address_id == '-1' ? null : parametersPerson.address_id;
            //         parametersPerson.country = parametersPerson.country == '-1' ? null : parametersPerson.country;
            //         parametersPerson.usage_type_id = parametersPerson.usage_type_id == '-1' ? null : parametersPerson.usage_type_id;
            //         parametersPerson.usage_type_detail_id = parametersPerson.usage_type_detail_id == '-1' ? null : parametersPerson.usage_type_detail_id;
            //         parametersPerson.property_type_id = parametersPerson.property_type_id == '-1' ? null : parametersPerson.property_type_id;
            //         parametersPerson.construction_type_id = parametersPerson.construction_type_id == '-1' ? null : parametersPerson.construction_type_id;
            //         parametersPerson.construction_year_id = parametersPerson.construction_year_id == '-1' ? null : parametersPerson.construction_year_id;
            //         parametersPerson.is_update_property_card = 1;
            //         parametersPerson.session_id = this.$getUser().session_id;

            //         const request =  axios.post('/api/DLLTemplates/UpdateTempTransactionPerson', parametersPerson, {'Content-Type': 'application/json'});
            //         axios.all([request]).then(axios.spread(() => {
            //             this.$swal("Success", "Person saved successfully..!", 'success');
            //             setTimeout(() => {
            //                 this.$refs.closePersonModal.click(); 
            //             }, 500);                
            //         }))
            //         .catch(function (error) {
            //             console.log(error);
            //         });
            //     }         
            // });
        },
        onCloseModal() {
            this.$parent.isViewFactorPersonModal = false;
        },
        clearForm() {
            this.factorPersonForm = {
                gender: '-1',
                birth_date: null,
                position_type_id: '-1',
                position_date: null,
                licence_number: null,
                licence_date: null,
            };
        }
    }
}
</script>