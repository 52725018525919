<template>
    <div>
        <div id="kt_header" class="container-header" data-kt-sticky="true" data-kt-sticky-name="header" style="min-height: 50px; background-color: #c7c7c7;">
            <div class="d-flex justify-content-between">
              <div class="d-flex justify-content-start">
                <h1 class="d-flex text-gray fw-bolder fs-3 m-3"> Help Page </h1>
              </div>
              <div class="d-flex justify-content-end">
                <div class=" m-3 mr-5 pr-5">
                 <div class="row">
                  <div class="col-md-12">
                    <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{this.selectedLanguage[0].title }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li v-for="lang in languages"  :key="lang.title"><a class="dropdown-item" href="#" @click="onSelectedLanguage(lang.code,lang.title)">{{ lang.title }}</a></li>
                    </ul>
                  </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
        </div>
      <div class="separator my-3 mt-0 border-gray-300"></div>
      <div class="content flex-row-fluid mt-4 p-5" id="help_page">
        <div class="row" v-for="group in groupList" v-bind:key="group">
            <div class="d-flex flex-column flex-row-auto" v-for="rd in group.items" v-bind:key="rd" :class="[rd.pages_content_width]">
                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6"  v-if="rd.pages_content_is_active != 0" >
                    <div class="card-body pt-3 pb-3" > 
                        <div class="row">
                            <div v-html="rd.pages_content_content" ></div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
      </div>   
    </div>  
</template>
  
<script> 
import axios from 'axios'

  export default {

    name: "HelpPage",
    data() {
      return {
          lists: [],
          searchbar: '',
          menu_selection: '',
          pagesAllLists:[],
          groupList :[],
          pageUrl :  window.location.pathname,
          notPage:0,
          filteredLang:[],
          selectedLanguage:[
            {
              code:  'en|en',
              title: 'English',
            },
          ],
          languages: [
            {
              code: 'en|en',
              title: 'English',
            },
            {
              code: 'en|tr',
              title: 'Turkish',
            }
          ],
      };
    },
    methods: {
      getData(){       
        this.$Progress.start();
        this.pagesAllLists = [];
        this.pagesAllLists = [];
        this.groupList = [];
        const parameters = {
          session_id: this.$getUser().session_id,
          menu_selection: this.pageUrl,
          language: this.selectedLanguage[0].code
        };  
        axios.post('api/HelpPage/GetPageContents', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.pagesAllLists = response.data.contents;
          this.languages = response.data.language;
          this.pagesAllLists.forEach((item) => { 
              if(!this.groupList.find(existingKey => existingKey.key == item.pages_content_is_group )) {
                this.groupList.push({key: item.pages_content_is_group , items: []});                   
              } 
            });
            response.data.contents.forEach((rd) => { 
              this.groupList.forEach((gl) => {
                if( gl.key == rd.pages_content_is_group ){
                  gl.items.push(rd);
                }    
              }); 
            });
            sessionStorage.clear();
            this.$Progress.finish();
          })
          .catch(function (error) {
            console.error(error);
            sessionStorage.clear();
            this.$Progress.finish();
          });  
      },
      onSelectedLanguage(selectLang,selectTitle){
        this.selectedLanguage[0].code = selectLang;
        this.selectedLanguage[0].title = selectTitle;
        this.getData();
      }
    }, 
    created() {
      this.getData();
    }
  }; 
</script>
<style scoped>
a:link {
  text-decoration: none;
  font-size: large;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
</style>