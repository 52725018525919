<template>
    <span class="d-flex flex-column-fluid justify-content-end" >
            <Popper class="fs-7" placement="top" content="Help Page" hover = "true">
                <span>
                    <button type="button" class="btn p-1" @click="this.openHelpPage()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                        <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                        <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                        </svg>
                    </button>
                </span>
            </Popper>
        </span>
</template>
<script> 
import axios from 'axios';
export default {
    name: "HelpPageIcon",
    props: {
        menuSelection: Object
    },
    components:{ 
    }, 
    setup() { 
    },
    data() {
        return {  
        };
    },
    computed: {
       
    },
    methods: {
        openHelpPage() {
            const parameters = {
                session_id : this.$getUser().session_id,
                menu_selection : this.menuSelection
            };
            axios.post('api/HelpPage/GetPageContents', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                this.pageContents = response.data.contents;
                window.open(this.menuSelection);
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                this.$removeSessionStorage('data'+ 'HP001');
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });    
        },  
    },
    created() {   
    },
    mounted(){ 
    }
}; 
</script>