<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">
                    {{ $t('Update Cash Transaction') }}
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-primary">{{ $t('Transactions') }}</li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Cash Transaction') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1" v-if="getSubCredentials('R')">
                <button type="button" class="btn btn-light border border-gray-400" data-bs-toggle="modal" data-bs-target="#kt_modal_transaction_list" @click="onOpenTransactionListModal()" :disabled="!this.$getUser().is_allow_list_all_data && (this.form.payment_account_id == null || this.form.payment_account_id == undefined || this.form.payment_account_id == '-1')" >
                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                    </svg>
                    </span>
                    {{ $t('Transaction List') }}
                </button>
            </div>
        </div>  
        <div class="content flex-row-fluid mt-4" id="kt_content">  
            <!-- <StatsBar></StatsBar> -->
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row p-4">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-header">
                                    <div class="card-title">
                                        <h3>
                                            {{ $t('Update Cash Transaction') }}
                                        </h3>
                                    </div>
                                    <div class="d-flex justify-content-end py-4">
                                        &nbsp;
                                        <router-link to="/CashTransactionCreate" v-if="getSubCredentials('I')">
                                            <button type="button" class="btn btn-light-primary border border-primary me-3">
                                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                                </span>
                                                {{ $t('Start New Cash Transaction') }}
                                            </button>
                                        </router-link> 
                                        <div class="dropdown">
                                            <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="cashActions" type="button">
                                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                                </svg></span>
                                                    {{$t('Actions')}}
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="cashActions">  
                                                <li v-if="form.is_accounted == 1 && this.$getUser().is_allow_cancel_accounting_integration"><a class="dropdown-item fs-4 text-danger" href="#" @click="onCancelIntegration()">{{ $t('Cancel Accounting Integration') }}</a></li>
                                                <li v-if="getSubCredentials('D')"><a class="dropdown-item fs-4 text-danger" href="#" @click="deleteDocket()">{{ $t('Delete') }} </a></li>
                                            </ul> 
                                        </div>
                                        <div class="dropdown ms-3" v-if="getSubCredentials('P')">
                                                <button class="btn btn-light-warning border border-warning dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="cashPrintActions" type="button">
                                                    <i class="fa fa-print"></i>
                                                    {{ $t('Print Actions') }}
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="cashPrintActions"> 
                                                    <li>
                                                        <a class="dropdown-item fs-4 text-primary py-4" href="#" @click="printReceipt()" 
                                                            data-bs-toggle="modal" data-bs-target="#kt_modal_print">
                                                           <i class="fa fa-print text-primary fs-5"></i>  {{ $t('Print Receipt') }}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item fs-4 text-primary py-4" href="#" @click="downloadReceipt()">
                                                            <i class="fa fa-file text-primary fs-5"></i> {{ $t('Download PDF') }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>  
                                    </div>
                                </div>
                                <div class="card-body"> 
                                    <div class="fv-row row">
                                        <div class="col-lg-4">
                                            <label class="fs-6 fw-bold form-label" v-tag="'record_date'">
                                                {{ $t('Record Date') }}
                                            </label>
                                            <Datepicker name="record_date" :required="this.$checkIfDateFieldRequired ('record_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.record_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" @update:modelValue="onChangeRecordDate()" :disabled="this.docketItemsList.length > 0"  />
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="fs-6 fw-bold form-label" v-tag="'cash_transaction_type'">
                                                {{ $t('Select Transaction Type') }}
                                            </label>
                                            <Select2 name="cash_transaction_type" v-model="form.cash_transaction_type" :options="cashTypeFilteredList" v-custom
                                                @select="getDescriptions();" :settings="{ width: '100%' }" disabled />
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="fs-6 fw-bold form-label" v-tag="'payment_account_id'">
                                                {{ $t('Select Payment Account') }}
                                            </label>
                                            <div class="input-group">
                                                <button type="button" class="btn rounded-start-0 btn-light btn-icon text-hover-primary border border-1 border-gray-300 z-index-0" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()"
                                                    v-if="form.payment_account_id !== null && form.payment_account_id !== '-1' && form.payment_account_id !== '' && form.payment_account_id !== undefined">
                                                    <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                </button>
                                                <input type="text" class="form-control form-control-lg" name="contact_name" v-model="form.payment_account_name" v-custom disabled>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-0">
                            <div class="card card-xxl-stretch mb-6 mt-3 bg-lighten border border-gray-300 mb-10">
                                <div class="card-body pb-0">
                                    <div class="p-0">
                                        <div class="d-flex flex-stack flex-wrap mb-2">
                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                <button type="button" class="btn btn-sm btn-success fw-bold me-3 required" v-if="!isBalanceClosed"
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#kt_modal_add_item" 
                                                        @click="openDocketModal('addItem')"
                                                        :disabled="form.payment_account_id == null || form.payment_account_id == '' || form.payment_account_id == '-1' || form.payment_account_id == undefined"
                                                        data-kt-menu-trigger="click" 
                                                        data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span> 
                                                        {{ $t('Add To List') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" 
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_add_item" 
                                                        @click="openDocketModal('editItem')"
                                                        :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                            </svg>
                                                        </span> 
                                                        {{ $t('Edit') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-warning me-3"
                                                    @click="deleteDocketItem()"
                                                    :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn btn-light btn-active-danger" @click="deleteAllDocketItems()" v-if="!isBalanceClosed">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove All (!)') }}
                                                </button>
                                            </div>
                                            <div class="flex-row">
                                                <div class="d-flex justify-content-end">
                                                    <span class="fw-bold text-gray-600 me-5">{{ $t('Docket Number') }}: </span>
                                                    <span class="fw-boldest text-black-600"> {{this.form.docket_number}} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pb-4">                                        
                                            <DxDataGrid id="gridContainer" 
                                                :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="docketItemsList" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true" 
                                                :customize-columns="onCustomizeColumn"
                                                @exporting="this.$onExporting($event , 'Cash-transaction-items')"
                                                :scrolling="{ mode: 'virtual' }"
                                                @option-changed="handlePropertyChange"> 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="pageSize"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxSearchPanel :visible="true"/> 

                                                <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/> 
                                                <DxColumn data-field="transaction_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="transaction_date-cell-template"/>   
                                                <DxColumn data-field="item_type_name" :caption="$t('Type')" cell-template="item_type_name-cell-template"/>   
                                                <DxColumn data-field="cash_bank_account_name" :caption="$t('Bank Account')" header-cell-template="bank-account-header" cell-template="cash_bank_account_name-cell-template"/>   
                                                <DxColumn data-field="payment_account_name" :caption="$t('Payment Account')" header-cell-template="payment-account-header" cell-template="payment_account_name-cell-template"/>   
                                                <DxColumn data-field="transaction_number" :caption="$t('Transaction No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>  
                                                <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/>
                                                <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>  
                                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                                <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" cell-template="amount_local-cell-template"/> 

                                                <template #bank-account-header>
                                                    <div>{{ $t('Cash') }}</div><div>{{ $t('Account') }}</div>
                                                </template>
                                                <template #payment-account-header>
                                                    <div>{{ $t('Payment') }}</div><div>{{ $t('Account') }}</div>
                                                </template>
                                                <template #transaction-no-header>
                                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                                </template>

                                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                                <template #headerLocalTemplate="{ data: data }">
                                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                </template>

                                                <template #is-selected-cell-template="{ data }">
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #id-cell-template="{ data }">
                                                    <a href="#" class="text-gray-800 text-hover-primary">
                                                        {{getRowNumber(data)}}
                                                    </a>
                                                </template>
                                                <template #transaction_date-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle text-end" > {{ previewDate(data.data.transaction_date) }}</span>
                                                </template>
                                                <template #item_type_name-cell-template="{ data }">
                                                        <span class="fw-bold p-3 align-middle" :class="{ 'text-primary': data.data.item_type == 1 , 'text-danger': data.data.item_type == 2}"> {{ data.data.item_type_name }}</span>
                                                </template>
                                                <template #cash_bank_account_name-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle" > {{ data.data.cash_bank_account_name }}</span>
                                                </template>
                                                <template #payment_account_name-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle" > {{ data.data.payment_account_name }}</span>
                                                </template>
                                                <template #transaction_number-cell-template="{ data }">
                                                    <span class="text-gray-600 p-3 align-middle" >{{ data.data.transaction_number }}</span>
                                                </template>
                                                <template #amount-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount}}</span>
                                                </template>
                                                <template #currency-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold p-3 align-middle"> {{data.data.currency}}</span>
                                                </template>
                                                <template #rate-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.rate}} </span>
                                                </template>
                                                <template #amount_local-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount_local}} </span>
                                                </template>
                                                <DxSummary :calculate-custom-summary="setSummaries">
                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                        <DxGroupItem
                                                            :show-in-group-footer="true"
                                                            name="amount_local"
                                                            summary-type="custom"
                                                            show-in-column="amount_local"
                                                            display-format="{0}"
                                                        /> 
                                                        <DxTotalItem name="amount_local"
                                                            summary-type="custom"
                                                            show-in-column="amount_local"
                                                        />
                                                    </DxSummary>
                                                        <DxStateStoring
                                                            :enabled="true"
                                                            type="custom"
                                                            :storage-key="this.storageKey"
                                                            :ref="dataGrid" 
                                                            :saving-timeout="1000"
                                                            :custom-load="LoadDXState"
                                                        />
                                            </DxDataGrid>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="getSubCredentials('U')">
                            <div class="row g-xxl-9">
                                <div class="col-lg-12">
                                    <div class="d-grid gap-2"> 
                                        <button class="btn btn-primary btn-lg mb-4" type="submit" @click="updateDocket()" data-kt-indicator="on"  :disabled="docketItemsList.length == 0"> 
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span>  
                                            <strong v-if="!this.isDocketSaving"> {{ $t('Update Transaction') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isDocketSaving">
                                                {{ $t('Updating Transaction. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- ADD TRANSACTION MODAL -->
        <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl" :class="{'modal-xxl': this.activeTab == 'BalanceClosing'}">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                        <div class="modal-title fw-bolder col-md-11">
                            <h2 class="text-white"> 
                                <span v-if="this.modalType == 'addItem'">{{ $t('Add') }}</span> 
                                <span v-if="this.modalType == 'editItem'">{{ $t('Edit') }}</span> {{ $t('Transaction') }}
                            </h2>
                            <small class="text-white" v-if="activeTab == 'BalanceClosing'">{{form.payment_account_name}} - {{docketForm.amount}} {{this.docketForm.currency}}</small>
                        </div> 
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y pt-0" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                        <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                            <li class="nav-item">
                                <a class="nav-link cursor-pointer" :class="{'active': this.activeTab == 'Transaction'}" @click="this.activeTab = 'Transaction'"><h4>{{ $t('Transaction') }}</h4></a>
                            </li>
                            <li class="nav-item" v-if="this.$isCredentialActive(26,'') && (form.payment_account_type == 1 || form.payment_account_type == 2)">
                                <a v-if="isBalanceClosingActive" class="nav-link cursor-pointer" :class="{'active': this.activeTab == 'BalanceClosing'}" 
                                    @click="this.activeTab = 'BalanceClosing', onClickBalanceClosing();">
                                    <h4>{{ $t('Balance Closing') }}</h4>
                                </a>
                                <Popper hover placement="top" v-if="!isBalanceClosingActive">
                                    <a class="nav-link" disabled>
                                        <h4>{{ $t('Balance Closing') }}</h4>
                                    </a>
                                    <template #content>
                                        <span class="text-danger"> {{ $t('Cash Account is required..!') }} </span>
                                    </template>
                                </Popper>
                            </li> 
                            <li class="nav-item" v-if="this.$isModuleActive(3)">
                                <a class="nav-link cursor-pointer" :class="{'active': this.activeTab == 'Document'}" @click="this.onActivateDocumentsTab()"><h4>{{ $t('Documents') }}</h4></a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade" :class="{'show active': this.activeTab == 'Transaction'}">
                                <RequiredFieldOnModal :routeName="this.modalType == 'addItem' ? 'CashTransactionItemCreate' : 'CashTransactionItemUpdate'" class="mt-5"></RequiredFieldOnModal>
                                <form id="cashItemForm" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card card-xxl-stretch mb-6">
                                                        <div class="card-body p-5"> 
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'cash_bank_account_id'"  >
                                                                    {{ $t('Select Cash Account') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="docketForm.cash_bank_account_id" :options="cashAccountList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" @select="onChangeCashAccount();" :disabled="modalType == 'editItem'" name="cash_bank_account_id" v-custom />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="card card-xxl-stretch mb-6">
                                                        <div class="card-body">
                                                            <div class="card-body p-1 pt-2"> 
                                                                <div class="row mb-2">
                                                                    <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'transaction_date'" >
                                                                        {{ $t('Transaction Date') }}
                                                                    </label>
                                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                        <Datepicker :required="this.$checkIfDateFieldRequired('transaction_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="docketForm.transaction_date"  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" @update:modelValue="onChangeCurrency()" :disabled="modalType == 'editItem'" name="transaction_date" v-custom />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label class="col-lg-4 col-form-label fw-bold fs-6 ">
                                                                        <span > {{ $t('Currency') }} </span>  
                                                                        <span v-tag="'rate'" v-if="this.docketForm.currency !== this.localCurrency"> / {{ $t('Rate') }} </span>  
                                                                    </label>
                                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.docketForm.currency == this.localCurrency }">
                                                                                <Select2 v-model="this.docketForm.currency" :options="this.currencyList" @select="onChangeCurrency()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" disabled />
                                                                            </div>
                                                                            <div class="col-lg-6 fv-row fv-row fv-plugins-icon-container" v-if="this.docketForm.currency !== this.localCurrency">
                                                                                <Select2 v-model="typeSelection" :options="typeList" @select="onChangeRateType()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" :disabled="modalType == 'editItem'"/>  
                                                                            </div>
                                                                            <div class="col-lg-12 fv-row fv-plugins-icon-container mt-3" v-if="this.docketForm.currency !== this.localCurrency">
                                                                                <CurrencyInput type="text" class="form-control" v-model="this.docketForm.rate" :precision="5" @focus="$event.target.select()"  @blur="calculateLocalAmount()" :disabled="modalType == 'editItem'" name="rate" v-custom />
                                                                                <small v-if="this.docketForm.rate_date !== null">{{ $t('Rate Date') }}: {{ previewDate(this.docketForm.rate_date) }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label class="col-lg-4 col-form-label fw-bold fs-6 ">
                                                                        <span v-tag="'amount'" >{{ $t('Amount') }}</span> 
                                                                        <span v-if="this.docketForm.currency !== this.localCurrency">/ {{ $t('Local Amount') }} </span>   
                                                                    </label>
                                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.docketForm.currency == this.localCurrency }">
                                                                                <div class="input-group mb-3">
                                                                                    <CurrencyInput type="text" class="form-control text-end" v-model="docketForm.amount" @focus="$event.target.select()" @blur="calculateLocalAmount()" :disabled="modalType == 'editItem'" name="amount" v-custom />
                                                                                    <ErrorMessage name="amount" class="text-danger" />
                                                                                    <span class="input-group-text p-1">
                                                                                        <button type="button" class="btn btn-light btn-sm p-1" @click="this.onChangeAmount()" :disabled="modalType == 'editItem'">
                                                                                            <i class="bi bi-box-arrow-in-down-left fs-2"></i>
                                                                                        </button>
                                                                                    </span>
                                                                                </div>  
                                                                            </div>
                                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.docketForm.currency !== this.localCurrency">
                                                                                <CurrencyInput type="text" class="form-control text-end" v-model="docketForm.amount_local" @focus="$event.target.select()" disabled />
                                                                                <ErrorMessage name="amount" class="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                        <span v-tag="'transaction_number'" >{{ $t('Transaction No') }}</span>
                                                                        <span v-tag="'ref_number'" >{{ $t(' / Ref No') }}</span>
                                                                    </label>
                                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                                <input type="text" class="form-control fw-boldest text-black-600" v-model="docketForm.transaction_number" :disabled="modalType == 'editItem'"  name="transaction_number" v-custom >
                                                                            </div>
                                                                            <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                                <input type="text" class="form-control" v-model="docketForm.ref_number" :disabled="modalType == 'editItem'" name="ref_number" v-custom >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'description'" >
                                                                        <span>{{ $t('Description') }}</span>
                                                                    </label>
                                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                        <div class="input-group">
                                                                            <input type="text" class="form-control" v-model="docketForm.description" :disabled="modalType == 'editItem'" name="description" v-custom >
                                                                            <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                                                                <div class="dropdown dropup">
                                                                                    <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;" :disabled="modalType == 'editItem'">
                                                                                        <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                                                    </button>
                                                                                    <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                                                        <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                                                            <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                                                                <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)" :disabled="modalType == 'editItem'"
                                                                                                    :class="{'text-success': this.docketForm.description == item.description && item.description !== ''}">
                                                                                                    <i class="bi bi-dash fw-boldest" v-if="this.docketForm.description !== item.description"> </i> 
                                                                                                    <i class="bi bi-check-lg fw-boldest text-success" v-if="this.docketForm.description == item.description && item.description !== ''"> </i>
                                                                                                    {{item.text}}  
                                                                                                </a>
                                                                                                <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="d-grid gap-2"> 
                                                <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'"
                                                    @click="this.addDocketItem()" :disabled="this.isModalButtonDisabled">
                                                    <strong>{{ $t('Add To List') }}</strong> 
                                                </button>
                                                <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                                    @click="this.updateDocketItem()" :disabled="this.isModalButtonDisabled">
                                                    <strong>{{ $t('Update') }}</strong> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                                <div class="tab-pane fade" :class="{'show active': this.activeTab == 'BalanceClosing'}">
                                <BalanceClosingList ref="BalanceClosingList" :modal-type="this.modalType" :active-tab="this.activeTab"></BalanceClosingList>
                            </div>
                            <div class="tab-pane fade" :class="{'show active': this.activeTab == 'Document'}">
                                <div class="row" v-if="this.activeTab == 'Document'">
                                    <FileManager :location="2" 
                                                    :locationtype="2" 
                                                    :is_transaction="1" 
                                                    :account_a="this.docketForm.cash_bank_account_id" 
                                                    :account_b="this.form.payment_account_id" 
                                                    :uuid="this.fileUUID"
                                                    :upload_source="1">
                                    </FileManager>
                                </div> 
                            </div>
                        </div> 
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="kt_modal_print" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<PrintModal ref="PrintModal" />
		</div> 

        <!-- Transaction List -->
        <div class="modal fade" id="kt_modal_transaction_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <TransactionListModal ref="TransactionListModal" :transaction-type="2" :account-id="this.form.payment_account_id" :account-name="form.payment_account_name"/>
        </div>

        <!-- Statement Modal  -->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="form.payment_account_name" :account-id="form.payment_account_id" :account-type="form.payment_account_type" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem } from 'devextreme-vue/data-grid';
import { uuid } from 'vue3-uuid';
import PrintModal from '../Modals/PrintModal.vue';
import { saveAs } from 'file-saver';
import TransactionListModal from '../TransactionListModal.vue';
import BalanceClosingList from '../Modals/BalanceClosingList.vue';
 
export default {
  name: "CashTransactionUpdate", 
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem, PrintModal, TransactionListModal, BalanceClosingList
  },
  data() {
    return {
        userData: this.$getUser(),
        pageSize: null,
        pageIndex: 0,
        fileUUID: null,
        activeTab: 'Transaction',
        isOpenDescriptionDropdown: false,
        isPrintModalOpen: false,
        searchbar: '',
        docketItemsList: [],
        currencyList: [],
        cashAccountList: [{ id: '-1', text: 'Select an option'}],
        cashTypeList: [],
        accountTypeList: [
            { id: '-1', text: 'Select an option'},
            { id: 12, text: 'Customer & Supplier'},
            { id: 3, text: 'Cash'},
            { id: 4, text: 'Bank'},
            { id: 5, text: 'Personnel'},
            { id: 6, text: 'Expense'},
            { id: 8, text: 'Asset'},
            { id: 15, text: 'Other Account' }
        ], 
        descriptionTypeList: [],
        localCurrency: null,
        docketForm: {
            id: null,
            cash_bank_account_id: '-1',
            transaction_date: moment(),
            transaction_number: null,
            currency: '-1',
            amount: 0.00,
            rate: 1,
            ref_number: null,
            description: null,
            rate_date: null,
            amount_local: 0.00
        },
        form: {
            docket_type: 7,
            record_date: moment(),
            docket_number: null, 
            account_name: '-1',
            account_id: '-1',
            is_accounted: 0,
            payment_account_type: 1,
            payment_account_id: '-1',
            payment_account_name: null,
            cash_transaction_type: 1
        }, 
        modalType: 'addItem',
        docketSummaryList: [],
        isDocketSaving: false,
        isDocketItemSaving: false,
        isModalButtonDisabled: false,
        menuSelection: '/CashTransactionHelppage',
        iconView : false,
        isSaving: false,
        isViewStatementModal: false,
        balanceClosingList: [],
        isBalanceClosed: false,
        isSearchingAccount: false,
        isManualBalanceClosing: false
    };
  },
  computed: { 
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
    },
    previewDate(){
      return (value)=>{
        if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
   isItemButtonDisabled() {
       return this.docketItemsList.find(item =>item.isSelected == true );
    },
    showPageSizeSelector() {
        return this.docketItemsList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.docketItemsList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    isBalanceClosingActive() {
        return this.form.payment_account_id !== null && this.form.payment_account_id !== '-1' && this.form.payment_account_id !== '' && this.docketForm.cash_bank_account_id !== null 
        && this.docketForm.cash_bank_account_id !== '-1' && this.docketForm.cash_bank_account_id !== '';
    },
    getSubCredentials() {
      return (operation)=>{
        let isView = false;
        if(this.form.cash_transaction_type == 1) isView = this.$isCredentialActive(31, operation, 21);
        if(this.form.cash_transaction_type == 2) isView = this.$isCredentialActive(32, operation, 21);
        return isView;
      }
    },
    cashTypeFilteredList() {
      if(!this.$isCredentialActive(31, 'U', 21)) {
        return this.cashTypeList.filter(p => p.id != 1);
      }
      if(!this.$isCredentialActive(32, 'U', 21)) {
        return this.cashTypeList.filter(p => p.id != 2);
      }      
      return this.cashTypeList;
    },
  },
  methods: {
    getData() { 
        this.$Progress.start();
        this.$removeSessionStorage('tmp_session_id');
        this.isPrintModalOpen = false;
        this.docketItemsList = [];

        const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["currency"]
        };
        const parametersTwo = { 
            session_id: this.$getUser().session_id,
            docket_header_id: this.$getSessionStorage('record_id')
        };
        const parametersThree = {
            session_id : this.$getUser().session_id,
            account_type : 3
        };
        const parametersFour = { 
            session_id: this.$getUser().session_id,
            tip: 6
        }; 
        const that = this;
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
        const requestTwo =  axios.post('/api/Transaction/GetDocketById', parametersTwo, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/CompanyAccounts/GetAccounts', parametersThree, {'Content-Type': 'application/json'}); 
        const requestFour =  axios.post('/api/Helper/GetConst', parametersFour, {'Content-Type': 'application/json'});
        
        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
            this.currencyList = responses[0].data.currency;
            this.localCurrency = this.$getUser().currency_code; 
            if (this.localCurrency !== null && this.localCurrency !== '' && this.localCurrency !== undefined) {
                this.docketForm.currency = this.$getUser().currency_code;
            }
            this.form = responses[1].data.docket_header_detail;
            this.form.cash_transaction_type = responses[1].data.docket_header_detail.item_type;
            this.docketItemsList = responses[1].data.docket_items;
            this.docketSummaryList = responses[1].data.docket_summary;
            this.isBalanceClosed = responses[1].data.is_balance_closing_exist;
            this.$setSessionStorage('refreshList', true); 
            if(responses[1].data.docket_items.length > 0){
                this.$setSessionStorage('tmp_session_id', responses[1].data.docket_items[0].session_id); 
            }
            
            this.cashAccountList = responses[2].data.account_data;
            this.cashAccountList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });

            this.cashTypeList = responses[3].data;
            this.cashAccountList.unshift({ id: '-1', text: 'Select an option'});
            this.getDescriptions(function() {}); 
            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors); 
            that.$Progress.finish();
        });     
    },
    handlePropertyChange(e) {
        if(e.name == "paging") {
            this.pageIndex = e.component.pageIndex();
        }
    },
    openDocketModal(modalMode) {
        const parameters = {
            route: modalMode == 'addItem'  ? 'CashTransactionItemCreate' : 'CashTransactionItemUpdate',
            session_id: this.$getUser().session_id
        };  
        axios.post('/api/FormValidations/GetFormFields', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                sessionStorage.setItem('frm_val', JSON.stringify(response.data));
            }
            this.activeTab = 'Transaction';
            this.modalType = modalMode;
            this.isDocketItemSaving = false;
            this.isModalButtonDisabled = false;
            this.isOpenDescriptionDropdown = false;
            this.isManualBalanceClosing = false;

            // Doğru listeleri getirebilmek için Edit mode durumunda Account Tipi önden alınır.
            if(modalMode == 'editItem'){
                this.docketItemsList.forEach((item) => { 
                    if(item.isSelected){
                        this.fileUUID = item.file_id == null ? uuid.v4() : item.file_id;
                    }
                });
            } 
            else { this.fileUUID = uuid.v4(); } 

            // Form öğeleri sıfırlanır.
            this.docketForm.id = null;
            this.docketForm.transaction_date = moment(this.form.record_date);
            this.docketForm.cash_bank_account_id = '-1';
            this.docketForm.transaction_number = null; 
            this.docketForm.amount = 0.00;
            this.docketForm.amount_local = 0.00;
            this.docketForm.rate = 1;
            this.docketForm.ref_number = null;
            this.docketForm.description = null;
            this.docketForm.currency = this.localCurrency;        
            this.docketForm.transaction_date = moment(this.form.record_date);
            this.balanceClosingList = [];
            if(this.form.payment_account_type == 1 || this.form.payment_account_type == 2) {
                this.$refs.BalanceClosingList.form = {
                    account_id: this.form.payment_account_id,
                    id: this.docketForm.id,
                    amount: Math.abs(parseFloat(this.docketForm.amount)),
                    item_type: this.form.cash_transaction_type
                }; 
                this.$refs.BalanceClosingList.getData();
            }
            // Open Docket Modal in Edit Mode.
            if(this.modalType == 'editItem'){ 
                this.docketItemsList.forEach((item) => { 
                    if(item.isSelected){ 
                        this.docketForm.id = item.id; 
                        this.docketForm.cash_bank_account_id = item.cash_bank_account_id; 
                        this.docketForm.transaction_number = item.transaction_number; 
                        this.docketForm.amount = item.amount.replace(/,/g, '');
                        this.docketForm.amount_local = item.amount_local.replace(/,/g, ''); 
                        this.docketForm.rate = item.rate.replace(/,/g, '');
                        this.docketForm.ref_number = item.ref_number;
                        this.docketForm.description = item.description;
                        this.docketForm.transaction_date = moment(item.transaction_date); 
                        this.docketForm.currency = item.currency;
                    }
                });
            }
            this.onChangeCurrency(true);
            
        });
    },
    setSummaries(options){
        if(options.name == "amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){ 
                var amount_local = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amount_local); 
            }
            else if(options.summaryProcess === 'finalize'){
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        } 
    },
    onSelectItem(item) {
        this.docketItemsList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });
    },
    getRowNumber(data) {
      return data.rowIndex + 1;
    },
    addDocketItem(){
        this.$checkSelect2Validation('cashItemForm');
        if(!document.forms['cashItemForm'].reportValidity()){
            return;
        }
        if(this.docketForm.transaction_date == null || this.docketForm.transaction_date == '' || this.docketForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if (this.docketForm.amount == null || this.docketForm.amount == '' || this.docketForm.amount == undefined) { this.docketForm.amount = 0; }
        if (this.docketForm.rate == null || this.docketForm.rate == '' || this.docketForm.rate == undefined) { this.docketForm.rate = 0; }
        this.isModalButtonDisabled = true;
        this.isDocketItemSaving = true;
        const that = this;
        const params = { 
            item_type: this.form.cash_transaction_type,
            cash_bank_account_id: this.docketForm.cash_bank_account_id,
            transaction_date: this.docketForm.transaction_date,
            transaction_number: this.docketForm.transaction_number,
            currency: this.docketForm.currency,
            amount: this.docketForm.amount,
            rate: this.docketForm.rate,
            ref_number: this.docketForm.ref_number,
            description: this.docketForm.description,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            payment_account_id: this.form.payment_account_id,
            file_id: this.fileUUID,
            due_date: moment(this.form.record_date),
            isManualBalanceClosing: this.isManualBalanceClosing,
            isBcCrd: this.$isCredentialActive(26,''),
            docket_type: this.form.docket_type
        };
        params.transaction_date = moment(params.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        params.due_date = moment(params.due_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        params.file_id = params.file_id == '' ? params.file_id = null :  params.file_id;        
        let selectedBalances = [];
        this.balanceClosingList.forEach((value) => {
            if(value.isSelected && value.closing_amount_input > 0){
                selectedBalances.push({
                    balance_id: value.id,
                    amount: value.closing_amount_input
                });
            }
        });
        params.balanceList = selectedBalances;
        axios.post('api/Transaction/AddItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                if(response.data.docket_items.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.docket_items[0].session_id);
                    this.docketSummaryList = response.data.docket_summary;
                    this.docketItemsList = response.data.docket_items;
                    this.isBalanceClosed = response.data.is_balance_closed;
                    this.$refs.closeModal.click();
                }  
            } 
            this.isDocketItemSaving = false;
        })
        .catch(function () {
            this.isDocketItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isDocketItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });   
    },
    updateDocketItem(){
        this.$checkSelect2Validation('cashItemForm');
        if(!document.forms['cashItemForm'].reportValidity()){
            return;
        }
        if(this.docketForm.transaction_date == null || this.docketForm.transaction_date == '' || this.docketForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if (this.docketForm.amount == null || this.docketForm.amount == '' || this.docketForm.amount == undefined) { this.docketForm.amount = 0; }
        if (this.docketForm.rate == null || this.docketForm.rate == '' || this.docketForm.rate == undefined) { this.docketForm.rate = 0; }
        this.isModalButtonDisabled = true;
        this.isDocketItemSaving = true;
        const that = this;
        const params = { 
            id: this.docketForm.id,
            item_type: this.form.cash_transaction_type,
            cash_bank_account_id: this.docketForm.cash_bank_account_id,
            transaction_date: this.docketForm.transaction_date,
            transaction_number: this.docketForm.transaction_number,
            currency: this.docketForm.currency,
            amount: this.docketForm.amount,
            rate: this.docketForm.rate,
            ref_number: this.docketForm.ref_number,
            description: this.docketForm.description,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            payment_account_id: this.form.payment_account_id,
            file_id: this.fileUUID,
            isManualBalanceClosing: this.isManualBalanceClosing,
            isBcCrd: this.$isCredentialActive(26,''),
            docket_type: this.form.docket_type
        };
        params.transaction_date = moment(params.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        params.file_id = params.file_id == '' ? params.file_id = null :  params.file_id;
        let selectedBalances = [];
        this.balanceClosingList.forEach((value) => {
            if(value.isSelected && value.closing_amount_input > 0){
                selectedBalances.push({
                    balance_id: value.id,
                    amount: value.closing_amount_input
                });
            }
        });
        params.balanceList = selectedBalances;
        axios.post('api/Transaction/UpdateItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                if(response.data.docket_items.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.docket_items[0].session_id);
                    this.docketSummaryList = response.data.docket_summary;
                    this.docketItemsList = response.data.docket_items;
                    this.isBalanceClosed = response.data.is_balance_closed;
                    this.$refs.closeModal.click();
                }  
            }   
            this.isDocketItemSaving = false;
        })
        .catch(function () {
            this.isDocketItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isDocketItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });   
    },
    deleteDocketItem() {
         this.$swal.fire({
            title: "Are you sure you want to delete this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.$Progress.start();
                var docketId = null; 
                this.docketItemsList.forEach((item) => { 
                    if(item.isSelected){ 
                        docketId = item.id; 
                    }
                });
                const params = { 
                    id: docketId, 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    docket_type: this.form.docket_type
                };
                axios.post('api/Transaction/DeleteItem', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        if(response.data.docket_items.length > 0){
                            this.$setSessionStorage('tmp_session_id', response.data.docket_items[0].session_id);  
                        }  
                        this.docketSummaryList = response.data.docket_summary;
                        this.docketItemsList = response.data.docket_items;
                        this.isBalanceClosed = false;
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        }); 
    },
    deleteAllDocketItems() {
         this.$swal.fire({
            title: "Are you sure you want to delete all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.$Progress.start();
                const params = { 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    docket_type: this.form.docket_type
                };
                axios.post('api/Transaction/DeleteAllItems', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$removeSessionStorage('tmp_session_id'); 
                        this.docketSummaryList = [];
                        this.docketItemsList = [];
                        this.isBalanceClosed = false;
                        this.$refs.closeModal.click();
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        }); 
    },
    updateDocket(){
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.isDocketSaving = false;
        this.$swal.fire({
            title: "Are you sure you want to save this Transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                this.isDocketSaving = true; 
                const that = this;
                const params = { 
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    record_date: this.form.record_date, 
                    docket_number: this.form.docket_number
                };  
                params.record_date = moment(params.record_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                axios.post('api/Transaction/UpdateDocket', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$removeSessionStorage('tmp_session_id');
                        this.$setSessionStorage('refreshList', true); 
                        this.getData();
                        this.$swal("Success", "Transaction updated succesfully..!", 'success');
                        this.isDocketSaving = false;
                        this.$Progress.finish();
                    }  
                    this.$Progress.finish();
                })
                .catch(function () { 
                    this.$Progress.finish();
                    new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isDocketSaving = false;
                        that.$Progress.finish(); 
                    }, 500);
                });  
            }
        }); 
    },
    deleteDocket(){
        this.$swal.fire({
            title: "Are you sure you want to delete this transaction record?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.$Progress.start(); 
                const params = { 
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id
                };  
                axios.post('api/Transaction/DeleteDocket', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$setSessionStorage('refreshList', true);    
                        this.$router.push({ name: 'CashTransactionCreate' });
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        });
    },
    onChangeRateType(){
        const params = {
            session_id: this.$getUser().session_id,
            rate_date: moment(this.docketForm.transaction_date),
            bank_id: 1, //EUROPEAN BANK
            currency: this.docketForm.currency
        };
        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.length > 0){ 
                this.rateList = response.data;
                this.form.rate_list_id = response.data[0].id;
                this.docketForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                this.docketForm.rate_date = response.data[0].rate_date;
                this.calculateLocalAmount(function() {});
            }
            else{
                this.docketForm.rate = 1;
                this.docketForm.rate_date = null;
                this.rateList = null;
                this.calculateLocalAmount(function() {});
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    onChangeCurrency(noWriteRate) {
        let originalRate = this.docketForm.rate;
        this.docketForm.rate = 1; 
        if(this.docketForm.currency !== this.localCurrency){

            axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
                .then((typeResponse) => {
                        this.typeList = typeResponse.data;
                        this.typeList.forEach((item) => { 
                            if(item.is_default == 1){
                                this.typeSelection = item.id;
                            }
                        });  

                        this.$Progress.start();
                        const params = {
                                session_id: this.$getUser().session_id,
                                rate_date: moment(this.docketForm.transaction_date),
                                bank_id: 1, //EUROPEAN BANK
                                currency: this.docketForm.currency
                        };
                        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                        var source = this.typeList.find((element) => element.id == this.typeSelection);
                        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
                        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                        .then((response) => {
                            if(response.data.length > 0){
                                this.rateList = response.data;
                                this.form.rate_list_id = response.data[0].id;
                                this.docketForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                                this.docketForm.rate_date = response.data[0].rate_date;
                                this.calculateLocalAmount(function() {});
                            }
                            else{
                                this.docketForm.rate = 1;
                                this.docketForm.rate_date = null;
                                this.calculateLocalAmount(function() {});
                            }
                            
                            if(noWriteRate){
                                this.docketForm.rate = originalRate;
                            }
                            this.$Progress.finish();
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.$Progress.finish();
                        });  

                })
                .catch(function (error) {
                    console.log(error); 
                }); 
        }
    },
    onChangeCashAccount() {
        this.docketForm.amount_local = 0.00;
        this.cashAccountList.forEach((item) => {
            if (item.id == '-1') {
                this.docketForm.currency = this.localCurrency;
                this.docketForm.rate = 1;
                this.docketForm.rate_date = null;
                return;
            }
            if (item.id == this.docketForm.cash_bank_account_id) {
                if (item.cash_currency !== null && item.cash_currency !== '' && item.cash_currency !== undefined) {
                    this.docketForm.currency = item.cash_currency;
                    this.onChangeCurrency();
                }
            }
        });
    },
    getCurrencies(){
        const params = {
            session_id: this.$getUser().session_id
        };    
        axios.post('/api/Currency/GetList', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.currencyList = response.data;
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.fail();
        });
    },
    calculateLocalAmount(callback){
        if(this.$getUser().currency_search_type == 1) {
            this.docketForm.amount_local = this.docketForm.amount / this.docketForm.rate;
        } else {
            this.docketForm.amount_local = this.docketForm.amount * this.docketForm.rate;
        }
        this.isManualBalanceClosing = false;
        if(typeof callback == 'function'){
            callback();
        } 
    },
    getAccountDetail(id) {
        this.form.payment_account_id = id;
        this.docketForm.amount = 0.00;
        this.docketForm.amount_local = 0.00;
        this.form.payment_account_name = null;
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.form.payment_account_name = this.$unlock(response.data.name);
            this.form.payment_account_type = response.data.account_type;
        });
    },
    getDescriptions(callback) {
        this.isOpenDescriptionDropdown = false;
        if (this.modalType == 'addItem') { this.docketForm.description = null; }
        const params = {
            session_id: this.$getUser().session_id,
            description_location: 3, //Cash
            location_detail: this.form.cash_transaction_type,
            account_type: this.form.payment_account_type !== '-1' ? this.form.payment_account_type : 0,
            sales_type: 0
        };
        axios.post('/api/Helper/GetDescriptionTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.descriptionTypeList = response.data;
            this.descriptionTypeList.forEach(element => {
                if (element.type_id == 2 && this.modalType == 'addItem') {
                    this.docketForm.description = element.description;
                }
            });
            if(typeof callback == 'function'){
                callback();
            }
        });
    },
    onSelectDescription(item) {
        this.docketForm.description = item.description;
        this.isOpenDescriptionDropdown = false;
    },
    onChangeAmount() {
        if(this.form.payment_account_id == null || this.form.payment_account_id == '' || this.form.payment_account_id == '-1' || this.form.payment_account_id == undefined) return;
        const parameters = {
          session_id: this.$getUser().session_id,
          account_id: this.form.payment_account_id
        };  
        axios.post('/api/CompanyAccounts/GetAccountBalance', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.docketForm.amount = this.docketForm.currency !== this.localCurrency ? Math.abs(parseFloat(response.data.total_balance.replaceAll(',', ''))) : Math.abs(parseFloat(response.data.total_balance_local.replaceAll(',', '')));
                this.calculateLocalAmount(function() {});
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                    if(column.dataField == 'amount_local'){ 
                        column.caption =  'Local Amount';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                });  
            } 
        } 
    },
    onActivateDocumentsTab(){
        this.activeTab = 'Document';
        if(this.fileUUID == null || this.fileUUID == '' || this.fileUUID == undefined){
            this.fileUUID = uuid.v4();         
        }
    },
    printReceipt(){
        if(!this.getSubCredentials('P')) return;
        this.$refs.PrintModal.isPrintModalOpen = true;
        this.$refs.PrintModal.isFrameLoading = true;
        this.$refs.PrintModal.documentName = this.form.payment_account_name.substring(0, 10) + '-Cash Transaction-' + this.form.docket_number;
        this.$refs.PrintModal.getData();
    },
    downloadReceipt(){
        if(!this.getSubCredentials('P')) return;
        const parameters = {
            session_id: this.$getUser().session_id,
            category: 2, // Receipt
            receipt_type: 0 // Receipt Type : Cash/Bank
        };
        axios.post('/api/Reports/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'}).then((responseReport) => { 
            this.designList = responseReport.data.report_designs;
            this.designSelection = responseReport.data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.reportType = item.category;
                }
            });

            if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
            {
                fetch('https://localhost:5001/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.reportType)
                    .then(response => response.blob())
                    .then(data => {
                        saveAs(data, (this.form.payment_account_name.substring(0, 10) + ' - Cash Transaction-'+ this.form.docket_number) + '.pdf');
                    });
            }
            else{
                fetch('/reports/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.reportType)
                    .then(response => response.blob())
                    .then(data => {
                        saveAs(data, (this.form.payment_account_name.substring(0, 10) + ' - Cash Transaction-'+ this.form.docket_number) + '.pdf');
                    });
            } 
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    onCancelIntegration() {
        this.$swal.fire({
            title: "Are you sure you want to cancel accounting integration?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const params = {
                    session_id: this.$getUser().session_id,
                    header_id: this.$getSessionStorage('record_id')
                };
                axios.post('api/AccountingIntegration/CancelSingleIntegration', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$swal("Success", "Accounting Integration canceled..!", 'success'); 
                        this.getData(); 
                    }
                })
                .catch(function (error) { 
                    console.log(error)
                });  
            }
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/CashTransactionHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    onOpenTransactionListModal() {
        this.$refs.TransactionListModal.getData();
    },
    onClickBalanceClosing() {
        this.isManualBalanceClosing = true;
        let isAuto = this.modalType == 'addItem' ? true : false;
        this.$refs.BalanceClosingList.form = {
            account_id: this.form.payment_account_id,
            id: this.docketForm.id,
            amount: Math.abs(parseFloat(this.docketForm.amount)),
            item_type: this.form.cash_transaction_type
        };
        if(this.form.cash_transaction_type == 2){ this.$refs.BalanceClosingList.balanceForm.isShowAll = false; }
        this.$refs.BalanceClosingList.balanceForm.currency = this.docketForm.currency;
        this.$refs.BalanceClosingList.onGetBalanceList(isAuto);
    },
    onOpenAccountReport(){
        this.$refs.AccountReports.getData();
        this.$refs.AccountReports.setModalCriteriasToDefault();
    }
  },
  created() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id'); 
    this.$setSessionStorage('report_category', 2); // Receipt
    this.$setSessionStorage('receipt_type', 0); // Cash/Bank
    this.fileUUID = uuid.v4(); 
    this.getData();
  },
};
</script>