<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">
                    {{ $t('Update Expense Transaction') }}
                </h1> 
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-primary">{{ $t('Transactions') }}</li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Expense Transaction') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1" v-if="this.$isCredentialActive(23,'R')">
                <button type="button" class="btn btn-light border border-gray-400" data-bs-toggle="modal" data-bs-target="#kt_modal_transaction_list" @click="onOpenTransactionListModal()" >
                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                    </svg>
                    </span>
                    {{ $t('Transaction List') }}
                </button>
            </div>
        </div>  
        <div class="content flex-row-fluid mt-4" id="kt_content">  
            <!-- <StatsBar></StatsBar> -->
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row p-10">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="row p-4">
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h3>{{ $t('Expense Transaction') }}</h3>
                                            <!-- TO-DO: Item eklendikten sonra read-only olacak -->
                                        </div>
                                        <div class="d-flex justify-content-end py-4 -">
                                            &nbsp;
                                            <router-link to="/ExpenseTransactionCreate" v-if="this.$isCredentialActive(23,'I')">
                                                    <button type="button" class="btn btn-light-primary border border-primary me-3">
                                                        <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                        </span>
                                                        {{ $t('Start New Expense Transaction') }}
                                                    </button>
                                            </router-link> 
                                            <div class="dropdown">
                                                <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="expenseActions" type="button">
                                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                                </svg></span>
                                                    {{ $t('Actions') }}
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="expenseActions"> 
                                                    <li v-if="form.is_accounted == 1 && this.$getUser().is_allow_cancel_accounting_integration"><a class="dropdown-item fs-4 text-danger" href="#" @click="onCancelIntegration()">{{ $t('Cancel Accounting Integration') }}</a></li>
                                                    <li v-if="this.$isCredentialActive(23,'D')"><a class="dropdown-item fs-4 text-danger" href="#" @click="deleteDocket()">{{ $t('Delete') }} </a></li>
                                                </ul> 
                                            </div>
                                            <div class="dropdown ms-3" v-if="this.$isCredentialActive(23,'P')">
                                                    <button class="btn btn-light-warning border border-warning dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="expensePrintActions" type="button">
                                                        <i class="fa fa-print"></i>
                                                        {{ $t('Print Actions') }}
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="expensePrintActions"> 
                                                        <li>
                                                            <a class="dropdown-item fs-4 text-primary py-4" href="#" @click="printReceipt()" 
                                                                data-bs-toggle="modal" data-bs-target="#kt_modal_print">
                                                            <i class="fa fa-print text-primary fs-5"></i>  {{ $t('Print Receipt') }}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item fs-4 text-primary py-4" href="#" @click="downloadReceipt()">
                                                                <i class="fa fa-file text-primary fs-5"></i> {{ $t('Download PDF') }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>  
                                        </div>
                                    </div>
                                    <div class="card-body pb-0">
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label" v-tag="'record_date'">
                                                    {{ $t('Record Date') }}
                                                </label>
                                                <Datepicker name="record_date" :required="this.$checkIfDateFieldRequired ('record_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.record_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" :disabled="this.docketItemsList.length > 0"/>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <div class="row" >
                                <ul class="nav nav-tabs flex-nowrap text-nowrap float-end px-3 border-0" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                            :class="{'active border border-bottom-0 border-gray-300': this.activeSubTab == 'ExpenseItems'}" @click="this.activeSubTab = 'ExpenseItems'"
                                            data-bs-toggle="tab" href="#kt_expense_sub_tab_1" aria-selected="true" role="tab">
                                            {{ $t('Expense Items') }}
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                            :class="{'active border border-bottom-0 border-gray-300': this.activeSubTab == 'RepeatedInvoice'}" @click="this.activeSubTab = 'RepeatedInvoice', getScheduledTransactions()"
                                            data-bs-toggle="tab" href="#kt_expense_sub_tab_2" aria-selected="true" role="tab">{{ $t('Repeated Transactions') }}</a>
                                    </li>
                                    
                                </ul>
                                <div id="kt_expense_sub_tab_1" class="tab-pane fade show active" v-if="this.activeSubTab == 'ExpenseItems'" role="tabpanel">
                                    <div class="col-lg-12 mt-0">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300 mb-10">
                                            <div class="card-body pb-0">
                                                <div class="p-0">
                                                    <div class="d-flex flex-stack flex-wrap mb-2">
                                                        <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                            <button type="button" class="btn btn-sm btn-success fw-bold required me-3"
                                                                    data-bs-toggle="modal" 
                                                                    data-bs-target="#kt_modal_add_item" 
                                                                    @click="openDocketModal('addItem')"
                                                                    data-kt-menu-trigger="click" 
                                                                    data-kt-menu-placement="bottom-end">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                            viewBox="0 0 24 24" fill="none">
                                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                                fill="currentColor">
                                                                            </rect>
                                                                        </svg>
                                                                    </span> 
                                                                    {{ $t('Add To List') }}
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" 
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#kt_modal_add_item" 
                                                                    @click="openDocketModal('editItem')"
                                                                    :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                        </svg>
                                                                    </span> 
                                                                    {{ $t('Edit') }}
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-light btn-active-warning me-3"
                                                                @click="deleteDocketItem()"
                                                                :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                                <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                                {{ $t('Remove') }}
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn btn-light btn-active-danger" @click="deleteAllDocketItems()">
                                                                <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                                {{ $t('Remove All (!)') }}
                                                            </button> 
                                                        </div>
                                                        <div class="flex-row">
                                                            <div class="d-flex justify-content-end">
                                                                <span class="fw-bold text-gray-600 me-5">{{ $t('Docket Number') }}: </span>
                                                                <span class="fw-boldest text-black-600"> {{this.form.docket_number}} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="pb-10">
                                                        <DxDataGrid id="gridContainer" 
                                                            :show-borders="true" 
                                                            key-expr="id" 
                                                            :data-source="docketItemsList" 
                                                            :allow-column-reordering="true"  
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true"
                                                            :customize-columns="onCustomizeColumn"
                                                            @exporting="this.$onExporting($event , 'Expense-transaction-items')"
                                                            :scrolling="{ mode: 'virtual' }"
                                                            @option-changed="handlePropertyChange"> 

                                                            <DxFilterRow :visible="true"/>
                                                            <DxHeaderFilter :visible="true"/>
                                                            <DxGroupPanel :visible="true"/>      
                                                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                            <DxPaging :page-size="pageSize"/>
                                                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                                            <DxSearchPanel :visible="true"/> 
                                                            <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                            <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/> 
                                                            <DxColumn data-field="transaction_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="transaction_date-cell-template"/>   
                                                            <DxColumn data-field="cash_bank_account_name" :caption="$t('Expense Account')" header-cell-template="expense-account-header" cell-template="cash_bank_account_name-cell-template"/>   
                                                            <DxColumn data-field="payment_account_name" :caption="$t('Payment Account')" header-cell-template="payment-account-header" cell-template="payment_account_name-cell-template"/>   
                                                            <DxColumn data-field="transaction_number" :caption="$t('Transaction No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>  
                                                            <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/>
                                                            <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>  
                                                            <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                                            <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" header-cell-template="local-amount-header" cell-template="amount_local-cell-template"/> 
                                                            <DxColumn data-field="payment_status_name" :caption="$t('Payment Status')" alignment="left" cell-template="payment_status-cell-template"/> 
                                                            <DxColumn data-field="description" :caption="$t('Description')" cell-template="description-cell-template"/> 


                                                            <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                                            <template #headerLocalTemplate="{ data: data }">
                                                                <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                            </template>
                                                            
                                                            <template #transaction-date-header>
                                                                <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                                            </template>
                                                            <template #payment-account-header>
                                                                <div>{{ $t('Payment') }}</div><div>{{ $t('Account') }}</div>
                                                            </template>
                                                            <template #expense-account-header>
                                                                <div>{{ $t('Expense') }}</div><div>{{ $t('Account') }}</div>
                                                            </template>
                                                            <template #transaction-no-header>
                                                                <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                                            </template>
                                                            <template #gross-amount-header>
                                                                <div>{{ $t('Gross') }}</div><div>{{ $t('Amount') }}</div>
                                                            </template>
                                                            <template #gross-local-amount-header>
                                                                <div>{{ $t('Gross Local') }}</div><div>{{ $t('Amount') }}</div>
                                                            </template>

                                                            <template #is-selected-cell-template="{ data }">
                                                                <span>
                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #id-cell-template="{ data }">
                                                                <a href="#" class="text-gray-800 text-hover-primary">
                                                                    {{ getRowNumber(data) }}
                                                                </a>
                                                            </template>
                                                            <template #transaction_date-cell-template="{ data }">
                                                                    <span class="text-gray-800 p-3 align-middle text-end" > {{ previewDate(data.data.transaction_date) }}</span>
                                                            </template>
                                                            <template #cash_bank_account_name-cell-template="{ data }">
                                                                    <span class="text-gray-800 fw-bold p-3 align-middle" > {{ data.data.cash_bank_account_name }}</span>
                                                            </template>
                                                            <template #payment_account_name-cell-template="{ data }">
                                                                    <span class="text-gray-800 fw-bold p-3 align-middle" > {{ data.data.payment_account_name }}</span>
                                                            </template>
                                                            <template #transaction_number-cell-template="{ data }">
                                                                <span class="text-gray-800 p-3 align-middle" >{{ data.data.transaction_number }}</span>
                                                            </template>
                                                            <template #amount-cell-template="{ data }">
                                                                    <span class="text-gray-800 text-end p-3 align-middle"> {{data.data.amount}}</span>
                                                            </template>
                                                            <template #currency-cell-template="{ data }">
                                                                    <span class="text-gray-800 p-3 align-middle"> {{data.data.currency}}</span>
                                                            </template>
                                                            <template #rate-cell-template="{ data }">
                                                                    <span class="text-gray-800 text-end p-3 align-middle"> {{data.data.rate}} </span>
                                                            </template>
                                                            <template #amount_local-cell-template="{ data }">
                                                                    <span class="text-gray-800 text-end p-3 align-middle"> {{data.data.amount_local}} </span>
                                                            </template>
                                                            <template #payment_status-cell-template="{ data }">
                                                                <span class="text-gray-800 p-3 align-middle"> {{ data.data.payment_status_name }} </span>
                                                            </template>
                                                            <template #description-cell-template="{ data }">
                                                                    <span class="text-gray-800 text-end p-3 align-middle"> {{data.data.description}} </span>
                                                            </template>
                                                            <DxSummary :calculate-custom-summary="setSummaries">
                                                                <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                                    <DxGroupItem
                                                                        :show-in-group-footer="true"
                                                                        name="amount_local"
                                                                        summary-type="custom"
                                                                        show-in-column="amount_local"
                                                                        display-format="{0}"
                                                                    /> 
                                                                    <DxTotalItem name="amount_local"
                                                                        summary-type="custom"
                                                                        show-in-column="amount_local"
                                                                    />
                                                                </DxSummary>
                                                                    <DxStateStoring
                                                                        :enabled="true"
                                                                        type="custom"
                                                                        :storage-key="this.storageKey"
                                                                        :ref="dataGrid" 
                                                                        :saving-timeout="1000"
                                                                        :custom-load="LoadDXState"
                                                                    />
                                                    </DxDataGrid>  
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="kt_expense_sub_tab_2" class="tab-pane fade show active" v-if="this.activeSubTab == 'RepeatedInvoice'" role="tabpanel">
                                    <div class="col-lg-12">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                            <div class="card-body"> 
                                                <div class="card card-xxl-stretch card-bordered">
                                                    <div class="p-5">
                                                        <div class="row d-flex flex-stack flex-wrap mb-5">
                                                            <div class="d-flex justify-content-start" data-kt-docs-table-toolbar="base" v-if="scheduledTransactions.length == 0">
                                                                <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                                    data-bs-target="#kt_modal_create_scheduled_operation" @click="onOpenScheduleModal()"
                                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                            viewBox="0 0 24 24" fill="none">
                                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                                fill="currentColor">
                                                                            </rect>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Create Scheduled Transaction') }}
                                                                </button>
                                                            </div>
                                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base" v-if="scheduledTransactions.length > 0 && isPendingTrnExist">
                                                                <button type="button" class="btn btn-sm btn-light-danger btn-active-success me-3" @click="updateScheduledTransaction(null, true)">
                                                                    {{ $t('Cancel Pending Transactions') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                            <div class="table-responsive">
                                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                    key-expr="id" 
                                                                    :data-source="scheduledTransactions" 
                                                                    :allow-column-reordering="true"  
                                                                    :allow-column-resizing="true" 
                                                                    :column-auto-width="true" 
                                                                    :row-alternatin-enabled="true"
                                                                    :hover-state-enabled="true" 
                                                                    :show-row-lines="true" 
                                                                    @row-updating="updateScheduledTransaction"
                                                                    @editing-start="onEditingStart"> 

                                                                    <DxEditing :allow-updating="true" mode="row"/>
                                                                    <DxFilterRow :visible="true"/>
                                                                    <DxHeaderFilter :visible="true"/>
                                                                    <DxGroupPanel :visible="false"/>
                                                                    <DxPaging :page-size="1000"/>
                                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                    <DxScrolling
                                                                        mode="virtual"
                                                                        row-rendering-mode="virtual"
                                                                    />
                                                                    <DxColumn data-field="period_type_name" :caption="$t('Period Type')" :allow-editing="false" /> 
                                                                    <DxColumn data-field="transaction_date" :caption="$t('Transaction Date')"  data-type="datetime" alignment="right" format="dd.MM.yyyy" />
                                                                    <DxColumn data-field="status_name" :caption="$t('Status')" :set-cell-value="setScheduleStatus" cell-template="status_name-cell-template">                                                                                
                                                                        <DxLookup :data-source="scheduleStatusList" value-expr="id" display-expr="text" v-model="scheduleStatus" />
                                                                    </DxColumn> 
                                                                    <DxColumn data-field="remain_day" :caption="$t('Remain Day')" :allow-editing="false" /> 
                                                                    <DxColumn data-field="invoice_date" :caption="$t('Invoice Date')"  data-type="date" alignment="right" format="dd.MM.yyyy" :allow-editing="false" />
                                                                    <DxColumn data-field="invoice_no" :caption="$t('Invoice No')" :allow-editing="false"/> 
                                                                    <DxColumn data-field="invoice_total" :caption="$t('Invoice Total')" :allow-editing="false"/>
                                                                    <DxColumn data-field="description" :caption="$t('Description')" :allow-editing="true" cell-template="description-cell-template"/>
                                                                    
                                                                    <template #status_name-cell-template="{ data }">
                                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                                            {{ data.data.status_name }}
                                                                        </a>
                                                                    </template>
                                                                    <template #description-cell-template="{ data }">
                                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                                                            {{ data.data.description }}
                                                                        </a>
                                                                    </template>
                                                                </DxDataGrid>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="row g-xxl-9">
                                    <!-- <div class="col-lg-12 mt-0">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-header">
                                                <div class="card-title">
                                                    <h3 class="text-gray-800 me-5"> Docket Total </h3>
                                                </div>
                                            </div>
                                            <div class="card-body pt-1">
                                                <form class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                                    <div class="fv-row row">
                                                        <table class="table align-middle table-row-dashed fs-6 gy-5 table-hover">
                                                            <thead>
                                                                <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 fw-bolder text-gray-800"> 
                                                                    <th>Account</th>
                                                                    <th>Currency</th>
                                                                    <th class="text-end">Total Incoming</th>
                                                                    <th class="text-end">Total Outgoing</th>
                                                                    <th class="text-end">Total Balance</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="text-gray-600 fw-bold" v-if="docketSummaryList">
                                                                <tr class="odd" v-for="item in docketSummaryList" v-bind:key="item"> 
                                                                    <td>{{item.cash_bank_account_name}}</td>
                                                                    <td>{{item.currency}}</td>
                                                                    <td class="text-end">{{item.total_incoming_amount}}</td>
                                                                    <td class="text-end">{{item.total_outgoing_amount}}</td>
                                                                    <td class="text-end">{{item.total_balance_amount}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-12" v-if="this.$isCredentialActive(23,'U')">
                                        <div class="d-grid gap-2"> 
                                            <button class="btn btn-primary btn-lg mb-0" type="submit" @click="updateDocket()" data-kt-indicator="on"  :disabled="docketItemsList.length == 0"> 
                                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                </svg>
                                                </span>  
                                                <strong v-if="!this.isDocketSaving"> {{ $t('Update Transaction') }} </strong> 
                                                <span class="indicator-progress" v-if="this.isDocketSaving">
                                                    {{ $t('Saving Transaction. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- ADD TRANSACTION MODAL -->
        <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xxl">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                            <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span> {{ $t('Transaction') }}
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y pt-0" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                        <RequiredFieldOnModal :routeName="this.modalType == 'addItem' ? 'ExpenseTransactionItemCreate' : 'ExpenseTransactionItemUpdate'" class="mt-5"></RequiredFieldOnModal>
                        <form id="expenseItemForm" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                            <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                                <li class="nav-item">
                                    <a class="nav-link cursor-pointer" :class="{'active': this.activeTab == 'Transaction'}" @click="this.activeTab = 'Transaction'"><h4>{{ $t('Transaction') }}</h4></a>
                                </li> 
                                <li class="nav-item" v-if="this.$isModuleActive(3)">
                                    <a class="nav-link cursor-pointer" :class="{'active': this.activeTab == 'Document'}" @click="this.onActivateDocumentsTab()"><h4>{{ $t('Documents') }}</h4></a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" :class="{'show active': this.activeTab == 'Transaction'}">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card card-xxl-stretch mb-6">
                                                        <div class="card-body p-4">    
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'expense_account_id'">
                                                                    {{ $t('Select Expense') }}
                                                                </label>
                                                                <div class="col-lg-7 fv-row fv-plugins-icon-container" 
                                                                    :class="{'col-lg-8': expenseForm.expense_account_id == null || expenseForm.expense_account_id == '-1' || expenseForm.expense_account_id == '', 'pe-0': expenseForm.expense_account_id !== null && expenseForm.expense_account_id !== '-1' && expenseForm.expense_account_id !== ''}">
                                                                    <Select2 v-model="expenseForm.expense_account_id" :options="expenseAccountList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" @select="onChangeExpenseSelection($event);"  name="expense_account_id" v-custom/>          
                                                                </div>
                                                                <div class="col-lg-1 fv-row fv-plugins-icon-container ps-0">       
                                                                    <button type="button" class="btn rounded-start-0 btn-light btn-icon text-hover-primary border border-1 border-gray-300" @click="onOpenAccountReport()"
                                                                        v-if="expenseForm.expense_account_id !== null && expenseForm.expense_account_id !== '-1' && expenseForm.expense_account_id !== '' && expenseForm.expense_account_id !== undefined">
                                                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                    <span v-tag="'branch_id'" >{{ $t('Branch') }}</span>
                                                                    <span v-tag="'expense_center_id'">{{ $t(' / Expense Center') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">                                                                
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <Select2 v-model="expenseForm.branch_id" :options="branchList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="branch_id" v-custom />
                                                                        </div>
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <Select2 v-model="expenseForm.expense_center_id" :options="expenseCenterList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="expense_center_id" v-custom />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="card card-xxl-stretch mb-6">
                                                        <div class="card-body p-4">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'transaction_date'" >
                                                                    {{ $t('Transaction Date') }} 
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <Datepicker :required="this.$checkIfDateFieldRequired('transaction_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="expenseForm.transaction_date"  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply 
                                                                            :clearable="false" :enableTimePicker="false"  @update:modelValue="onChangeCurrency()" name="transaction_date" v-custom />
                                                                        </div> 
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <Select2 v-model="this.expenseForm.currency" :options="this.currencyList" @select="onChangeCurrency()" 
                                                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>                                                        
                                                            <div class="row mb-2"  v-if="this.expenseForm.currency !== this.localCurrency">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'rate'" >
                                                                    {{ $t('Rate') }}
                                                                </label>
                                                                <div class="col-lg-8">
                                                                    <div class="row">
                                                                        <div class="col-lg-6" > 
                                                                            <Select2 v-model="typeSelection" :options="typeList" @select="onChangeRateType()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>   
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <CurrencyInput type="text" class="form-control" v-model="this.expenseForm.rate" :precision="5" @focus="$event.target.select()" name="rate" v-custom />
                                                                            <small v-if="this.expenseForm.rate_date !== null">{{ $t('Rate Date') }}: {{ previewDate(this.expenseForm.rate_date) }}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                    <span v-tag="'transaction_number'" >{{ $t('Transaction No') }}</span>
                                                                    <span v-tag="'ref_number'" >{{ $t(' / Ref No') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <input type="text" class="form-control fw-boldest text-black-600" v-model="expenseForm.transaction_number" name="transaction_number" v-custom >
                                                                        </div>
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <input type="text" class="form-control" v-model="expenseForm.ref_number" name="ref_number" v-custom >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 ">
                                                                    <span v-tag="'total'" >{{ $t('Total') }}</span>
                                                                    <span v-tag="'vat_amount'" >{{ $t(' / VAT') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <div class="input-group mb-3">
                                                                                <CurrencyInput type="text" class="form-control text-end" v-model="expenseForm.amount" @focus="$event.target.select()" @blur="calculateGrandTotal()"  name="total" v-custom />
                                                                                <ErrorMessage name="amount" class="text-danger" />
                                                                                <span class="input-group-text p-1">
                                                                                    <button type="button" class="btn btn-light btn-sm p-1" @click="this.onChangeAmount()">
                                                                                        <i class="bi bi-box-arrow-in-down-left fs-2"></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>  
                                                                        </div>
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <CurrencyInput type="text" class="form-control text-end" v-model="expenseForm.vat_amount" @focus="$event.target.select()" @blur="calculateGrandTotal()" name="vat_amount" v-custom />
                                                                            <ErrorMessage name="amount" class="text-danger" />
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " >
                                                                    {{ $t('Grand Total') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container"> 
                                                                    <CurrencyInput type="text" class="form-control text-end" v-model="expenseForm.grand_total" @focus="$event.target.select()" disabled />
                                                                    <ErrorMessage name="amount" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'description'" >
                                                                    {{ $t('Description') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="input-group">
                                                                        <input type="text" class="form-control" v-model="expenseForm.description" name="description" v-custom >
                                                                        <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                                                            <div class="dropdown dropup">
                                                                                <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;">
                                                                                    <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                                                </button>
                                                                                <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                                                    <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                                                        <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                                                            <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)"
                                                                                                :class="{'text-success': this.expenseForm.description == item.description && item.description !== ''}">
                                                                                                <i class="bi bi-dash fw-boldest" v-if="this.expenseForm.description !== item.description"> </i> 
                                                                                                <i class="bi bi-check-lg fw-boldest text-success" v-if="this.expenseForm.description == item.description && item.description !== ''"> </i>
                                                                                                {{item.text}}  
                                                                                            </a>
                                                                                            <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="card card-xxl-stretch mb-6">
                                                        <div class="card-body p-4">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'payment_status'" >
                                                                    {{ $t('Payment Status') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="expenseForm.payment_status" :options="paymentStatusList"
                                                                    @select="onChangePaymentStatus(this.expenseForm.payment_status)" 
                                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="payment_status" v-custom />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'payment_account_type'" >
                                                                    {{ $t('Payment Account Type') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="expenseForm.payment_account_type" :options="accountTypeFilteredList"
                                                                    @select="onChangeAccountType(this.expenseForm.payment_account_type)" 
                                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="payment_account_type" v-custom /> 
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'payment_account_id'" >
                                                                    {{ $t('Payment Account') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="expenseForm.payment_account_id" :options="accountNameListByCurrency"
                                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="payment_account_id" v-custom /> 
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" v-if="expenseForm.payment_account_type != 3 && expenseForm.payment_account_type != 4 && expenseForm.payment_account_type != '-1'">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'payment_value'" >
                                                                    {{ $t('Payment Value') }} 
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Datepicker :required="this.$checkIfDateFieldRequired('payment_value')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="expenseForm.due_date"  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" name="payment_value" v-custom  />
                                                                </div>
                                                            </div>  
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="card border border-gray-300" id="kt_contacts_list" style="height: 96%" v-if="!this.isContactInfoVisible">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h4 class="text-gray-800">
                                                            {{ $t('Expense Accounts') }}
                                                        </h4> 
                                                    </div>                                            
                                                    <div class="card-toolbar" v-if="this.expenseForm.expense_account_id !== null && this.expenseForm.expense_account_id !== '' && this.expenseForm.expense_account_id != '-1'">
                                                        <i class="bi bi-info-circle text-primary fs-3 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                                    </div>
                                                </div>
                                                <div class="card-body p-5" id="kt_contacts_list_body" v-if="expenseAccountList">
                                                    <div class="scroll-y me-n5 pe-5 mh-500px h-xl-auto">
                                                        <div v-for="(item, index) in expenseAccountList" v-bind:key="item">
                                                            <div class="d-flex flex-stack py-2"  v-if="item.id != -1">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="symbol symbol-40px symbol-circle cursor-pointer">
                                                                        <span v-if="this.expenseForm.expense_account_id !== item.id" class="symbol-label fs-6 fw-bolder">
                                                                            <a href="#" class="btn btn-sm btn-icon text-hover-primary btn-circle border border-1 border-gray-200" 
                                                                                @click="onOpenAccountReport(item)">
                                                                                <i class="fa fa-eye text-gray-600 fs-2"></i>
                                                                            </a>
                                                                        </span>
                                                                        <span v-if="this.expenseForm.expense_account_id == item.id" class="svg-icon svg-icon-success svg-icon-3x">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                                                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div class="ms-4 cursor-pointer" @click="onChangeExpenseSelection(item)">
                                                                        <a class="fs-6 fw-bolder text-gray-800 text-hover-primary mb-2 cursor-pointer" @click="onChangeExpenseSelection(item)">
                                                                            {{item.name}}
                                                                        </a>
                                                                        <div @click="onChangeExpenseSelection(item)" class="fw-bold fs-7 text-muted cursor-pointer">{{item.account_type_name}}
                                                                            <span class="h-20px border-gray-400 border-start mx-3 mt-3"></span>
                                                                            <span @click="onChangeExpenseSelection(item)" class="fw-bold fs-7 text-muted cursor-pointer">{{item.balance}}</span>
                                                                        </div>                                                
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="separator separator-dashed my-3" v-if="item.id != -1 && index !== expenseAccountList.length - 1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card border border-gray-300" id="kt_contacts_list" style="height: 96%" v-if="this.isContactInfoVisible">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h4 class="text-gray-800">
                                                            {{ $t('Account Info') }}
                                                        </h4> 
                                                    </div>
                                                    <div class="card-toolbar m-0 p-0">
                                                        <i class="bi bi-x-lg fs-5 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                                    </div>
                                                </div>
                                                <AccountInfo :selected-account-info="this.selectedAccount"></AccountInfo>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="card">
                                                <div class="d-grid gap-2"> 
                                                    <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" 
                                                        @click="this.addDocketItem()" :disabled="this.isModalButtonDisabled">
                                                        <strong>{{ $t('Add To List') }}</strong> 
                                                    </button>
                                                    <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'" 
                                                        @click="this.updateDocketItem()" :disabled="this.isModalButtonDisabled">
                                                        <strong>{{ $t('Update') }}</strong> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="tab-pane fade" :class="{'show active': this.activeTab == 'Document'}">
                                    <div class="row" v-if="this.activeTab == 'Document'">
                                        <FileManager :location="2" 
                                                     :locationtype="4" 
                                                     :is_transaction="1" 
                                                     :account_a="this.expenseForm.expense_account_id" 
                                                     :account_b="this.expenseForm.payment_account_id" 
                                                     :uuid="this.fileUUID"
                                                     :upload_source="1">
                                        </FileManager>
                                    </div> 
                                </div>
                            </div> 
                        </form>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="modal fade" id="kt_modal_print" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<PrintModal ref="PrintModal" />
		</div> 

        <!-- Transaction List -->
        <div class="modal fade" id="kt_modal_transaction_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <TransactionListModal ref="TransactionListModal" :transaction-type="4" />
        </div>

        <!--AccountReports-->
        <div class="modal" :class="{'fade show d-block': isViewStatementModal, 'd-none': !isViewStatementModal}" id="kt_modal_account_report_selection" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="expenseForm.expense_account_name" :account-id="expenseForm.expense_account_id" :account-type="expenseForm.payment_account_type" />
        </div>

         <!-- Schedule Modal -->
         <div class="modal fade" id="kt_modal_create_scheduled_operation" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">            
            <div class="modal-dialog modal-lg p-9">
                <div class="modal-content modal-rounded">
                    <div class="modal-header py-7 d-flex justify-content-between bg-primary py-3">
                        <h2 class="text-white">{{ $t('Create Scheduled Operation') }}</h2>
                        <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeScheduleModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y m-3" :class="{'overlay overlay-block': this.isSaving == true}">
                        <div class="d-flex flex-column" data-kt-stepper="true">
                            <form id="kt_ecommerce_settings_general_form"
                                class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                <div class="fv-row mb-7 row">                                    
                                    <div class="col-lg-12">
                                        <label class="fw-bold form-label mt-3" >
                                            <span> {{ $t('Period Type') }}</span>
                                        </label>
                                        <Select2 v-model="scheduleForm.period_type" :options="periodTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_create_scheduled_operation .modal-content' }" name="period_type" />
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('Period Count') }}
                                        </label>
                                        <input type="number" class="form-control" name="period_count" v-model="scheduleForm.period_count">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('Start From') }}
                                        </label>
                                        <Datepicker name="transaction_date" textInput :textInputOptions="{ format: 'dd.MM.yyyy HH:mm' }" v-maska="'##.##.#### ##:##'" v-model="scheduleForm.transaction_date" format="dd.MM.yyyy HH:mm" previewFormat="dd.MM.yyyy HH:mm" autoApply :clearable="false" :enableTimePicker="true" />
                                    </div>
                                </div>
                                <div class="fv-row mb-5 row">
                                    <div class="col-md-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('Description') }}
                                        </label>
                                        <textarea name="description" class="form-control" row="1" v-model="scheduleForm.description" id=""></textarea>
                                    </div> 
                                </div>
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-success" @click="this.saveScheduledTransaction()"  data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isSaving"> {{ $t('Create') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isSaving">
                                                {{ $t('Saving Data') }}... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>

                
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem ,DxEditing, DxLookup} from 'devextreme-vue/data-grid';
import { uuid } from 'vue3-uuid';
import PrintModal from '../Modals/PrintModal.vue';
import { saveAs } from 'file-saver';
import TransactionListModal from '../TransactionListModal.vue';

 
export default {
  name: "ExpenseTransactionUpdate",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem, PrintModal, TransactionListModal, DxEditing , DxLookup
  }, 
  data() {
    return {
        userData: this.$getUser(),
        pageSize: null,
        pageIndex: 0,
        fileUUID: null,
        activeTab: 'Transaction',
        isOpenDescriptionDropdown: false,
        isPrintModalOpen: false,
        searchbar: '',
        docketItemsList: [],
        currencyList: [],
        transactionList: [],
        expenseAccountList: [{ id: '-1', text: 'Select an option'}],
        expenseCenterList: [],
        accountNameList: [{ id: '-1', text: 'Select an option'}],
        accountTypeList: [
            { id: '-1', text: 'Select an option'},
            { id: 3, type: 1, text: 'Cash'},
            { id: 4, type: 1, text: 'Bank'},
            { id: 12, type: 2, text: 'Contact'},
            { id: 5, type: 2, text: 'Personnel'},
            { id: 8, type: 2, text: 'Asset'},
            { id: 15, type: 2, text: 'Other Account'}
        ],
        paymentStatusList: [
            { id: 1, text: 'Paid'},
            { id: 2, text: 'To be paid'}
        ],
        accountTypeFilteredList: [{ id: '-1', text: 'Select an option'}],
        branchList: [],
        descriptionTypeList: [],
        localCurrency: null,
        expenseForm: {
            id: null,
            expense_account_id: '-1',
            expense_account_name: null,
            expense_account_type: 1,
            expense_center_id: '-1',
            payment_account_type: '-1',
            payment_account_id: '-1',
            payment_account_name: null,
            transaction_date: moment(),
            transaction_number: null,
            currency: '-1',
            amount: 0.00,
            vat_amount: 0.00,
            grand_total: 0.00,
            rate: 0.00,
            ref_number: null,
            description: null,
            due_date: moment(),
            item_type: 1,
            payment_status: 1,
            branch_id: '-1'
        },
        form: {
            docket_type: 8,
            record_date: moment(),
            docket_number: null, 
            account_name: '-1',
            account_id: '-1',
            is_accounted: 0
        }, 
        modalType: 'addItem',
        docketSummaryList: [],
        isDocketSaving: false,
        isContactInfoVisible: false,
        isDocketItemSaving: false,
        selectedAccount: {},
        isModalButtonDisabled: false,
        menuSelection: '/ExpenseTransactionHelppage',
        iconView : false,
        isSaving: false,
        isViewStatementModal: false,
        scheduledTransactions: [],
        periodTypeList: [
            { id: 1, text: 'Weekly'},
            { id: 2, text: 'Monthly'},
            { id: 3, text: 'Annual'}
        ],
        scheduleForm: {
            period_type: 1,
            transaction_date: moment(),
            period_count: 1,
            description:null,
        },
        scheduleStatusList: [
            { id: 0, text: 'Pending'},
            { id: 1, text: 'Canceled'},
            { id: 2, text: 'Completed'},
            { id: 3, text: 'Failed'}
        ],
        scheduleStatus: 0,
        activeSubTab: 'ExpenseItems',
    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);         
        }
   },
    searchableCustomerList() {
      return this.accountNameList.filter(p => { 
        return p.text.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    accountNameListByCurrency(){ 
        // Cash
        if(this.expenseForm.payment_account_type == 3){ 
            return this.accountNameList.filter(p => { 
                return p.cash_currency == this.expenseForm.currency || p.id == '-1';
            });
        } 
        // Bank
        else if(this.expenseForm.payment_account_type == 4) { 
            return this.accountNameList.filter(p => { 
                return p.bank_currency == this.expenseForm.currency || p.id == '-1';
            });
        }
        else{
            return this.accountNameList;
        }  
    },
   isItemButtonDisabled() {
       return this.docketItemsList.find(item =>item.isSelected == true );
    },
    showPageSizeSelector() {
        return this.docketItemsList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.docketItemsList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    isPendingTrnExist() {
        return this.scheduledTransactions.find(item => item.status == 0 );
    },

  },
  methods: {
    getData() { 
        this.$Progress.start();
        this.isPrintModalOpen = false;
        const parameters = {
            session_id: this.$getUser().session_id,
            account_type: 6 // Expense: 6
        };
        const parametersTwo = { 
            session_id: this.$getUser().session_id,
            list_name: ["currency", "branch", "expense_center"]
        };
        // Get Docket Header ById 
        const parametersThree = { 
            session_id: this.$getUser().session_id,
            docket_header_id: this.$getSessionStorage('record_id')
        };
        const requestOne =  axios.post('/api/CompanyAccounts/GetAccounts', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GeneralLists', parametersTwo, {'Content-Type': 'application/json'});
        const requestThree =  axios.post('/api/Transaction/GetDocketById', parametersThree, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.expenseAccountList = responses[0].data.account_data;
            this.expenseAccountList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });

            this.expenseAccountList.unshift({ id: '-1', text: 'Select an option'}); 
            this.expenseCenterList = responses[1].data.expense_center;
            this.branchList = responses[1].data.branch;
            this.currencyList = responses[1].data.currency;
            this.localCurrency = this.$getUser().currency_code; 
            this.$setSessionStorage('refreshList', true); 
            if (this.localCurrency !== null && this.localCurrency !== '' && this.localCurrency !== undefined) {
                this.expenseForm.currency = this.$getUser().currency_code;
            }
            this.form = responses[2].data.docket_header_detail;
            this.docketItemsList = responses[2].data.docket_items;
            this.docketSummaryList = responses[2].data.docket_summary;
            this.$setSessionStorage('refreshList', true); 
            if(responses[2].data.docket_items.length > 0){
                this.$setSessionStorage('tmp_session_id', responses[2].data.docket_items[0].session_id); 
            }  
            this.getCurrencies();
            this.getScheduledTransactions();
            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();
        });
    },
    setScheduleStatus(newData, value) {
        newData.status = value;
        let status_name = null;
        switch(value) {
            case 0:
                status_name = "Pending"
                break;
            case 1:
                status_name = "Canceled"
                break;
            case 2:
                status_name = "Completed"
                break;
            case 3:
                status_name = "Failed"
                break;
            default: status_name = "";
        }
        newData.status_name = status_name;
    },
    handlePropertyChange(e) {
        if(e.name == "paging") {
            this.pageIndex = e.component.pageIndex();
        }
    },
    onChangePaymentStatus(type) {
        this.expenseForm.payment_account_id = '-1';
        this.accountTypeFilteredList = this.accountTypeList.filter((element) => {
            return element.type == type || element.id == '-1';
        });
        this.expenseForm.payment_account_type = this.accountTypeFilteredList[1].id;
        this.onChangeAccountType(this.expenseForm.payment_account_type, this.expenseForm.payment_account_id);
    },
    openDocketModal(modalMode) {
        const parameters = {
            route: modalMode == 'addItem'  ? 'ExpenseTransactionItemCreate' : 'ExpenseTransactionItemUpdate',
            session_id: this.$getUser().session_id
        };  
        axios.post('/api/FormValidations/GetFormFields', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                sessionStorage.setItem('frm_val', JSON.stringify(response.data));
            }
            this.modalType = modalMode; 
            this.activeTab = 'Transaction';
            this.isContactInfoVisible = false;
            this.isDocketItemSaving = false;
            this.isModalButtonDisabled = false;
            this.isOpenDescriptionDropdown = false;

            // Form öğeleri sıfırlanır.
            this.expenseForm.expense_account_id = '-1';
            this.expenseForm.expense_center_id = '-1';
            this.expenseForm.branch_id = '-1';
            this.expenseForm.payment_account_type = '-1';
            this.expenseForm.payment_account_id = '-1';
            this.expenseForm.payment_account_name = null;
            this.expenseForm.transaction_number = null; 
            this.expenseForm.amount = 0.00;
            this.expenseForm.currency = this.localCurrency;
            this.expenseForm.rate = 0.00;
            this.expenseForm.vat_amount = 0.00;
            this.expenseForm.grand_total = 0.00;
            this.expenseForm.ref_number = null;
            this.expenseForm.description = null;
            this.expenseForm.due_date = moment(this.form.record_date);
            this.expenseForm.transaction_date = moment(this.form.record_date);
            this.expenseForm.branch_id = '-1';
            this.expenseForm.payment_status = 1;

            // Open Docket Modal in Edit Mode.
            if(this.modalType == 'editItem'){   
                this.docketItemsList.forEach((item) => { 
                    if(item.isSelected){ 
                        this.expenseForm.id = item.id; 
                        this.expenseForm.expense_account_id = item.cash_bank_account_id; 
                        this.expenseForm.expense_center_id = (item.expense_center_id == '' || item.expense_center_id == null) ? '-1': item.expense_center_id; 
                        this.expenseForm.branch_id = (item.branch_id == '' || item.branch_id == null) ? '-1': item.branch_id;  
                        this.expenseForm.transaction_number = item.transaction_number; 
                        this.expenseForm.amount = item.amount.replace(/,/g, '');
                        this.expenseForm.rate = item.rate.replace(/,/g, '');
                        this.expenseForm.ref_number = item.ref_number;
                        this.expenseForm.description = item.description;
                        this.expenseForm.transaction_date = moment(item.transaction_date); 
                        this.expenseForm.payment_account_type = item.payment_account_type;
                        this.expenseForm.payment_account_id = item.payment_account_id; 
                        this.expenseForm.due_date = moment(item.due_date); 
                        this.expenseForm.currency = item.currency; 
                        this.expenseForm.vat_amount = item.vat_amount.replace(/,/g, ''); 
                        this.expenseForm.grand_total = parseFloat(item.gross_amount.replace(/,/g, ''));  
                        this.expenseForm.payment_status = item.payment_status;
                        if (item.payment_account_type == 1 || item.payment_account_type == 2) {
                            this.expenseForm.payment_account_type = 12;
                        } 
                        this.fileUUID = item.file_id == null ? uuid.v4() : item.file_id;
                        this.accountTypeFilteredList = this.accountTypeList.filter((element) => {
                            return element.type == item.payment_status || element.id == '-1';
                        });
                        this.onChangeAccountType(this.expenseForm.payment_account_type, this.expenseForm.payment_account_id); 
                    }
                });  
            } else {
                this.accountTypeFilteredList = this.accountTypeList.filter((element) => {
                    return element.type == this.expenseForm.payment_status || element.id == '-1';
                });
                this.expenseForm.payment_account_type = this.accountTypeFilteredList[1].id;
                this.fileUUID = uuid.v4(); 
                this.expenseCenterList.forEach((value) => {
                    if(value.is_default){ this.expenseForm.expense_center_id = value.id; }
                });
                this.branchList.forEach((value) => {
                    if(value.is_default){ this.expenseForm.branch_id = value.id; }
                }); 
                this.onChangeAccountType(this.expenseForm.payment_account_type, this.expenseForm.payment_account_id);  
            } 
            this.onChangeCurrency(true);
        });
    },
    getScheduledTransactions() {
        const parameters = {
          session_id: this.$getUser().session_id,
          offer_header_id: this.$getSessionStorage('record_id'),
          transaction_type : 2
        };  
        axios.post('/api/Offer/GetScheduledTransactions', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.scheduledTransactions = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    updateScheduledTransaction(e, isCancelAll) {
        if(isCancelAll) {
            this.$swal.fire({
                title: "Are you sure you want to cancel all pending transactions?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        event_id: null,
                        transaction_id: this.$getSessionStorage('record_id'),
                        transaction_date: null,
                        status: 0,
                        is_cancel_all: 1,
                        transaction_type: 2,
                        description : null,
                    };
                    axios.post('/api/Offer/UpdateScheduledTransactions', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.getScheduledTransactions();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            });
        } else {
            const parameters = {
                session_id: this.$getUser().session_id,
                event_id: e.oldData.id,
                transaction_id: this.$getSessionStorage('record_id'),
                transaction_date: e.newData.transaction_date ? e.newData.transaction_date  : e.oldData.transaction_date,
                status: e.newData.status ? e.newData.status  : e.oldData.status,
                is_cancel_all: 0,
                transaction_type: 2,
                description : e.newData.description ? e.newData.description : e.oldData.description,

            };
            parameters.transaction_date = moment(parameters.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss');
            axios.post('/api/Offer/UpdateScheduledTransactions', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.getScheduledTransactions();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    },
    onOpenScheduleModal() {
        this.scheduleForm = {            
            period_type: 1,
            transaction_date: moment(),
            period_count: 1,
            description:null
        };
    },
    onEditingStart(e) {        
        this.scheduleStatus = e.data.status;
        if (e.data.status_name !== 'Pending') {
            e.cancel = true;  
        }
    },
    saveScheduledTransaction() {
        const parameters = {
            session_id: this.$getUser().session_id,
            transaction_id: this.$getSessionStorage('record_id'),
            period_type: this.scheduleForm.period_type,
            transaction_date: this.scheduleForm.transaction_date,
            period_count: this.scheduleForm.period_count,
            transaction_type: 2,  //expense type
            description : this.scheduleForm.description
        };  
        parameters.transaction_date = moment(parameters.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss');        
        this.isSaving = true;
        const that = this;
        axios.post('/api/Offer/CreateScheduledTransactions', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.scheduledTransactions = response.data;
              this.$refs.closeScheduleModal.click();
           }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isSaving = false;
            }, 500);
        });
    },
    getRowNumber(data) {
      return data.rowIndex + 1;
    },
    onChangeAccountType(accountType, accountId) {
      this.isContactInfoVisible = false;
      const params = {
        session_id : this.$getUser().session_id,
        account_type : accountType
      };
      if (params.account_type == '-1') {
        this.accountNameList = [];
        this.accountNameList.unshift({ id: '-1', text: 'Select an option'});
        this.getDescriptions(function() {});
        return;
      }
      this.$Progress.start();
      axios.post('api/CompanyAccounts/GetAccounts', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.accountNameList = response.data.account_data;
            this.accountNameList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });

            this.accountNameList.forEach((item) => {
                item.text = item.text + ' (' + item.account_type_name + ')';

                if(item.id == this.expenseForm.expense_account_id) { 
                    this.expenseForm.expense_account_name = item.text; 
                    this.expenseForm.expense_account_type = item.account_type; 
                }
            });
            this.accountNameList.unshift({ id: '-1', text: 'Select an option'});
            if (accountId !== null && accountId !== undefined && accountId !== '') {
                this.expenseForm.payment_account_id = accountId;
            } else {
                this.expenseForm.payment_account_id = '-1';
            }
            this.getDescriptions(function() {});
            this.$Progress.finish();
        })
        .catch(function () {
            this.$Progress.finish();
        });  
    },
    setSummaries(options){
        if(options.name == "amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){ 
                var amount_local = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amount_local); 
            }
            else if(options.summaryProcess === 'finalize'){
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        } 
    },
    onSelectItem(item) {
        this.docketItemsList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });
    },
    onChangeAmount() {
        if(this.expenseForm.expense_account_id == null || this.expenseForm.expense_account_id == '' || this.expenseForm.expense_account_id == '-1' || this.expenseForm.expense_account_id == undefined) return;
        const parameters = {
          session_id: this.$getUser().session_id,
          account_id: this.expenseForm.expense_account_id
        };  
        axios.post('/api/CompanyAccounts/GetAccountBalance', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.expenseForm.amount = this.expenseForm.currency !== this.localCurrency ? Math.abs(parseFloat(response.data.total_balance.replaceAll(',', ''))) : Math.abs(parseFloat(response.data.total_balance_local.replaceAll(',', '')));
                this.calculateGrandTotal();
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onChangeRateType(){
        const params = {
            session_id: this.$getUser().session_id,
            rate_date: moment(this.expenseForm.transaction_date),
            bank_id: 1, //EUROPEAN BANK
            currency: this.expenseForm.currency
        };
        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.length > 0){
                this.rateList = response.data;
                this.form.rate_list_id = response.data[0].id;
                this.expenseForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                this.expenseForm.rate_date = response.data[0].rate_date;
            }
            else{
                this.expenseForm.rate = 1;
                this.expenseForm.rate_date = null;
                this.rateList = null;
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    onChangeCurrency(noWriteRate) {
        let originalRate = this.expenseForm.rate;
        this.expenseForm.rate = 1;
        this.expenseForm.payment_account_id = '-1';
        if (this.expenseForm.currency == this.localCurrency) {return;}

        axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
                .then((typeResponse) => {
                        this.typeList = typeResponse.data;
                        this.typeList.forEach((item) => { 
                            if(item.is_default == 1){
                                this.typeSelection = item.id;
                            }
                        });  

                        this.$Progress.start();
                        const params = {
                            session_id: this.$getUser().session_id,
                            rate_date: moment(this.expenseForm.transaction_date),
                            bank_id: 1, //EUROPEAN BANK
                            currency: this.expenseForm.currency
                        };
                        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                        var source = this.typeList.find((element) => element.id == this.typeSelection);
                        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
                        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                        .then((response) => {
                            if(response.data.length > 0){
                                this.rateList = response.data;
                                this.form.rate_list_id = response.data[0].id;
                                this.expenseForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                                this.expenseForm.rate_date = response.data[0].rate_date;
                            }
                            else{
                                this.expenseForm.rate = 1;
                                this.expenseForm.rate_date = null;
                            }
                            if(noWriteRate){
                                this.expenseForm.rate = originalRate;
                            }
                            this.$Progress.finish();
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.$Progress.finish();
                        }); 

                })
                .catch(function (error) {
                    console.log(error); 
                });  
    },
    addDocketItem(){
        this.$checkSelect2Validation('expenseItemForm');
        if(!document.forms['expenseItemForm'].reportValidity()){
            return;
        }
        if(this.expenseForm.transaction_date == null || this.expenseForm.transaction_date == '' || this.expenseForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if(this.expenseForm.due_date == null || this.expenseForm.due_date == '' || this.expenseForm.due_date == undefined) {
            this.$swal("Warning", "Due Date must be valid..!", 'info');
            return;
        }
        if (this.expenseForm.amount == null || this.expenseForm.amount == '' || this.expenseForm.amount == undefined) { this.expenseForm.amount = 0; }
        if (this.expenseForm.vat_amount == null || this.expenseForm.vat_amount == '' || this.expenseForm.vat_amount == undefined) { this.expenseForm.vat_amount = 0; }
        if (this.expenseForm.grand_total == null || this.expenseForm.grand_total == '' || this.expenseForm.grand_total == undefined) { this.expenseForm.grand_total = 0; }
        if (this.expenseForm.rate == null || this.expenseForm.rate == '' || this.expenseForm.rate == undefined) { this.expenseForm.rate = 0; }
        this.isModalButtonDisabled = true;
        this.isDocketItemSaving = true;
        const that = this;
        const params = { 
            cash_bank_account_id: this.expenseForm.expense_account_id,
            expense_center_id: this.expenseForm.expense_center_id,
            transaction_date: this.expenseForm.transaction_date,
            transaction_number: this.expenseForm.transaction_number,
            currency: this.expenseForm.currency,
            amount: this.expenseForm.amount,
            rate: this.expenseForm.rate,
            ref_number: this.expenseForm.ref_number,
            description: this.expenseForm.description,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            payment_account_id: this.expenseForm.payment_account_id,
            due_date: this.expenseForm.due_date,
            vat_amount: this.expenseForm.vat_amount,
            grand_total: this.expenseForm.grand_total,
            item_type: 1, // Expensede her zaman 1 iletilir
            branch_id: this.expenseForm.branch_id,
            file_id: this.fileUUID,
            docket_type: this.form.docket_type
        };
        params.transaction_date = moment(params.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        params.due_date = moment(params.due_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        params.file_id = params.file_id == '' ? params.file_id = null :  params.file_id;
        if(params.expense_center_id == -1){ params.expense_center_id = null; }
        if(params.branch_id == -1){ params.branch_id = null; }
        if(params.cash_bank_account_id == -1){ params.cash_bank_account_id = null; }
        axios.post('api/Transaction/AddItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
             if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                if(response.data.docket_items.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.docket_items[0].session_id);
                    this.docketSummaryList = response.data.docket_summary;
                    this.docketItemsList = response.data.docket_items;
                    this.$refs.closeModal.click();
                }  
            } 
            this.isDocketItemSaving = false;
        })
        .catch(function () {
            this.isDocketItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isDocketItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });   
    },
    updateDocketItem(){
        this.$checkSelect2Validation('expenseItemForm');
        if(!document.forms['expenseItemForm'].reportValidity()){
            return;
        }
        if(this.expenseForm.transaction_date == null || this.expenseForm.transaction_date == '' || this.expenseForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if(this.expenseForm.due_date == null || this.expenseForm.due_date == '' || this.expenseForm.due_date == undefined) {
            this.$swal("Warning", "Due Date must be valid..!", 'info');
            return;
        }
        if (this.expenseForm.amount == null || this.expenseForm.amount == '' || this.expenseForm.amount == undefined) { this.expenseForm.amount = 0; }
        if (this.expenseForm.vat_amount == null || this.expenseForm.vat_amount == '' || this.expenseForm.vat_amount == undefined) { this.expenseForm.vat_amount = 0; }
        if (this.expenseForm.grand_total == null || this.expenseForm.grand_total == '' || this.expenseForm.grand_total == undefined) { this.expenseForm.grand_total = 0; }
        if (this.expenseForm.rate == null || this.expenseForm.rate == '' || this.expenseForm.rate == undefined) { this.expenseForm.rate = 0; }
        this.isModalButtonDisabled = true;
        this.isDocketItemSaving = true;
        const that = this;
        const params = { 
            id: this.expenseForm.id,
            cash_bank_account_id: this.expenseForm.expense_account_id,
            expense_center_id: this.expenseForm.expense_center_id,
            transaction_date: this.expenseForm.transaction_date,
            transaction_number: this.expenseForm.transaction_number,
            currency: this.expenseForm.currency,
            amount: this.expenseForm.amount,
            rate: this.expenseForm.rate,
            ref_number: this.expenseForm.ref_number,
            description: this.expenseForm.description,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            payment_account_id: this.expenseForm.payment_account_id,
            due_date: this.expenseForm.due_date,
            vat_amount: this.expenseForm.vat_amount,
            grand_total: this.expenseForm.grand_total,
            item_type: 1, // Expensede her zaman 1 iletilir,
            branch_id: this.expenseForm.branch_id,
            file_id: this.fileUUID,
            docket_type: this.form.docket_type
        };
        params.transaction_date = moment(params.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        params.due_date = moment(params.due_date).format('YYYY-MM-DD[T]HH:mm:ss');
        params.file_id = params.file_id == '' ? params.file_id = null :  params.file_id; 
        if(params.expense_center_id == -1){ params.expense_center_id = null; }
        if(params.branch_id == -1){ params.branch_id = null; }
        if(params.cash_bank_account_id == -1){ params.cash_bank_account_id = null; }
        axios.post('api/Transaction/UpdateItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
             if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                if(response.data.docket_items.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.docket_items[0].session_id);
                    this.docketSummaryList = response.data.docket_summary;
                    this.docketItemsList = response.data.docket_items; 
                    this.$refs.closeModal.click();
                }  
            }
            this.isDocketItemSaving = false;
        })
        .catch(function () {
            this.isDocketItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isDocketItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        }); 
    },
    deleteDocketItem() {
         this.$swal.fire({
            title: "Are you sure you want to delete this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.$Progress.start();
                var docketId = null; 
                this.docketItemsList.forEach((item) => { 
                    if(item.isSelected){ 
                        docketId = item.id; 
                    }
                });
                const params = { 
                    id: docketId, 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    docket_type: this.form.docket_type
                };
                axios.post('api/Transaction/DeleteItem', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        if(response.data.docket_items.length > 0){
                            this.$setSessionStorage('tmp_session_id', response.data.docket_items[0].session_id);  
                        }  
                        this.docketSummaryList = response.data.docket_summary;
                        this.docketItemsList = response.data.docket_items;
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        }); 
    },
    deleteAllDocketItems() {
         this.$swal.fire({
            title: "Are you sure you want to delete all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.$Progress.start();
                const params = { 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    docket_type: this.form.docket_type
                };
                axios.post('api/Transaction/DeleteAllItems', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.$removeSessionStorage('tmp_session_id'); 
                        this.docketSummaryList = [];
                        this.docketItemsList = [];
                        this.$refs.closeModal.click();
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        }); 
    },
    updateDocket(){
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.isDocketSaving = false;
        this.$swal.fire({
            title: "Are you sure you want to update this Transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                this.isDocketSaving = true; 
                const that = this;
                const params = { 
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    record_date: this.form.record_date, 
                    docket_number: this.form.docket_number
                };  
                params.record_date = moment(params.record_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                axios.post('api/Transaction/UpdateDocket', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$removeSessionStorage('tmp_session_id');
                        this.$setSessionStorage('refreshList', true); 
                        this.getData();
                        this.$swal("Success", "Transaction updated succesfully..!", 'success');
                        this.isDocketSaving = false;
                        this.$Progress.finish();
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () { 
                    this.$Progress.finish(); 
                    new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isDocketSaving = false;
                        that.$Progress.finish(); 
                    }, 500);
                }); 
            }
        }); 
    },
    deleteDocket(){
        this.$swal.fire({
            title: "Are you sure you want to delete this transaction record?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.$Progress.start(); 
                const params = { 
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id
                };  
                axios.post('api/Transaction/DeleteDocket', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$setSessionStorage('refreshList', true);    
                        this.$router.push({ name: 'ExpenseTransactionCreate' });
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        });
    },
    calculateGrandTotal(){ 
        this.expenseForm.grand_total = parseFloat(this.expenseForm.vat_amount) + parseFloat(this.expenseForm.amount) ;
    },
    onChangeExpenseSelection(selection){
        this.expenseForm.expense_account_id = selection.id;
        this.expenseForm.branch_id = (selection.branch_id == '' || selection.branch_id == null) ? '-1' : selection.branch_id;
        this.expenseForm.expense_center_id = (selection.expense_center_id == '' || selection.expense_center_id == null) ? '-1' : selection.expense_center_id;  
        this.getAccountDetail(selection.id);
    },
    getCurrencies(){
        const params = {
            session_id: this.$getUser().session_id
        };    
        axios.post('/api/Currency/GetList', params, {'Content-Type': 'application/json'})
             .then((response) => { 
                this.currencyList = response.data;
                this.$Progress.finish();
             })
             .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
             });
    },
    getAccountDetail(id) {
        this.expenseForm.amount = 0.00;
        this.expenseForm.grand_total = 0.00;
        this.expenseForm.vat_amount = 0.00;
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            response.data.account_id = this.$unlock(response.data.account_id);
            response.data.name = this.$unlock(response.data.name);
            response.data.qr_code = this.$unlock(response.data.qr_code);
            response.data.bank_short_code = this.$unlock(response.data.bank_short_code);
            response.data.bank_account_number = this.$unlock(response.data.bank_account_number);
            response.data.customer_account_number = this.$unlock(response.data.customer_account_number);
            response.data.customer_contact_name = this.$unlock(response.data.customer_contact_name);
            response.data.customer_email = this.$unlock(response.data.customer_email);
            response.data.customer_mobile_phone = this.$unlock(response.data.customer_mobile_phone);
            response.data.customer_phone = this.$unlock(response.data.customer_phone);
            response.data.customer_postcode = this.$unlock(response.data.customer_postcode);
            response.data.customer_address = this.$unlock(response.data.customer_address);
            response.data.customer_country = this.$unlock(response.data.customer_country);
            response.data.customer_city = this.$unlock(response.data.customer_city);
            response.data.customer_vat_number = this.$unlock(response.data.customer_vat_number);
            response.data.customer_phone_country_code = this.$unlock(response.data.customer_phone_country_code);
            response.data.customer_land_phone_country_code = this.$unlock(response.data.customer_land_phone_country_code);
            response.data.customer_district = this.$unlock(response.data.customer_district);
            response.data.customer_x_coordinate = this.$unlock(response.data.customer_x_coordinate);
            response.data.customer_y_coordinate = this.$unlock(response.data.customer_y_coordinate);
            response.data.customer_uprn = this.$unlock(response.data.customer_uprn);
            response.data.customer_udprn = this.$unlock(response.data.customer_udprn);
            response.data.personnel_account_number = this.$unlock(response.data.personnel_account_number);
            response.data.personnel_contact_name = this.$unlock(response.data.personnel_contact_name);
            response.data.personnel_email = this.$unlock(response.data.personnel_email);
            response.data.personnel_mobile_phone = this.$unlock(response.data.personnel_mobile_phone);
            response.data.personnel_phone = this.$unlock(response.data.personnel_phone);
            response.data.personnel_postcode = this.$unlock(response.data.personnel_postcode);
            response.data.personnel_address = this.$unlock(response.data.personnel_address);
            response.data.personnel_country = this.$unlock(response.data.personnel_country);
            response.data.personnel_city = this.$unlock(response.data.personnel_city);
            response.data.personnel_nino = this.$unlock(response.data.personnel_nino);
            response.data.personnel_phone_country_code = this.$unlock(response.data.personnel_phone_country_code);
            response.data.personnel_land_phone_country_code = this.$unlock(response.data.personnel_land_phone_country_code);
            response.data.personnel_district = this.$unlock(response.data.personnel_district);
            response.data.personnel_x_coordinate = this.$unlock(response.data.personnel_x_coordinate);
            response.data.personnel_y_coordinate = this.$unlock(response.data.personnel_y_coordinate);
            response.data.personnel_uprn = this.$unlock(response.data.personnel_uprn);
            response.data.personnel_udprn = this.$unlock(response.data.personnel_udprn);
            response.data.personnel_position_name = this.$unlock(response.data.personnel_position_name);
            response.data.supplier_account_number = this.$unlock(response.data.supplier_account_number);
            response.data.supplier_contact_name = this.$unlock(response.data.supplier_contact_name);
            response.data.supplier_email = this.$unlock(response.data.supplier_email);
            response.data.supplier_mobile_phone = this.$unlock(response.data.supplier_mobile_phone);
            response.data.supplier_phone = this.$unlock(response.data.supplier_phone);
            response.data.supplier_postcode = this.$unlock(response.data.supplier_postcode);
            response.data.supplier_address = this.$unlock(response.data.supplier_address);
            response.data.supplier_country = this.$unlock(response.data.supplier_country);
            response.data.supplier_city = this.$unlock(response.data.supplier_city);
            response.data.supplier_vat_number = this.$unlock(response.data.supplier_vat_number);
            response.data.supplier_phone_country_code = this.$unlock(response.data.supplier_phone_country_code);
            response.data.supplier_land_phone_country_code = this.$unlock(response.data.supplier_land_phone_country_code);
            response.data.supplier_district = this.$unlock(response.data.supplier_district);
            response.data.supplier_x_coordinate = this.$unlock(response.data.supplier_x_coordinate);
            response.data.supplier_y_coordinate = this.$unlock(response.data.supplier_y_coordinate);
            response.data.supplier_uprn = this.$unlock(response.data.supplier_uprn);
            response.data.supplier_udprn = this.$unlock(response.data.supplier_udprn);
            response.data.other_contact_name = this.$unlock(response.data.other_contact_name);
            response.data.other_email = this.$unlock(response.data.other_email);
            response.data.other_mobile_phone = this.$unlock(response.data.other_mobile_phone);
            response.data.other_phone = this.$unlock(response.data.other_phone);
            response.data.other_phone_country_code = this.$unlock(response.data.other_phone_country_code);
            response.data.other_land_phone_country_code = this.$unlock(response.data.other_land_phone_country_code);
            this.selectedAccount = response.data;
            this.expenseForm.expense_account_name = response.data.name;
            this.expenseForm.expense_account_type = response.data.account_type;
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    getDescriptions(callback) {
        this.isOpenDescriptionDropdown = false;
        if (this.modalType == 'addItem') { this.expenseForm.description = null; }
        const params = {
            session_id: this.$getUser().session_id,
            description_location: 5, // Expense
            location_detail: 0,
            account_type: 0,
            sales_type: 0
        };
        axios.post('/api/Helper/GetDescriptionTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.descriptionTypeList = response.data;
            this.descriptionTypeList.forEach(element => {
                if (element.type_id == 2 && this.modalType == 'addItem') {
                    this.expenseForm.description = element.description;
                }
            });
            if(typeof callback == 'function'){
                callback();
            }
        });
    },
    onSelectDescription(item) {
        this.expenseForm.description = item.description;
        this.isOpenDescriptionDropdown = false;
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                    if(column.dataField == 'amount_local'){ 
                         column.caption =  'Local Amount';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });  
            } 
        } 
    },
    onActivateDocumentsTab(){
        this.activeTab = 'Document';
        if(this.fileUUID == null || this.fileUUID == '' || this.fileUUID == undefined){
            this.fileUUID = uuid.v4();         
        }
    },
    printReceipt(){
        if(!this.$isCredentialActive(23,'P')) return;
        this.$refs.PrintModal.isPrintModalOpen = true;
        this.$refs.PrintModal.isFrameLoading = true;
        this.$refs.PrintModal.documentName = 'Expense Transaction' + '-' + this.form.docket_number;
        this.$refs.PrintModal.getData();
    },
    downloadReceipt(){
        if(!this.$isCredentialActive(23,'P')) return;
        const parameters = {
            session_id: this.$getUser().session_id,
            category: 2, // Receipt
            receipt_type: 3 // Receipt Type : Expense
        };
        axios.post('/api/Reports/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'}).then((responseReport) => { 
            this.designList = responseReport.data.report_designs;
            this.designSelection = responseReport.data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.reportType = item.category;
                }
            });

            if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
            {
                fetch('https://localhost:5001/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.reportType)
                    .then(response => response.blob())
                    .then(data => {
                        saveAs(data, 'Expense Transaction' + '-' + this.form.docket_number + '.pdf');
                    });
            }
            else{
                fetch('/reports/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.reportType)
                    .then(response => response.blob())
                    .then(data => {
                        saveAs(data, 'Expense Transaction' + '-' + this.form.docket_number + '.pdf');
                    });
            } 
        })
        .catch(function (error) {
            console.log(error); 
        });
    }, 
    onCancelIntegration() {
        this.$swal.fire({
            title: "Are you sure you want to cancel accounting integration?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const params = {
                    session_id: this.$getUser().session_id,
                    header_id: this.$getSessionStorage('record_id')
                };
                axios.post('api/AccountingIntegration/CancelSingleIntegration', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$swal("Success", "Accounting Integration canceled..!", 'success');  
                        this.getData();
                    }
                })
                .catch(function (error) { 
                    console.log(error)
                });  
            }
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/ExpenseTransactionHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    onOpenTransactionListModal() {
        this.$refs.TransactionListModal.getData();
    },
    onOpenAccountReport(item){
        let accountInfo = {
            id: (item !== null && item !== undefined && item !== '') ? item.id : this.expenseForm.expense_account_id, 
            name: (item !== null && item !== undefined && item !== '') ? item.name : this.expenseForm.expense_account_name
        };
        this.isViewStatementModal = true;
        this.$refs.AccountReports.getData(accountInfo);
        this.$refs.AccountReports.setModalCriteriasToDefault();
    }
  },
  created() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$setSessionStorage('report_category', 2); // Receipt
    this.$setSessionStorage('receipt_type', 3); // Expense
    this.$removeSessionStorage('tmp_session_id'); 
    this.fileUUID = uuid.v4(); 
    this.getData();
  },
};
</script>