<template> 
  <div> 
    <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Cheque Account List') }}
        </h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                </a>
            </li> 
            <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
            <li class="breadcrumb-item text-gray-600">{{ $t('Cheque Account List') }}
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </li> 
        </ul> 
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(19,'R')">
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                            </template>
                        </Popper>
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getLists()" data-kt-indicator="on" :disabled="!this.$getUser().is_allow_list_all_data && (this.form.payment_account_id == null || this.form.payment_account_id == undefined || this.form.payment_account_id == '-1')" >
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> {{ $t('List Cheques') }}</strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <div class="row p-3">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2 bg-light">
                        <div class="fv-row mb-7 row">
                            <div class="col-lg-2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>{{ $t('Start Due Date') }}</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" 
                                    inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                            </div>
                            <div class="col-lg-2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>{{ $t('End Due Date') }}</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date"
                                    inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                            </div>
                            <div class="col-lg-2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>{{ $t('Cheque Type') }}</span>
                                </label>
                                <Select2 v-model="form.transaction_type" :options="transactionTypeList"  @select="this.form.bank_account_id = '-1'" :settings="{width: '100%'}" />
                            </div>
                            <div class="col-lg-2" v-if="this.form.transaction_type == 1">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Bank') }}
                                </label>                                                            
                                <Select2 v-model="form.bank_account_id" :options="bankList" :settings="{ width: '100%' }" />
                            </div>
                            <div class="col-lg-2" v-if="this.form.transaction_type == 2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Cheque Account') }}
                                </label>                                                            
                                <Select2 v-model="form.bank_account_id" :options="bankAccountList" :settings="{ width: '100%' }" />
                            </div>
                            <div class="col-lg-2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Position') }} 
                                </label>                                                            
                                <Select2 v-model="form.last_position" :options="lastPositionList" :settings="{ width: '100%' }"/>
                            </div>
                            <div class="col-lg-2" v-if="this.$getUser().is_allow_list_all_data">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Account') }}
                                </label>
                                <Select2 v-model="form.payment_account_id" :options="filteredAccountNameList" :settings="{ width: '100%'}"/>
                            </div>
                            <div class="col-lg-3" v-if="!this.$getUser().is_allow_list_all_data">
                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_account_id'">
                                    {{ $t('Account') }}
                                </label>
                                <div class="input-group">
                                    <button type="button" class="btn rounded-start-0 btn-light btn-icon text-hover-primary border border-1 border-gray-300 z-index-0" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()"
                                        v-if="form.payment_account_id !== null && form.payment_account_id !== '-1' && form.payment_account_id !== '' && form.payment_account_id !== undefined">
                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                    </button>
                                    <input type="search" class="form-control form-control-lg" name="payment_account_name" v-model="form.payment_account_name" v-custom @input="form.payment_account_id = null" :placeholder="$t('Please type an account name and click on search button or press enter')" @keyup.enter="onClickSearchButton()">
                                    <button ref="searchAccountButton" type="button" class="btn btn-sm btn-light-primary border border-gray-300 z-index-0" data-bs-toggle="modal" data-bs-target="#kt_search_account_modal" 
                                        data-kt-indicator="on" @click="openSearchModal(form.payment_account_name)">
                                        <i class="fa fa-search"></i>
                                        {{ $t('Search') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                    <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                        <DxDataGrid id="gridContainer"
                            :show-borders="true" 
                            key-expr="id" 
                            :data-source="lists" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true"
                            :ref="'dataGrid'"
                            @exporting="this.$onExporting($event , 'Cheque-Account-List')"> 

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="pageSize"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                            <DxSearchPanel :visible="true"/> 
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                            />
                            <DxColumn data-field="detail" caption="..." cell-template="detail-cell-template" alignment="left" :allow-exporting="false"/> 
                            <DxColumn data-field="due_date" :caption="$t('Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="due_date-header" cell-template="due_date-cell-template"/>
                            <DxColumn data-field="item_type_name" :caption="$t('Cheque Type')" header-cell-template="item-type-header" cell-template="item_type_name-cell-template"/>
                            <DxColumn data-field="last_position_name" :caption="$t('Last Position')" header-cell-template="last_position_name-header" cell-template="last_position_name-cell-template"/>
                            <DxColumn data-field="docket_number" :caption="$t('Docket No')" header-cell-template="docket-no-header" cell-template="docket_number-cell-template"/>
                            <DxColumn data-field="payment_account_name" :caption="$t('Account')" cell-template="payment_account_name-cell-template"/>   
                            <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/>
                            <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>  
                            <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                            <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" header-cell-template="local-amount-header" cell-template="amount_local-cell-template"/> 
                            <DxColumn data-field="last_position_date" :caption="$t('Last Position Date')" header-cell-template="last_position_date-header" data-type="date" alignment="right" format="dd.MM.yyyy"  cell-template="last_position_date-cell-template"/>
                            <DxColumn data-field="last_position_account_name" :caption="$t('Last Position Account')" header-cell-template="last_position_account_name-header" cell-template="last_position_account_name-cell-template"/>
                            <DxColumn data-field="bank_account_name" :caption="$t('Bank Account')" header-cell-template="bank-account-header" cell-template="cash_bank_account_name-cell-template"/>
                            <DxColumn data-field="transaction_number" :caption="$t('Transaction No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>
                            <DxColumn data-field="cheque_number" :caption="$t('Cheque No')" header-cell-template="cheque-no-header" cell-template="cheque_number-cell-template"/>
                            <DxColumn data-field="transaction_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="transaction_date-cell-template"/>
                            <DxColumn data-field="created_by" :caption="$t('Created By')" alignment="right" cell-template="created-by-cell-template"/>

                            <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                            <template #headerLocalTemplate="{ data: data }">
                                <div v-html="setColumnTemplateForLocal(data)"></div> 
                            </template>

                            <template #bank-account-header>
                                <div>{{ $t('Bank') }}</div><div>{{ $t('Account') }}</div>
                            </template>
                            <template #last_position_name-header>
                                <div>{{ $t('Last') }}</div><div>{{ $t('Position') }}</div>
                            </template>
                            <template #last_position_account_name-header>
                                <div>{{ $t('Last Position') }}</div><div>{{ $t('Account Name') }}</div>
                            </template>
                            <template #transaction-no-header>
                                <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                            </template>
                            <template #cheque-no-header>
                                <div>{{ $t('Cheque') }}</div><div>{{ $t('No') }}</div>
                            </template>
                            <template #item-type-header>
                                <div>{{ $t('Cheque') }}</div><div>{{ $t('Type') }}</div>
                            </template>
                            <template #docket-no-header>
                                <div>{{ $t('Docket') }}</div><div>{{ $t('No') }}</div>
                            </template>
                            <template #local-amount-header>
                                <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                            </template>
                            <template #due_date-header>
                                <div>{{ $t('Due') }}</div><div>{{ $t('Date') }}</div>
                            </template>
                            <template #last_position_date-header>
                                <div>{{ $t('Last Position') }}</div><div>{{ $t('Date') }}</div>
                            </template>
                            <template #transaction_date-cell-template="{ data }">
                                    <span class="text-gray-700 p-3 align-middle text-end" > {{ previewDate(data.data.transaction_date) }}</span>
                            </template>
                            <template #last_position_name-cell-template="{data }">
                                    <span class="text-gray-700 p-3 align-middle" >{{ data.data.last_position_name }}</span>
                            </template>
                            <template #last_position_account_name-cell-template="{ data}">
                                    <span class="text-gray-700 p-3 align-middle" >{{ data.data.last_position_account_name }}</span>
                            </template>
                            <template #last_position_date-cell-template="{data }">
                                    <span class="text-gray-700 p-3 align-middle" > {{ previewDate(data.data.last_position_date) }}</span>
                            </template>
                            <template #docket_number-cell-template="{ data }">
                                    <span class="text-gray-700 p-3 align-middle" >{{ data.data.docket_number }}</span>
                            </template>
                            <template #item_type_name-cell-template="{ data }">
                                    <span class="fw-bold p-3 align-middle" :class="{ 'text-primary': data.data.item_type == 1 , 'text-danger': data.data.item_type == 2}"> {{ data.data.item_type_name }}</span>
                            </template>
                            <template #cash_bank_account_name-cell-template="{ data }">
                                    <span class="text-gray-700 p-3 align-middle" > {{ data.data.bank_account_name }}</span>
                            </template>
                            <template #payment_account_name-cell-template="{ data }">
                                    <span class="text-gray-700 p-3 align-middle" >{{ data.data.payment_account_name }}</span>
                            </template>
                            <template #due_date-cell-template="{ data }">
                                    <span class="text-gray-700 p-3 align-middle text-end" > {{ previewDate(data.data.due_date) }}</span>
                            </template>
                            <template #transaction_number-cell-template="{ data }">
                                    <span class="text-gray-700 p-3 align-middle" >{{ data.data.transaction_number }}</span>
                            </template>
                            <template #cheque_number-cell-template="{ data }">
                                    <span class="text-gray-700 p-3 align-middle" >{{ data.data.cheque_number }}</span>
                            </template>
                            <template #amount-cell-template="{ data }">
                                    <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount}}</span>
                            </template>
                            <template #currency-cell-template="{ data }">
                                    <span class="text-gray-800 fw-bold p-3 align-middle"> {{data.data.currency}}</span>
                            </template>
                            <template #rate-cell-template="{ data }">
                                    <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.rate}} </span>
                            </template>
                            <template #amount_local-cell-template="{ data }">
                                    <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount_local}} </span>
                            </template>
                            <template #detail-cell-template="{ data }">
                                <button type="button" class="btn btn-sm btn-icon btn-secondary btn-icon-dark btn-text-dark w-20px h-20px" @click="viewDetail(data.data)"
                                        data-bs-toggle="modal" data-bs-target="#kt_modal_view_detail" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <span class="svg-icon svg-icon-muted icon-size-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"/>
                                    <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"/>
                                    </svg>
                                    </span>
                                </button>
                            </template>
                            <template #created-by-cell-template="{ data }">
                                    <span class="text-gray-800 text-end p-3 align-middle"> {{data.data.created_by}} </span>
                            </template>

                            <DxSummary :calculate-custom-summary="setSummaries">
                                <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="due_date"/>
                                <DxGroupItem
                                    :show-in-group-footer="true"
                                    name="amount_local"
                                    summary-type="custom"
                                    show-in-column="amount_local"
                                    display-format="{0}"
                                /> 
                                <DxTotalItem name="amount_local"
                                    summary-type="custom"
                                    show-in-column="amount_local"
                                />
                            </DxSummary>
                            
                            <DxStateStoring
                                :enabled="true"
                                type="custom"
                                :storage-key="this.storageKey"
                                :ref="dataGrid" 
                                :saving-timeout="1000"
                                :custom-load="LoadDXState"
                            />

                       
                        </DxDataGrid>  
                    </div>
                </div> 
            </div>
        </div> 
    </div>   

    
    <div class="modal fade" id="kt_modal_view_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content"  >
                <div class="modal-header bg-primary py-3">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">
                        {{ $t('Details') }}
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                        <div class="row"> 
                            <div class="card p-2">  
                                <div class="card-body p-2">
                                    <div class="table-responsive min-h-300px">
                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                            key-expr="id" 
                                            :data-source="detailList" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true" 
                                            :ref="'detailDataGrid'"
                                            @exporting="this.$onExporting($event , 'Cheque-Account-List')"> 
                                            
                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="true"/>      
                                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                            <DxPaging :page-size="10"/>
                                            <DxPager :visible="false" :allowed-page-sizes="pageSizesDetailList" :show-page-size-selector="showPageSizeSelectorDetailList" :show-info="true" :show-navigation-buttons="true"/>
                                            <DxFilterRow :visible="true"/>
                                            <DxSearchPanel :visible="true"/>

                                            <DxColumn data-field="position_date" :caption="$t('Position Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="position_date-cell-template"/> 
                                            <DxColumn data-field="position_name" :caption="$t('Position')" cell-template="position_name-cell-template"/> 
                                            <DxColumn data-field="account_name" :caption="$t('Account Name')" cell-template="account_name-cell-template"/>
                                            <DxColumn data-field="docket_number" :caption="$t('Docket Number')" cell-template="docket_number-cell-template"/>
                                            <DxColumn data-field="created_at" :caption="$t('Created Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="created_at-cell-template"/> 

                                            <!-- Cell Templates -->
                                            <template #created_at-cell-template="{ data }">
                                                <span class="text-gray-900"> 
                                                    {{ previewFullDate(data.data.created_at) }}
                                                </span>
                                            </template>
                                            <template #position_date-cell-template="{ data }">
                                                <span class="text-gray-900"> 
                                                    {{ previewDate(data.data.position_date) }}
                                                </span>
                                            </template>
                                            <template #position_name-cell-template="{ data }">
                                                <span>
                                                    {{ data.data.position_name }}
                                                </span>
                                            </template>
                                            <template #account_name-cell-template="{ data }">
                                                <span>
                                                    {{ data.data.account_name }}
                                                </span>
                                            </template>
                                            <template #docket_number-cell-template="{ data }">
                                                <span>
                                                    {{ data.data.docket_number }}
                                                </span>
                                            </template>
                                        </DxDataGrid>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> 
    <!-- Statement Modal  -->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="form.payment_account_name" :account-id="form.payment_account_id" :account-type="form.payment_account_type" />
        </div>
    <!-- Search Account Modal -->
    <div class="modal fade" id="kt_search_account_modal" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <SearchAccountWithSelect ref="SearchAccountWithSelect" />
    </div>
  </div>  
</template>


<script>
import axios from 'axios' 
import moment from 'moment';
// import Swal from 'sweetalert2'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem, DxStateStoring, DxScrolling} from 'devextreme-vue/data-grid';
import SearchAccountWithSelect from '../../Accounts/Modals/SearchAccountWithSelect.vue';

 
export default {
  name: "ChequeAccountList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem, DxStateStoring, DxScrolling, SearchAccountWithSelect
  },
  data() {
    return {
        storageKey: 'dx_' + this.$options.name,
        pageSize: 20,
        pageSizeDetailList: 10,
        lists: [],
        isListProgressing: false,
        page_size: 10,
        page: 1,
        form:  {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            payment_account_id: '-1',
            payment_account_type: 0,
            payment_account_name: null,
            transaction_type: 0,
            bank_account_id: '-1',
            last_position: '-1'
        },
        accountNameList: [],
        bankList: [],
        lastPositionList: [],
        bankAccountList: [],
        transactionTypeList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Incoming Cheque'},
            { id: 2, text: 'Outgoing Cheque'},
        ],
        detailList: [],
        menuSelection: '/ChequeHelppage',
        iconView : false
    };
  },
  computed: {
    previewFullDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    filteredAccountNameList() {
        return this.accountNameList.filter(p => { 
            return p.id == '-1' || (p.account_type == 1 || p.account_type == 2 || p.account_type == 5 || p.account_type == 8 || p.account_type == 15);
        });
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
        showPageSizeSelectorDetailList() {
            return this.detailList.length > this.pageSizeDetailList;
        },
        pageSizesDetailList() {
            let arr = [];
            let x = 1;
            let loop = (this.detailList.length / this.pageSizeDetailList);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSizeDetailList * x);
                x++;
            }
            if(loop > 5) arr.push(100);
            if(loop > 1) arr.push('all');
            return arr;
        }
  },
  methods: {
    getData() {
         const parameters = {
            session_id : this.$getUser().session_id,
            account_type : '-1',
            cash_bank_account_type: 4, // Bank: 4            
            list_type: 2, // Bank List: 2
            get_access_status: 0,
            docket_sub_type: 0,
            cheque_position: 0
        };
        
        const requestOne =  axios.post('/api/Transaction/GetMultipleAccounts', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetPositions', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.bankAccountList = responses[0].data.cash_bank_accounts;
            this.accountNameList = responses[0].data.contacts;            
            this.bankAccountList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });
            this.accountNameList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });
            
            this.bankAccountList.unshift({ id: '-1', text: 'ALL'});
            this.accountNameList.forEach((item) => {
                item.text = item.text + ' (' + item.account_type_name + ')';
            });
            this.accountNameList.unshift({ id: '-1', text: 'ALL'});
            
            this.bankList = responses[1].data;
            this.bankList.unshift({ id: '-1', text: 'ALL'}); 
            this.lastPositionList = responses[2].data; 
            this.lastPositionList.unshift({ id: -1, text: 'ALL'});
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    getLists() {
    if((this.form.payment_account_name === null || this.form.payment_account_name === undefined || this.form.payment_account_name.length < 1) && !this.$getUser().is_allow_list_all_data) return;
      this.$Progress.start();
      this.isListProgressing = true;
      const params = {
        session_id: this.$getUser().session_id,
        start_date: moment(this.form.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
        end_date: moment(this.form.end_date).format('YYYY-MM-DD[T]HH:mm:ss'),
        transaction_type: this.form.transaction_type,
        payment_account_id: this.form.payment_account_id,
        bank_account_id: this.form.bank_account_id,
        last_position : this.form.last_position
      };
      axios.post('api/GetCompanyAccounts/GetChequeAccounts', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.lists = response.data;            
            this.lists.forEach(element => {
                element.transaction_number = this.$unlock(element.transaction_number);
                element.cheque_number = this.$unlock(element.cheque_number);
                element.bank_account_name = this.$unlock(element.bank_account_name);
                element.payment_account_name = this.$unlock(element.payment_account_name);
                element.last_position_account_name = this.$unlock(element.last_position_account_name);
            });
            this.$Progress.finish();
             setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            this.$removeSessionStorage('data'+ '_OTH001');
            this.$Progress.finish();
             setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
        
    },
    
    setCriteriasToDefault() {
        this.form = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            payment_account_id: '-1',
            payment_account_type: 0,
            transaction_type: 0,
            bank_account_id: '-1',
            last_position : '-1'
        };
        this.lists = [];
        this.getData();
    },
    setSummaries(options){
        if(options.name == "amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var amount_local = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amount_local);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/ChequeHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    viewDetail(data){
        this.detailList = [];
        this.$refs.detailDataGrid.instance.clearFilter();
        this.$Progress.start(); 
        const queryParameter = {
            session_id: this.$getUser().session_id,
            cheque_id: data.id,
        }; 
        axios.post('/api/Cheque/GetChequePositions', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => {  
            this.detailList = response.data;
            this.$Progress.finish();
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    openSearchModal(searchText) {
        this.$refs.SearchAccountWithSelect.getData(-2, 0, 0, searchText);
    },
    getAccountDetail(id) {
        this.form.payment_account_id = id;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.form.payment_account_name = this.$unlock(response.data.name);
            this.form.payment_account_type = response.data.account_type;
        });
    },
    onOpenAccountReport(){
        this.$refs.AccountReports.getData();
        this.$refs.AccountReports.setModalCriteriasToDefault();
    },
    onClickSearchButton() {
        this.$refs.searchAccountButton.click();
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
    if(this.$getSessionStorage('refreshList')){
        this.getLists();
        this.$removeSessionStorage('refreshList'); 
    }
    else{
        this.lists = [];
    }
  }
}; 
</script>